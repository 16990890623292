.info-btn__container {
  width: 360px;
  height: 360px;
  border-radius: 180px;
  background-color: #ffffff;
}

.info-btn__content {
  max-width: 56px;
  max-height: 56px;
}

.info-btn__details {
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.info-btn__dashed-outline {
  width: 72px;
  height: 72px;
  background-color: #ffeef2;
  border-radius: 36px;
  border: 1px #fe6c8a dashed;
  margin: auto;
  margin-bottom: 18px;
}

.info-btn__circle {
  width: 56px;
  height: 56px;
}

.info-btn__text {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.info-btn__title {
  margin-bottom: 4px;
}

.info-btn__subtitle {
  max-width: 60%;
}

.info-btn__pink {
  margin: 0;
  color: var(--fill-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px dashed var(--fill-primary-light);
  padding: 5px;
}

.info-btn__filled {
  width: 56px;
  height: 56px;
  background: var(--fill-primary-medium);
  border-radius: 50%;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-btn__faded {
  border: 2px dashed var(--fill-grey-light);

  div {
    background: var(--fill-grey-light);
  }
}

.empty-quote {
  background-color: var(--fill-blue-light2);
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-quote-details {
    background-color: var(--fill-blue-light2);
  }
}
