.head {
  color: var(--fill-grey-dark);
  padding: 14px 6px;
  cursor: pointer;

  svg path {
    stroke: var(--fill-grey-medium3);
  }
}

.section {
  padding-bottom: 8px;
  box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);

  &:last-child {
    box-shadow: none;
  }
}
