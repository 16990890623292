.sampling-details__img {
  max-width: 100px;
  display: flex;
  & > img {
    max-width: 100%;
  }
}

.sampling-details__import {
  background-color: var(--fill-white);
  min-height: 140px;
  border: 1px solid var(--fill-grey-light2);
  display: flex;

  & > * {
    padding: 16px 32px;
  }

  // https://blog.benestudio.co/custom-file-upload-button-with-pure-css-5aacf39aa0a
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
  }

  .btn {
    min-width: 200px;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.sampling-details__import__flex {
  & > * {
    margin-right: 1em;
    flex-grow: 1;
    align-items: center;
  }
}

.sampling-details__text {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  :last-child {
    max-width: 60%;
    margin-top: 1em;

    line-height: 1.43;
    letter-spacing: 0.2px;
    color: var(--fill-grey-medium);
  }
}

.sampling-details__btn {
  border-radius: 4px;
  background-color: var(--fill-primary-light);
  color: var(--fill-primary-medium);
  line-height: 1.43;
  font-size: 14px;
  padding: 14px 20px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;

  letter-spacing: 1.2px;

  &:hover {
    background-color: var(--fill-primary-medium);
    color: var(--fill-white);
  }
}

.sampling-request__btn {
  border-radius: 4px;
  background-color: var(--fill-secondary-dark);
  color: var(--fill-white);
  line-height: 1.43;
  font-size: 14px;
  padding: 12px 16px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.sampling-details__view-file {
  background-color: var(--fill-blue-light2);
  padding: 16px 24px;
  .card__section {
    border: 1px solid var(--fill-grey-light3);
    border-top: none;
  }
}

.sampling-details__view-file__header {
  padding: 16px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: var(--fill-white);
  border: 1px solid var(--fill-grey-light3);

  & > .right {
    margin-left: auto;
    line-height: 40px;
    button:focus {
      outline: 0;
    }
  }

  & > .left > .sub-header {
    margin-top: 4px;

    & > span {
      color: var(--fill-primary-medium);
      text-decoration: underline;
      user-select: none;
      cursor: pointer;
    }
  }
}

.sampling-details__table {
  align-items: center;
  & > div:nth-child(1) {
    min-width: 40%;
    max-width: 40%;
  }

  & > div:nth-child(2) {
    min-width: 20%;
    max-width: 20%;
  }

  & > div:nth-child(3) {
    min-width: 20%;
    max-width: 20%;
  }
}

.sampling-details__btn-red {
  border: 0;
  user-select: none;
  cursor: pointer;
  padding: 8px 20px;
  border: none;
  text-transform: uppercase;
  color: var(--fill-secondary-dark);
  font-weight: var(--f-w-semi-bold);
  line-height: 1.43;
  border-radius: 4px;
  background-color: var(--fill-secondary-light);

  :hover {
    background-color: var(--fill-secondary-dark);
    color: var(--fill-white);
  }

  :focus {
    outline: 0;
  }

  :disabled {
    color: #bfbfbf;

    &:hover {
      color: #bfbfbf;
      background-color: transparent !important;
    }
  }
}


.sampling-details__skipped {
  padding: 90px 0;
  justify-content: center;

  img {
    margin-right: 16px;
  }

  .text {
    color: var(--fill-locofast-black);
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
  }

  .subtext {
    width: 180px;
    margin-top: 4px;
    color: var(--fill-grey-dark)
  }
}
.sampling-details .select-input-transparent {
  max-width: 150px;
}

.horizontal-flex {
  flex-direction: column;
  justify-content: center;
}

.sampling-request__text {
  font-size: 14px;
  color: var(--fill-primary-medium);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  padding-top: 20px;
}

.title-sampling-req {
  margin: 20px 24px;
}

.sampling-details {
  hr {
    border-top: 1px solid var(--fill-grey-light3);
    position: relative;
    top: -5px;
  }
  .Dropdown-placeholder {
    color: var(--fill-secondary-dark);
  }

  .Dropdown-arrow-wrapper {
    display: none;
  }

  .production-table {
    padding: 0 24px;
    border: none;
    color: var(--fill-grey-medium);

    > :first-child {
      margin-left: 24px;
    }
  }

  .sample-row {
    max-height: 150px;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.3s;
    transition-property: max-height;

    &.accordion-container {
      &__opened {
        max-height: 1000px;
        .accordion-icon {
          transform: rotate(180deg);
        }
      }
    }
  }



  .accordion-title {
    color: var(--fill-primary-blue);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 24px;
    display: flex;

    .accordion-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-left: 8px;
      transition: transform 0.3s ease-in-out;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../../assets/images/navigation/ic-chevron-down-blue.svg')
    }
   }
}

//sampling btn n heading css
.header-btn-section {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 6px 24px;
}
