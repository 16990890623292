.tna-details {
  padding: 8px 24px;
  &-row {
    display: grid;
    grid-template-columns: 224px 150px 100px 1fr;
    grid-auto-rows: 64px;
    grid-gap: 20px;

    &__heading {
      color: var(--fill-locofast-black);
    }

    &__heading > div {
      display: grid;
      grid-gap: 16px;
      align-items: center;
    }

    &__heading,
    &__date {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      font-weight: normal;
    }

    &__date {
      color: var(--fill-grey-dark);
    }

    &__status {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }

    &__attachment {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__cta {
      display: none;
      align-items: center;
      justify-content: flex-end;
    }

    .red {
      color: var(--fill-error);
    }

    .green {
      color: var(--fill-green-2);
    }

    .gray {
      color: var(--fill-grey-medium);
    }

    .dark-gray {
      color: var(--fill-grey-dark);
    }

    .yellow {
      color: var(--fill-yellow-1);
    }

    .blue {
      color: var(--fill-selection-dark);
    }

    .tna-details-row__date .pp-fields {
      cursor: pointer;
    }

    .pp-fields {
      display: flex;
      font-size: 12px;
      padding-top: 4px;
    }

    &.inactive {
      > * {
        color: var(--fill-grey-medium);
      }
    }
  }
  .row-with-cta {
    grid-template-columns: 200px 150px 100px 1fr 130px;
    grid-auto-rows: 72px;

    .tna-details-row__cta {
      display: flex;
      .btn--blue-bg {
        padding: 4px 8px;
        font-size: 12px;
        height: auto;
        border-radius: 4px;
      }
    }
  }
}
