.note {
    padding: 16px;
    border-radius: 4px;
    background-color: #FFFCC1;
    border: 1px solid rgba(0, 1, 38, 0.1);
    margin-bottom: 24px;

    .text {
        line-height: 1.43;
        letter-spacing: 0.2px;
        color: var(--fill-grey-dark);
    }
}
