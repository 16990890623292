.duplicate-design-cfm-modal {
  min-width: 720px;
  max-width: 720px;
  background: #fcfbfb;
  height: 544px;

  > form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .formik-field.field-input {
      position: absolute;
      max-width: 174px;
      margin-top: 32px;

      .label {
        position: absolute;
        background-color: white;
        left: 12px;
        top: -10px;
        padding: 0 4px;
        font-size: 12px;
      }

      input {
        padding: 12px 16px;
        // font-size: 12px;
      }

      .error {
        font-size: 12px;
      }
    }
  }

  &__img {
    margin: 0 45px;
  }

  .blue-dot {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #5ba6ff;
  }
}
