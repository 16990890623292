@mixin pseudoStyles {
  width: 9px;
  height: 9px;
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 5px;
  left: 0;
}

$indicator-padding: 16px;

.design-table-row {
  .avatar {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;

    .initials {
      line-height: 24px;
      font-size: 12px;
    }
  }

  .profile-avatar > .img {
    width: 24px;
    height: 24px;
  }

  .col-title {
    position: relative;

    &.green {
      padding-left: $indicator-padding;

      &::before {
        @include pseudoStyles();
        background-color: var(--fill-green-light);
      }
    }

    &.red {
      padding-left: $indicator-padding;

      &::before {
        @include pseudoStyles();
        background-color: var(--fill-orangered);
      }
    }

    &.orange {
      padding-left: $indicator-padding;

      &::before {
        @include pseudoStyles();
        background-color: var(--fill-orange);
      }
    }
  }

  .date-tooltip-parent:hover .date-tooltip {
    left: -4px;
    bottom: 24px;
  }
}
