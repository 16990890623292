.file-details__modal {
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 1, 38, 0.5);
  background-color: #ffffff;

  width: 90vw;
  height: 88vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.file-details__view {
  flex-basis: 75%;
  background-color: var(--fill-locofast-black);
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

.file-details__meta {
  flex-grow: 1;
  padding: 2em;
}

.file-details__header {
  align-items: center;
}

.file-details__footer {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-blue);
  margin-top: auto;
  cursor: pointer;
  user-select: none;
  margin-bottom: -2em;
  margin-left: -1.5em;
  margin-right: -1.5em;
  box-shadow: inset 0 1px 0 0 var(--fill-grey-light3);
  padding: 18px 36px;
}

.file-details__close-img {
  margin-left: auto;
  cursor: pointer;
  user-select: none;
}

.file-details__label {
  margin-bottom: 6px;
  margin-top: 2.5em;
  color: var(--fill-grey-medium);
}
