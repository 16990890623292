.quote-date-modal {
  .note {
    display: flex;
    align-items: center;
    padding: 12px 24px;

    .loco-icon {
      width: initial;
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }

  .react-datepicker__day--highlighted-custom {
    background-color: var(--fill-blue-light);
    border-radius: 50%;
    color: var(--fill-primary-blue);
  }

  .react-datepicker__day--selected {
    background: var(--fill-primary-blue);
    color: var(--fill-white);
  }
  .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 600;
  }

  &.date-modal {
    main {
      padding: 0 24px 24px;
    }

    footer {
      border-top: 0;
    }
  }
}
