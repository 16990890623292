@import "../../../assets/scss/colors.scss";

.ant-tooltip-inner {
  background-color: #fafafa !important;
}
.ant-switch-checked {
  background: red !important;
}
.disabled tr {
  background-color: var(--fill-grey-light5);
}
.test {
  background-color: rgb(177, 228, 211);
}
.page {
  margin: 40px 32px 0 32px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
.chevron {
  cursor: pointer;
  padding-left: 10px;
}
.head {
  display: flex;
  justify-content: space-between;
  .headline {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
  }
}
.actions {
  visibility: hidden;
}

tr:hover .actions {
  visibility: visible;
}
.tableHeading {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--fill-grey-dark);
}
.topdraw {
  width: 600px;
}
.arrow {
  transform: rotate(180deg);
}

.cross {
  text-align: center;
  padding-top: 10px;
}

.ant-col-5 { 
  flex: none;
  max-width: 100% !important;
}

.pdc-drawer {
  .drawer_footer {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
  }
  .data_area {
    margin-left: 10%;
  }
  .alert {
    margin-left: 150px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 12px;
    gap: 8px;
    width: 173px;
    height: 46px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  .name {
    font-size: 10.5px;
    padding-left: 6px;
  }
  .group {
    font-size: 14px;
    font-weight: 600;
  }
  .warn {
    font-size: 12px;
  }
  hr {
    border-top: 3px dashed var(--fill-grey-light);
    outline: none;
    margin-bottom: 20px;
    margin-top: -10px;
  }
  .row {
    flex: content;
    padding-bottom: 24px;
    .label {
      width: 150px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
    .data {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      flex: auto;
      width: 150px;
      flex-wrap: wrap;
    }
  }
  .form {
    display: grid;
    margin: 0 1.5rem 0 1.5rem;

    .form-row {
      .form-column {
        margin-bottom: 10px;
        max-width: 100%;
        flex: 0 0 50%;
      }
      .form-button {
        display: flex;
        justify-content: space-around;
        margin-top: 2.5rem;
      }
    }

    .drawer-footer {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }
}

.edit {
  color: var(--fill-primary-blue);
  font-size: small;
  font-weight: bold;
  width: 4.5rem;
  text-align: center;
  border: 1px solid var(--fill-primary-blue);
  border-radius: 50px;
  margin-left: 2px;
  cursor: pointer;
}
.editBox {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.switch {
  display: flex;
  margin-top: 6px;
  gap: 5px;
  font-size: small;
}
.text-wrapper {
  width: 100%;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 0.5rem;
}
.text-wrapper:hover {
  overflow: visible;
  white-space: normal;
}

.specification-column {
  display: flex;
  align-items: center;
}
.cancel {
  height: 40px;
  width: 134px;
  border: 1px solid var(--fill-primary-blue);
  border-radius: 8px;
  padding: 12px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--fill-primary-blue);
  cursor: pointer;
}
.save {
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: var(--fill-primary-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.saveDisabled {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: var(--fill-blue-300);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.status-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 50%;
}

.inventory {
  justify-content: flex-end;
  .form-column {
    width: 100%;
    align-items: end;
    display: flex;
    justify-content: end;

    .form-button {
      display: flex;
      justify-content: space-between;
      width: 50%;
    }
  }
}

.dot {
  background: var(--fill-grey-dark3);
  border-radius: 50%;
  height: 4px;
  margin: 0 8px;
  width: 4px;
}
