.date-tooltip {
  opacity: 0;
  height: 0;
  position: absolute;
  left: -16px;
  bottom: 24px;
  display: none;
  z-index: 2;
  background: var(--fill-white);
  padding: 12px;
  box-shadow: 0px 2px 8px rgba(0, 1, 38, 0.1);
  color: var(--fill-locofast-black);
  border: 1px solid var(--fill-grey-light3);
  z-index: 5;
  min-width: 200px;
  border-radius: 4px;

  .date-tip {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;

    &.ontime {
      color: var(--fill-green-dark);
    }

    &.delayed {
      color:var(--fill-orangered);
    }
  }

  .due-info {
    color: var(--fill-grey-medium);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }

    &.multi-dates{
      div:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

}

.date-tooltip-parent {
  position: relative;

  &:hover {
    .date-tooltip {
      opacity: 1;
      height: auto;
      display: block;
    }
  }
}
