@import "../../assets/scss/_mixins.scss";

.quotes-container {
  position: relative;
  height: 100%;

  .tabs__navbar {
    border-bottom: none;
    padding: 0;
  }

  .quotes-header {
    padding: 16px 44px 8px;
    display: flex;

    > .tabs {
      flex: 1;
    }
  }

  &.scrollable {
    overflow: auto;
    max-height: 100%;
  }
}

.quote-container {
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.cols-5 {
    .quote-container-table .container-table-section,
    .quote-container-header {
      grid-template-columns: 1.1fr repeat(1, 1fr) 0.8fr 0.9fr minmax(180px, 0.9fr);
    }
  }

  &.cols-6 {
    .quote-container-table .container-table-section,
    .quote-container-header {
      grid-template-columns: 1.1fr repeat(2, 1fr) 0.8fr 0.9fr minmax(180px, 0.9fr);
    }
  }

  &.cols-7 {
    .quote-container-table .container-table-section,
    .quote-container-header {
      grid-template-columns: 1.1fr repeat(2, 1fr) 0.8fr 0.8fr 0.9fr minmax(180px, 0.9fr);
    }
  }
}

.quote-container-header {
  display: grid;
  font-size: 12px;
  color: var(--fill-grey-medium);
  padding: 12px 64px;
  border-bottom: 1px solid var(--fill-grey-light3);
  line-height: 15px;
  grid-column-gap: 12px;
}

.quote-container-table {
  padding: 0px 44px;
  font-size: 14px;
  .container-table-section {
    display: grid;
    align-items: center;
    color: var(--fill-locofast-black);
    font-size: 14px;
    padding: 16px 0;
    grid-column-gap: 12px;
  }

  .link-blue {
    color: var(--fill-locofast-black);
  }

  .table-row {
    padding: 0 20px;
    border-radius: 8px;

    &:hover {
      background-color: var(--fill-grey-light5);

      .title--review {
        display: block;
      }
      .hover-none {
        display: none;
      }

      .link-blue,
      .btn--link {
        color: var(--fill-primary-blue);
      }
    }

    & + .table-row {
      .container-table-section {
        border-top: 1px solid var(--fill-grey-light5);
      }
    }

    .factory-info {
      .title-blue-mr {
        color: var(--fill-selection-dark);
      }
    }
  }

  .table-section-design {
    display: flex;
  }

  .design-title-date {
    color: var(--fill-grey-medium);
    font-size: 12px;
    line-height: 16px;
  }

  .section-design-img {
    @include fix-size(40px);

    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    &.dummy {
      border-radius: 0;
    }
    img {
      @include fix-size(40px);

      object-fit: cover;
      object-position: top;
    }
  }

  .section-design-text {
    padding-right: 10px;
    word-break: break-all;
    line-height: 20px;
    align-items: center;
  }

  .section-design-title {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 4px;
  }

  .status-0 {
    color: black;
  }
  .status-1 {
    color: #219653;
  }
  .status-2 {
    color: #e09400;
  }

  .price-variation {
    font-weight: 600;

    .icon-text + .loco-icon {
      vertical-align: -2px;
    }
  }

  .table-section-quote {
    .fa {
      margin-left: 2px;
      margin-right: 4px;
      cursor: default;
    }
  }

  .quote-price {
    .best-quote {
      margin-left: 4px;
    }
    .loco-icon--star {
      vertical-align: -1px;
    }

    .pre-approved-quote {
      margin-left: 4px;
      svg {
        padding-top: 3px;
      }
    }
  }
}

//title blue
.title-blue {
  color: var(--fill-selection-dark);
}

//title green
.title-green {
  color: var(--fill-success);
}

//title red
.title-red {
  color: var(--fill-secondary-dark);
}

//title match
.title-match {
  color: var(--fill-orange);
}

.d-none {
  display: none;
}

.design-wid {
  min-width: 200px;
}
.up-down-arrow {
  width: 14px;
  height: 14px;
  vertical-align: top;
  margin-left: 4px;
}

.star {
  margin-left: 2px;
}
