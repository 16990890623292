.date-modal {

  width: 400px;

  .note {
    margin: 0;
    background: var(--fill-yellow-light);
    border-radius: 0;
    font-size: 14px;
    line-height: 20px;
  }

  main {
    padding: 0 24px 12px;
    height: 504px;
    overflow: auto;

    p {
      font-weight: 600;
      font-size: 16px;
    }

    .attach-file {
      .clickable {
        border: 1px dashed var(--fill-grey-light3);
        display: inline-flex;
        padding: 10px 8px;
        align-items: center;
        margin: 16px 0;
        border-radius: 4px;
        line-height: 20px;

        &.attached {
          background: var(--fill-grey-light5);
          border-color: transparent;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .fa-paperclip {
        transform: rotate(135deg) scaleX(-1);
      }
    }
  }

  .react-datepicker {
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    box-shadow: none;
    border: 1px solid var(--fill-grey-light3);

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      padding: 5px;
      width: 36px;
      height: 36px;
      line-height: 26px;
    }

    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      border-radius: 50%;
    }

    .react-datepicker__day {
      font-size: 14px;
    }

    .react-datepicker__navigation--next {
      right: 20px;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: 1px solid var(--fill-grey-light3);

    button {
      margin-left: 16px;
    }
  }
}
