.new-design {
  padding: 2em;
  max-height: 60vh;
  min-width: 450px;
  max-width: 450px;
  padding-top: 1em;
  max-height: 500px;

  .input_label {
    color: var(--fill-grey-dark);
  }

  input[type="text"] {
    border: 1px solid var(--fill-grey-light3);

    &:focus {
      border: 1px solid var(--fill-selection-dark);
    }
  }

  .custom-category__select{
    border: 0;

    .loco__radio {
      padding: 12px 14px 12px 0;
    }
  }

  .category_select {
    display: flex;
    flex-direction: row;
    margin: 8px 0;
  }

  .loco__radio_tabs{
    font-weight: 600;
  }

  .loco__radio.loco__radio--left-align [type="radio"]:checked + label:before,
  .loco__radio.loco__radio--left-align
    [type="radio"]:not(:checked)
    + label:before {
      border: 1px solid var(--fill-grey-light);
  }

  .loco__radio [type="radio"]:checked + label:before{
    border: 1px solid var(--fill-primary-blue) !important;
  }

  .loco__radio.loco__radio--left-align [type="radio"]:checked + label:after, .loco__radio.loco__radio--left-align [type="radio"]:not(:checked) + label:after{
    background: var(--fill-primary-blue);
  }
}
