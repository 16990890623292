.design-container-details,
.col-container-details {
  height: 100%;
  z-index: 15;

  > .int-sidebar {
    min-width: 250px;
    box-shadow: 1px 0px 0px #E5E5E5;
    border-right: 0;
  }

  .tab__filters {
    width: 100%;
    border: 0;
    padding: 8px 16px 12px 16px;
    height: 64px;
    border-bottom: solid 1px var(--fill-grey-light3);
    border-radius: 0;

    span {
      padding-bottom: 8px;

      input {
        padding: 0;
      }
    }

    .select-pane {
      top: 64px;
    }
  }
}

.item-container {
  > div {
    margin-left: 16px;
    box-shadow: inset 0px -1px 0px #e5e5e5;
  }
  &.active {
    background-color: var(--fill-blue-light);
  }
}

.design-list,
.col-list {
  &__item {
    .item__title {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
    }
    .item__subtitle {
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: var(--fill-grey-dark);
    }
  }
}

.design-container-details__sidebar-item,
.col-container-details__sidebar-item {
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  &.header {
    padding: 12px 10px;

    img + span {
      margin-left: 10px;
    }
  }
  &.footer {
    padding: 15px 20px;
    max-height: 48px;
    display: flex;
    span {
      flex: 1;
    }

    span + img {
      margin-left: 10px;
    }
  }
}

.design-list__item,
.col-list__item {
  padding: 12px 16px 12px 0;
  padding-right: 16px;
  cursor: pointer;
}

.col-container-details__main {
  flex-grow: 1;
}
