table.reports-table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  width: calc(100vw - 72px - 250px);
  grid-template-rows: repeat(auto-fill, 44px);

  thead,
  tbody,
  tr {
    display: contents;

    > th:first-child {
      padding-left: 24px;
    }

    > th:not(:first-child),
    td:not(:first-child) {
      padding-left: 20px;
    }

    > td:first-child {
      margin-left: 20px;
    }

    > td:last-child {
      margin-right: 20px;
    }

    .empty-list-container {
      position: fixed;
      text-align: center;
      transform: translate(-40%, -50%);
    }
  }

  th {
    top: 0;
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    color: var(--fill-grey-medium);
    text-transform: uppercase;
    line-height: 28px;
    height: 40px;
    padding: 6px 0;
    border-bottom: 1px solid var(--fill-grey-light3);
    background: var(--fill-white);
    white-space: nowrap;

    .header-tooltip {
      display: inline-block;
      line-height: 14px;
      vertical-align: middle;
      position: relative;

      svg {
        width: 12px;
        height: 12px;
        margin-left: 8px;

        path {
          stroke: var(--fill-grey-medium);
        }
      }

      &:hover {
        .visible-on-hover {
          opacity: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          transform: translate(32px, 8px);
          width: 240px;
          right: 0;
          text-transform: initial;
          font-size: 12px;
          height: auto;


        }
      }
    }
  }

  td {
    padding: 12px 0;
    color: var(--fill-locofast-black);
    border-bottom: 1px solid var(--fill-grey-light3);
  }

  .link {
    text-transform: none;
    font-size: inherit;
  }
}
