.custom-table {
  width: 100%;
  max-height: 100%;
  min-width: 100%;
  background-color: var(--fill-white);
  // table-layout: fixed;

  & > thead {
    // box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
    border-bottom: 1px solid var(--fill-grey-light3);
  }

  & > thead > tr > th {
    text-align: left;
    padding: 1em 2em;
    color: var(--fill-grey-medium);
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
  }

  & > tbody {
    display: block;
    width: 100%;
    // overflow: auto;
    // overflow-y: scroll;
    max-height: 100%;
    height: 100%;

    tr:hover {
      background-color: var(--fill-grey-light5);
    }
  }

  & > tbody > tr > td {
    padding: 2em 2em;

    img {
      cursor: pointer;
    }
  }

  &.v2 {
    thead th {
      font-size: 12px;
      line-height: 28px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 6px 0;
    }

    tbody td {
      padding: 16px 0;
    }

    thead th,
    tbody td {
      vertical-align: middle;
      padding-left: 20px;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
    }
  }
}
