.sizes-quantity-accordion {

  .btn-expand {
    height: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .sizes-quantity__header {
    display: flex;
    padding: 10px 0;
    border-top: 1px solid var(--fill-grey-light3);
    .total-sizes {
      padding-right: 10px;
      color: var(--fill-primary-blue);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    img {
      padding: 0 !important;
    }
  }
  .sizes-quantity__container {
    display: flex;
    padding: 6px 0 16px 0;

    .sizes-quantity__item {
      flex: 1 1;
      max-width: 150px;

      .size {
        color: var(--fill-grey-dark);
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
      }

      .quantity {
        line-height: 20px;
      }

      & + .sizes-quantity__item {
        margin-left: 20px;
      }
    }
  }

  &--expanded {
    .sizes-quantity__header .arrow {
      transform: rotate(180deg);
    }
  }
}
