.profile-plate {
  display: flex;
  min-height: 64px;
  max-height: 64px;
  padding: 12px 16px;
  user-select: none;

  .profile-avatar {
    margin-right: 16px;
  }

  .profile-name {
    font-size: 14px;
    line-height: 20px;
  }

  .profile-email {
    color: var(--fill-grey-medium);
    font-size: 12px;
    line-height: 16px;
  }
}
