.factoryassociation__dropdown_menu_white {
  border: 1px solid var(--fill-grey-light3);
  border-radius: 2px;
}

.factoryassociation__dropdown_menu {
  padding: 15px;
  left: 0px;
}

.factoryassociation__dropdown_menu_options {
  .Dropdown-option {
    &:hover {
      background: var(--fill-grey-light5);
    }
    padding: 15px !important;
  }
}

.factoryassociation__dropdown_arrow {
  top: 20px;
}

.factoryassociation__container > div {
  padding: 16px 28px;
}

.factoryassociation__container {
  .react-datepicker {
    left: 50%;
    transform: translate(-50%);

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      padding: 5px;
      width: 35px;
    }

    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      border-radius: 50%;
    }
  }
}

///////////////// custom date picker css changed/////////////////
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #ffffff;
}

.react-datepicker__navigation--next {
  background: url(../../../assets/images/navigation/ic-chevron-right.svg) no-repeat;
  width: 15px;
  height: 20px;
  border: none;
}

.react-datepicker__navigation--previous {
  background: url(../../../assets/images/navigation/ic-chevron-left.svg) no-repeat;
  width: 15px;
  height: 20px;
  border: none;
}

.react-datepicker__current-month {
  padding-top: 7px;
  border-bottom: 1px solid var(--fill-grey-light4);
  padding-bottom: 12px;
}

.react-datepicker {
  box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
  border: none;
}

.react-datepicker__header {
  border-bottom: 1px solid var(--fill-grey-light4);
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background: var(--fill-primary-blue);
  &:hover {
    background: var(--fill-primary-blue);
    border-radius: 50%;
  }
}
.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: var(--fill-grey-light2);
}

.react-datepicker__day--disabled:hover {
  border-radius: 50%;
  background-color: transparent;
}

.react-datepicker__day {
  font-size: 12px;
  margin: 5px;
}

.react-datepicker__day-name {
  font-size: 12px;
  margin: 5px;
  color: var(--fill-grey-medium);
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background: var(--fill-primary-blue);
  &:hover {
    background: var(--fill-primary-blue);
  }
}
