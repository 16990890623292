.design-summary-details {
  width: 100%;
  border-radius: 4px;
  flex-direction: column;
  border-bottom: 1px solid var(--fill-grey-light5);

  // TODO: Check the issue. style breaks in the absence of the padding-top
  padding-top: 2px;

  > .card__section {
    padding: 20px 24px;
  }
}

.design-summary-details__cover {
  margin-right: 50px;
}

.design-summary-details__cover-title {
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;

  .fs-12-bold {
    font-size: 12px;
    font-weight: bold;
  }

  button {
    display: flex;
    align-items: center;
    margin-left: 12px;
    height: 32px;
    padding: 6px 12px;
  }

  img {
    width: 20px;
    margin-right: 8px;
  }
}

.design-summary-details__content {
}

.design-summary-details__img {
  overflow: hidden;
  border-radius: 4px;
  border: 1px #e5e5e5 solid;
  background-color: #f2f2f2;

  max-height: 350px;
  max-width: 300px;
  min-height: 350px;
  min-width: 300px;

  // these styles are for centered image

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-right: 32px;

  & > img {
    // max-height: 350px;
    // max-width: 300px;
    // min-height: 350px;
    // min-width: 300px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    background-image: url("/assets/img/model.jpg");
  }

  position: relative;

  .upload_image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    background: rgba(103, 103, 115, 0.1);
    color: var(--fill-grey-dark);

    img {
      width: 20px;
    }

    span {
      line-height: 20px;
      max-height: 20px;
    }
  }
}

.design-summary-details__caption {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3em;
  background-color: rgba(0, 1, 38, 0.7);
  color: #ffffff;
  padding: 16px;
  align-items: center;
  justify-content: flex-end;

  & > img {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}

.caption__icon {
  max-width: 24px;
  max-height: 24px;

  img {
    max-width: 100%;
  }
}

.design-summary-details__form {
  flex-grow: 1;
  max-width: 55%;
}

.design-summary-details__2-col {
  margin-bottom: 16px;
  span {
    flex-grow: 1;
  }

  .col_2 {
    margin-left: 32px;
  }
}

.design-summary-details__footer {
  box-shadow: inset 0 1px 0 0 var(--fill-grey-light3);
  border-top: 1px solid var(--fill-grey-light3);
  height: 56px;
  // margin: 0 -32px;
  margin-top: auto;
  padding: 8px;

  button {
    border-radius: 4px;
    background-color: #ffeef2;
    height: 40px;
    padding: 10px 16px;

    border: 0;
    user-select: none;
    cursor: pointer;
    color: #fc3a52;

    margin-left: auto;
  }

  button:focus {
    outline: 0;
  }
}
