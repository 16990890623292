.formik_radio {
  > label {
    display: block;
  }
  .radio_group {
    padding-right: 16px;

    > label {
      padding: 0;
      color: var(--fill-locofast-black);
      font-size: 14px;
      line-height: 20px;
    }

    div:first-child {
      margin-right: 16px;
    }

    label {
      display: flex;
      line-height: 20px;
    }

    span {
      flex-grow: 1;
      margin-bottom: 0;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:checked + span,
    [type="radio"]:not(:checked) + span {
      position: relative;
      // padding-left: 28px;
      cursor: pointer;
      line-height: 19px;
      display: inline-block;
    }

    [type="radio"]:checked + span:before,
    [type="radio"]:not(:checked) + span:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid var(--fill-grey-light);
      border-radius: 100%;
      background: #fff;
    }

    [type="radio"]:checked + span:before {
      border: 1px solid var(--fill-primary-blue) !important;
    }

    [type="radio"]:checked + span:after,
    [type="radio"]:not(:checked) + span:after {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--fill-primary-blue);
      position: absolute;
      top: 4px;
      right: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + span:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="radio"]:checked + span:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    [type="radio"]:disabled + span:before {
      border: 1px solid var(--fill-grey-light) !important;
    }

    [type="radio"]:disabled:checked + span:after {
      background: var(--fill-grey-light) !important;
    }

    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    span {
      margin: 0;
      line-height: 1.43;
      letter-spacing: 0.2px;
      font-size: 14px;
      font-weight: 600;
    }

    input {
      margin: 0;
    }

    &.radio_group--left-align {
      &.radio_group--light {
        [type="radio"]:checked + span:before,
        [type="radio"]:not(:checked) + span:before {
          border-color: var(--fill-grey-light);
          border-width: 1px;
        }
      }

      [type="radio"]:checked + span,
      [type="radio"]:not(:checked) + span {
        position: relative;
        padding-left: 26px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
      }

      [type="radio"]:checked + span:before,
      [type="radio"]:not(:checked) + span:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid var(--fill-grey-light);
        border-radius: 100%;
        background: #fff;
      }

      [type="radio"]:checked + span:after,
      [type="radio"]:not(:checked) + span:after {
        content: "";
        width: 12px;
        height: 12px;
        background: var(--fill-primary-blue);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  &--inline-options {
    display: flex;

    > label {
      flex: 1;
    }
  }

  &.disabled {
    label {
      cursor: default;
    }

    .radio_group {
      opacity: 0.5;

      label,
      [type="radio"]:checked + span,
      [type="radio"]:not(:checked) + span {
        cursor: default;
      }
    }
  }
}
