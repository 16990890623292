.size-table {
  border-collapse: collapse;

  td {
    width: 150px;
    min-width: 150px;
    border: 1px solid var(--fill-grey-light2);
    padding-left: 12px;
    color: var(--fill-grey-medium);
    line-height: 20px;
    font-size: 13px;
    height: 32px;
  }

  thead {
    background: var(--fill-grey-light5);
    font-weight: normal;

    td {
      text-align: left;
      height: 32px;
      font-weight: 600;
    }
  }

  .size-input {
    input {
      width: 100%;
      height: 32px;
      border-radius: 0;
      border: 0;
      padding-left: 0;
    }
  }

  &.static-full {
    width: 100%;

    tr td:nth-child(2){
      color: var(--fill-locofast-black)
    }
  }
}
