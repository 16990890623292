.design-avatar-list {
  display: flex;

  .design-avatar {
    border: 3px solid #ffffff;

    &--placeholder{
      background-color: #E1E1EA;
    }

    & + .design-avatar {
      margin-left: calc(var(--avatar-size) / -2);
    }
  }
}
