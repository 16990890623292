$triangle-size: 5px;

.formik_size-qty {
  position: relative;

  &__checklist {
    background: white;
    width: 200px;
    max-height: 200px;
    padding-top: 8px;
    z-index: 100;

    .control-checkbox {
      padding: 10px 16px 10px 48px;

      .control_indicator {
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }

  &__final {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-top: 1px dashed var(--fill-grey-light2);
    padding: 9px 0 10px;
    margin-top: 12px;

    > div {
      flex: 1;
    }
  }

  &__field {
    &-item {
      display: flex;
      padding: 8px 0;
      align-items: center;

      .custom-input {
        display: flex;
        margin-right: 16px;

        label {
          padding: 0;
          font-size: 14px;
          line-height: 20px;
          color: var(--fill-locofast-black);
        }

        &__prefix {
          padding: 0 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
        }

        input {
          padding: 0 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;

          &:focus,
          &:active {
            outline: none;
          }
        }

        &.has-error {
          .custom-input__field-box input {
            border-color: var(--fill-secondary-medium);
          }
        }
      }

      .btn-remove-size {
        opacity: 0.6;
        display: flex;
        align-items: center;

        &:hover {
          opacity: 1;

          .img-close {
            pointer-events: none;
          }
        }
      }
    }
  }

  .btn-show-sizes {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0;

    &--popover {
      .loco-icon {
        transform: rotate(180deg);
      }
    }
  }
}
