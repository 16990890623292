.sampling-tracker {
  display: grid;
  grid-auto-flow: column;

  .sampling-status {
    padding-left: 20px;
  }

  .sample-files{
    justify-content: flex-start;
  }
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.pointer {
  cursor: pointer;
}

.cell-checkbox {
  justify-content: space-between;
  align-items: center;
}
