.summary-form {
  grid-row-gap: 0 !important;

  .design-details__2-col {

    >:first-child {
      padding-right: 32px;
    }

    > * {
      flex: 1;
    }
  }

  .btn--customAddDetail {
    grid-column-start: 2;
    width: 96px;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-left: auto;

    svg {
      margin-right: 4px;
    }

    div {
      line-height: 20px;
    }
  }
}
