.create-order-success {
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    min-width: 650px;
    max-width: 800px;
    width:75%;
    margin: 20px;
  }

  &__info {
    background: #e8fff1;
    border-radius: 8px;
    margin: 24px;
    padding: 20px;
    position: relative;

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }

    &__subtitle {
      color: #219653;
      font-weight: 600;
      margin-top: 4px;
    }

    &__grid {
      width: 50%;
      margin: 20px 0;

      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      &-value {
        font-weight: bold;
        text-align: right;
      }
    }

    &__title-2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &__address {
      width: 50%;
    }

    &__logo {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  &__cta-container {
    padding: 16px 24px;
    border-top: 1px solid #e5e5e5;

    .btn {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }

  &__designs-table {
    margin: 24px;

    &__header,
    &__row {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 0.2fr 0.2fr;
      column-gap: 40px;
      align-items: center;
      white-space: nowrap;
    }

    &__header {
      border-bottom: 1px solid #e5e5e5;
      padding: 12px 0;

      .col-head {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #333340;
      }
    }

    &__row {
      padding: 12px 0;

      .design-info {
        display: flex;
        align-items: center;
      }

      .design-cover {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }

      .design-price,
      .design-quantity {
        color: #676773;
      }
    }
  }
}
