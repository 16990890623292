@import "../../../assets/scss/mixins";

.messaging-header {
  display: flex;
  align-items: center;
  background: white;
  padding: 12px 25px;

  &.is-factory {
    .profile-avatar .initials {
      font-family: Volkhov;
    }
  }

  .profile-avatar {
    @include fix-size(32px);

    .initials {
      font-size: 16px;
      line-height: 32px;
    }
  }

  &__sender-org-name {
    margin-left: 15px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  &__design-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    flex: 1;
    color: var(--fill-primary-blue);
  }
}
