.order-delivery {
  background: white;
  display: flex;
  justify-content: center;

  &__main {
    max-width: 800px;
    flex: calc(800 / 350);
    margin-right: 32px;
  }

  &__summary {
    flex: 1;
    max-width: 350px;
  }

  .heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #676773;

    &--active {
      color: #000126;
    }
  }

  &__address {
    &__list {
      max-height: 348px;
      margin: -4px;
      padding: 4px;
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      padding: 20px;
      background: none;
      margin-bottom: 20px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      min-height: 160px;
      max-height: 160px;
      align-items: flex-start;
      cursor: pointer;

      &:hover,
      &--hover,
      &:focus {
        box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.25);
        outline: none;
      }

      .btn {
        padding: 5px 12px;
        line-height: 20px;
        height: unset;
      }

      .address-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .address-line {
        flex: 1;
        margin-bottom: 16px;
      }

      &:last-child,
      &:nth-last-child(2):nth-child(2n + 1) {
        margin-bottom: 0;
      }

      // &:last-child {
      //   border: none;
      //   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23D4D4D4FF' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      // }
    }

    .selected-address {
      &__title {
        margin-bottom: 8px;
      }
      &__full {
        color: #676773;
      }
    }
  }

  &__po-details {
    &__fields {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 20px;
    }
    &__field {
      label {
      }
    }

    input {
      padding: 10px 16px;
      line-height: 20px;
      border: 1px solid #d4d4d4;
    }

    .formik label:not(.custom-file__field-box) {
      margin-bottom: 8px;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      color: #333340;
    }

    .formik {
      &_input,
      &_datepicker:not(.custom_datepicker),
      &_file {
        margin-bottom: 24px;
      }
    }

    .formik_datepicker {
      &.custom_datepicker {
        display: flex;
        padding: 9px 16px;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid #d4d4d4;

        .placeholder {
        }
      }
    }
  }

  .accordion {
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 24px;

    &__header {
      display: flex;
      flex-direction: column;

      &__row {
        &:first-child {
          display: flex;
          justify-content: space-between;
        }
        & + .accordion__header__row {
          margin-top: 24px;
        }
      }

      .btn {
        padding: 4px 12px;
        font-size: 12px;
      }
    }

    &__content {
      margin-top: 24px;
      display: none;
      max-height: 100%;
    }

    &--active {
      .accordion__content {
        display: block;
      }
      .heading {
        color: var(--fill-locofast-black);
      }
    }

    & + .accordion {
      margin-top: 20px;
    }
  }
}
