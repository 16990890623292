// ==== styles for swatch details model ====
.material__details {
  min-width: 1100px;
  padding: 0px 32px;
  scroll-behavior: smooth;

  &_form {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    padding-left: 32px;
    padding-bottom: 12px;
  }

  hr {
    border-top: 1px solid var(--fill-grey-light3);
    margin: 0;
  }

  .react-select__option {
    &:hover,
    &:active {
      background: var(--fill-grey-light5);
    }
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    color: var(--fill-locofast-black);
    background: var(--fill-grey-light5);
  }
}

.material__sections {
  display: grid;
  grid-template-columns: 320px 1fr;
  padding: 0 32px;

  .section-links {
    list-style-type: none;
    display: flex;
    padding-left: 0;
    border-bottom: 1px solid var(--fill-grey-light3);
    grid-column-start: 2;
    margin-left: 32px;

    li {
      width: 120px;
      padding-bottom: 10px;
      text-align: center;
      position: relative;
      cursor: pointer;
    }

    li.active {
      color: var(--fill-primary-blue);
      &::after {
        content: "";
        position: absolute;
        left: 10px;
        bottom: 0;
        height: 3px;
        width: 100px;
        background: var(--fill-primary-blue);
        border-radius: 3px 3px 0px 0px;
      }
    }
  }
}

.material__image_wrapper {
  display: grid;
  grid-template-columns: 320px 1fr;
}

.material__image_thumnail_wrapper {
  display: flex;
  flex-direction: column;

  &_inner {
    width: 100px;
    max-height: 120px;

    // background-color: #ddd;
    margin-bottom: 25px;
    cursor: pointer;
  }
}

.material__image_big {
  width: 320px;
  height: 390px;
  //   padding: 0px 32px;

  background-color: var(--fill-grey-light5);
  justify-self: center;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.material {
  &__name {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: var(--fill-locofast-black);
    padding-bottom: 24px;
  }

  &__id {
    padding-bottom: 8px;
  }

  &__color {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-top: 8px;
    margin-right: 6px;
  }

  &__data_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .material__data_icons {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 24px;

      > div {
        cursor: pointer;
        svg path {
          fill: var(--fill-grey-medium2);
        }
      }
    }
  }
  &__metadata {
    display: grid;
    grid-template-columns: repeat(2, 205px);

    .label {
      color: var(--fill-grey-medium);
      padding-bottom: 4px;
    }
  }

  &__actions {
    display: inline-grid;
    padding-top: 20px;
    padding-bottom: 32px;
  }

  &__stock {
    display: grid;
    grid-gap: 20px;
    align-items: center;
    .visible_on_hover {
      display: none;

      svg path {
        fill: var(--fill-locofast-black);
      }
    }

    .size__accordion {
      cursor: pointer;
      svg {
        transition: transform 0.2s ease;

        path {
          stroke: var(--fill-grey-medium);
        }
      }

      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &_container {
      padding: 12px 0;
      border-bottom: 1px solid var(--fill-grey-light5);
      .size-table.static-full {
        margin-top: 20px;
        margin-left: 44px;
        width: 95%;
      }
    }

    &.inwards {
      grid-template-columns: 24px 1fr 100px 120px 24px;

      &:hover {
        .visible_on_hover {
          display: block;
        }
      }
    }

    &.outwards {
      grid-template-columns: 24px 1fr 1fr 100px 24px;
      &:hover {
        .visible_on_hover {
          display: block;
        }
      }

      .col-qty {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .name {
      font-weight: 600;
      line-height: 20px;
    }

    .subtext {
      padding-top: 4px;
      color: var(--fill-grey-medium);
      display: flex;
      align-items: center;

      .attached-file {
        color: var(--fill-primary-blue);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        border: 1px solid var(--fill-grey-light5);
        border-radius: 16px;
        padding: 4px 8px;
        font-size: 12px;

        margin-left: 8px;

        svg {
          width: 20px;
          height: 20px;
          transform: scale(0.8);
          path {
            fill: var(--fill-primary-blue);
          }
        }
      }

      & > .btn {
        margin-left: 8px;
        font-size: 10px;
        line-height: 16px;
      }
    }
  }

  &__notes_container {
    .input {
      text-align: right;
    }
    textarea {
      display: block;
      width: 100%;
      resize: none;
      & + div {
        padding: 8px 0 12px 0;
      }
    }

    button {
      height: 32px;
      padding: 6px 12px;
    }
  }

  &__notes {
    padding: 12px 16px;
    margin-bottom: 12px;
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light3);
    background: var(--fill-yellow-light);

    .metadata {
      font-weight: 600;
      padding-bottom: 12px;
    }
  }
}

.material__description_heading {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--fill-locofast-black);
  padding-top: 24px;
  padding-bottom: 16px;

  &.with_tabs {
    display: flex;
    justify-content: space-between;
    .tabs {
      width: auto;
    }
  }
}

.material__description {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--fill-locofast-black);
}

.material__table_wrapper {
  border: 1px solid var(--fill-grey-light);
}

.material__table_item {
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid var(--fill-grey-light);
  }

  &_label {
    flex: 1;
    font-weight: 600;
    padding: 6px 12px;
    line-height: 20px;
    color: var(--fill-grey-medium);
    border-right: 1px solid var(--fill-grey-light2);
  }

  &_value {
    flex: 1;
    padding: 6px 12px;
    line-height: 20px;
    color: var(--fill-locofast-black);

    &.colors {
      flex-wrap: wrap;
      padding: 0 12px 6px;
    }
  }
}

// === styles for dropdown ===

.dropdown__wrapper_custom {
  border: 1px solid var(--fill-grey-light);
  background-color: var(--fill-grey-light5);

  border-radius: 4px;
  margin-bottom: 15px;
}

// === STYLES FOR DESIGN FILTER ===
.filter__wrapper {
  display: flex;
  align-items: center;

  padding: 5px 10px;
  width: 100%;

  &:hover {
    background-color: var(--fill-grey-light5);
  }
}

.filter__image_wrapper {
  width: 40px;
  height: 40px;
  border-radius: 100px;

  overflow: hidden;
}

.filter__design_image {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.filter__design_name {
  font-size: 12px;
  color: var(--fill-locofast-black);
  padding-left: 15px;
}
