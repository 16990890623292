.reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    padding: 0;
    margin: 0;
    color: var(--fill-locofast-black);
  }

  .btn {
    padding: 8px 12px;
    height: 36px;
  }

  .sub-report-tabs {
    margin-left: 58px;
  }

  .sub-report-tabs {
    margin-left: 58px;
    display: flex;

    &__item {
      color: #93939f;

      &--active {
        color: #0067e2;
        font-weight: 600;
      }

      & + .sub-report-tabs__item {
        margin-left: 35px;
      }
    }
  }
}
