.order-summary {
  .summary {
    &__design-list {
      padding: 0 24px;
      max-height: 388px;
      flex: 1;
    }

    &__empty-designs {
      position: relative;
      min-height: 324px;
    }

    &__design-item {
      padding: 12px 0;
      display: grid;
      grid-template-columns: 40px minmax(0, 1fr);
      column-gap: 12px;

      &__cover-pic {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__design {
        &-price {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #333340;
        }

        &-price-quantity {
          font-weight: 600;
          color: var(--fill-grey-medium);
        }

        &-cta {
          height: 20px;
          width: 20px;
          margin-left: 16px;
        }
      }

      .custom-input {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;

        &__prefix,
        &__suffix {
          padding: 0 8px;
        }

        input {
          padding: 0 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          width: 64px;
        }
      }

      &__content {
        &__footer {
          margin-top: 8px;
          justify-content: space-between;
        }
      }

      &:hover,
      &--hover {
        .cta-design-delete {
          .img-trash-red {
            display: block;
          }
          .img-trash-grey {
            display: none;
          }
        }
      }

      .cta-design-delete:focus {
        .img-trash-red {
          display: block;
        }
        .img-trash-grey {
          display: none;
        }
      }
    }

    &__design-total {
      padding: 24px;

      &__sub-total {
        margin-top: 8px;
      }

      &__po-value {
        margin-top: 12px;
        font-weight: 700;

        .custom-input {
          font-weight: 700;
          &__prefix,
          &__suffix {
            padding: 4px 8px;
          }

          input {
            padding: 4px 8px;
            text-align: right;
            font-weight: 700;
          }
        }
      }

      .cta-continue {
        margin-top: 20px;
      }
    }
  }

  .cta-design-delete {
    .img-trash-red {
      display: none;
    }
  }
}
