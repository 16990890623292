.star-rating {
  input[type='radio'] {
    display: none;
  }

  &__icon {
    cursor: pointer;
    padding-right: 16px;
  }
}
