.modal__wrapper.order-add-address {
  .order-add-address-modal {
    padding: 16px 24px 24px;
    min-width: 500px;

    .formik-field + .formik-field{
      margin-top: 20px;
    }

    label {
      font-size: 12px;
      line-height: 16px;
      color: #333340;
      padding: 0;
      margin-bottom: 8px;
    }
  }
}
