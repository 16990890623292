.average-entities{

  display: flex;
  align-items: center;
  border: 1px solid var(--fill-grey-light3);
  border-radius: 8px;

  >div {
    padding: 0 12px;
  }

  img {
    padding: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  p {
    padding: 4px 6px;
    margin: 0;
  }

  &.purple {
    img {
      background: var(--fill-purple-light2);
    }
  }

  &.orange {
    img {
      background: var(--fill-orange-light);
    }
    margin-right: 20px;
  }
}

.text-green {
  color: var(--fill-success);
  margin: 0;
}
