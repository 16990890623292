.header {
    padding: 14px 32px;

    display: flex;
    flex: 1;
    border-bottom: 1px solid #e5e5e5;

    &__inner_wrapper {
        display: flex;

        flex: 0.4;
    }

    &__sample {
        flex: 0.234 !important; // try 0.225

        // font-size: 14px;
        // line-height: 20px;
        // color: #333340;
        // font-weight: 400;
    }

    &__size_label {
        flex: 1;
        padding-right: 10px;
    }

    &__status_label {
        flex: 1;
    }
}

.add__sample_btn_wrapper {
    padding-left: 14px;
}

.add__btn_wrapper {
    padding: 14px 16px;
    width: 300px;

    display: flex;
    align-items: center;

    cursor: pointer;
}

.add__icon {
    margin-right: 12px;
    display: block;
}

.add__label {
    font-size: 14px;
    line-height: 20px;
    color: #0277bd;
    font-weight: 600;
    text-transform: uppercase;

    padding-top: 5px;
}

// ============ STYLES FOR CUSTOM SELECT ....

.select__wrapper {
    // padding: 8px;
    background-color: #fff;

    border: 1px solid #d4d4d4;
    border-radius: 4px;

    box-shadow: 0 4px 6px 0 rgba(0, 1, 38, 0.4);

    margin: 10px 20px;
    width: 25%;
    margin-top: 0;

    position: absolute;
}

.select__item {
    padding: 8px 16px;

    font-size: 14px;
    color: #000126;
    text-transform: capitalize;
    cursor: pointer;
    line-height: 20px;

    &:hover {
        background-color: #f2f2f2;
    }
}


// =========== STYLES FOR SAMPLE ROW AFTER ADDED ...
.sample {
    &__row {
        background: var(--fill-white);
        border-bottom: 1px solid var(--fill-grey-light3);

        &:first-child{
          padding-top: 0;
        }
    }

    &__row__footer {
        border: 1px solid var(--fill-grey-light2);
        position: relative;
        top: -10px;
        background: var(--fill-white);
        padding: 10px;
        border-top: none;
    }

    &__row_lable {
        flex: 0.27;

        font-size: 14px;
        line-height: 20px;
        color: #000126;
        font-weight: 400;
    }

    &__text_box {
        flex: 0.7;

        display: flex;
        align-items: center;
    }

    &__choose_status {
        // flex: 2;
        // flex-grow: 1;

        padding-left: 10px;
        padding-right: 10px;

        width: 200px;

        position: relative;

        & .Dropdown-control {
            padding: 14px;
        }
    }

    &__submit_btn_wrapper {
        flex: 0.2;
        // text-align: -webkit-right;

        display: flex;
        justify-content: flex-end;
    }

    &__submit_btn {
        padding: 8px 6px;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 600;
        color: #fc3a52;

        border-radius: 50px;
        text-align: center;
        cursor: pointer;
        transition: 0.2s all;
        // width: 25%;

        // &:hover {
        //     color: #fff;
        //     background-color: #fc3a52;
        // }
    }

    &__update_btn {
        padding: 8px 6px;
        font-size: 12px !important;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 600;
        color: #676773;
        // margin-right: 5px;

        border-radius: 50px;
        text-align: center;
        cursor: pointer;
        transition: 0.2s all;
        // width: 25%;
        // margin-right: 20px;

        // &:hover {
        //     // color: #fff;
        //     background-color: #eee;
        // }
    }

    &__secondary_text {
        color: var(--fill-grey-medium);
        font-size: 12px;
    }
}

// ======== STYLES FOR DROPDOWN .....
.dropdown__styles {
    // padding: 10px;
    border: none;

    &_menu {
        border: none !important;
    }
}





// ************* styles for commit modal .....

.commit {

    padding: 15px;

    display: flex;
    flex-direction: column;

    &__text_wrapper {
        display: flex;
        flex-direction: column;
    }

    &__reason_label {
        line-height: 25px;
        padding-left: 20px;
    }

    &__text_area {

    }

    &__btn {

        padding: 10px;
        align-self: flex-end;
        outline: none;
        border: none;

        cursor: pointer;
        background-color: #fff;

        // margin: 10px;

        font-size: 13px;
        color: #fc3a52;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__textarea {
        // width: 400px;
        height: 150px;
        padding: 15px 10px;
        border: 1px solid #ccc;

        resize: none;
    }

    &__dropdown {
        margin: 0 10px;
    }

    label {
        line-height: 24px
    }
}


// ==========  STYLES FOR UPLOAD FILE BUTTON =============

.upload {
    &__file_wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

        // flex: 1;
        margin-right: 10px;
    }

    &__file_icon {

    }

    &__text {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #0277bd;
    }
}

.zero_padding_bottom {
    padding-bottom: 0px;
}

.arrow_down {
    background: url("../../../assets/images/messages/ic-arrow-downward.svg")
        center no-repeat;
    background-size: cover;
    padding-right: 15px;
}

.arrow_up {
    background: url("../../../assets/images/messages/ic-arrow-upward.svg") center
        no-repeat;
    background-size: cover;
    padding-right: 15px;
}
