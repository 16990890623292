.quote-request-modal {
  width: 800px;
  height: 600px;
  overflow: auto;
  position: relative;

  .bar__wrapper {
    padding: 0 24px;
    border-bottom: 1px solid var(--fill-grey-light3);
  }

  .quote-details {
    display: grid;
    grid-template-columns: 300px 214px;
    grid-column-gap: 38px;
    justify-content: space-between;
    padding: 24px;
    padding-bottom: 0;
  }
  .factories-section {
    height: 542px;
    padding: 16px 24px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .search-factory {
      width: 300px;
      background: var(--fill-grey-light5);
      color: var(--fill-grey-medium);
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      padding: 0 8px 0 12px;
      border-radius: 4px;
      border: 1px solid var(--fill-grey-light3);

      input {
        width: 100%;
        background: var(--fill-grey-light5);
        border: 0;
      }

      svg {
        margin-top: 6px;
        path {
          fill: var(--fill-grey-medium);
        }
      }
    }

    .production-table {
      padding: 0;
      margin-bottom: 8px;

      :first-child {
        margin-left: 38px;
      }
    }

    .factory-listing {
      max-height: 472px;
      overflow: auto;

      &:first-child {
        margin-bottom: 8px;
      }
    }

    .factory-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 100px;
      grid-gap: 16px;
      color: var(--fill-grey-dark);

      >:first-child {
        display: flex;
        align-items: center;

        p {
          margin: 0 0 0 10px;
        }

        .recent-tag {
          background: var(--fill-seashell);
          border-radius: 16px;
          font-size: 12px;
          color: var(--fill-sea-buckthorn);
          font-weight: 600;
          line-height: 20px;
          padding: 6px 8px;
        }
      }

      .control-checkbox {
        flex: 1;
        color: var(--fill-locofast-black);
        padding-top: 0;
        padding-left: 36px;
      }

      .with_hover {
        span {
          width: 214px;
          left: -38px;
          top: -42px;
        }
      }
      .with-icon {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        svg {
          margin-right: 4px;
        }
        &.machine {
          padding-right: 8px;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.modal__wrapper {
  .design-card {
    min-height: unset;

    &__caption {
      min-height: unset;
    }

    &__name {
      min-height: unset;
    }

    &:hover {
      cursor: default;
      box-shadow: none;
    }
  }

  &--request-quote {
    .control input:disabled ~ .control_indicator {
      background: var(--fill-primary-blue);
      opacity: 0.5;
      border: 0;

      &::after {
        border-color: white;
        top: 2px;
        left: 7px;
      }
    }

    .modal__header {
      margin: 20px 24px 12px;
    }

    .quote-request-footer {
      display: flex;
      justify-content: flex-end;
      padding: 12px 24px;
      border-top: 1px solid var(--fill-grey-light3);

      .split-column {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        p {
          text-transform: lowercase;
          font-size: 16px;
          margin: 0;
        }

        .blu-title {
          color: var(--fill-primary-blue);
          cursor: pointer;
          position: relative;

          .fa-caret-down {
            padding-left: 4px;
          }
        }
      }

      .btn-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }

      .next-btn {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: var(--fill-white);
          }
        }
      }
    }
  }
}

.quote-request-container {
  > div {
    padding-bottom: 18px;
  }

  .error {
    padding-top: 4px;
  }

  .formik_radio .radio_group {
    margin-top: 12px;
    flex: 1;
    white-space: nowrap;

    & + .radio_group {
      margin-left: 28px;
    }
  }

  .label,
  label {
    color: #333340;
    font-size: 12px;
    line-height: 16px;
  }
  input,
  .custom_datepicker {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;

      polygon,
      path {
        fill: var(--fill-grey-medium);
      }
    }
  }

  .formik_radio.quantity-type {
    > label {
      display: none;
    }

    .radio_group {
      flex: auto;
    }
  }

  .react-datepicker-popper {
    z-index: 999;
  }

  .formik_select__block {
    padding: 0;
    padding-right: 10px;
    height: 48px;

    .select-pane {
      margin-left: 12px;
      margin-top: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .field-separator {
    border-top: 1px solid var(--fill-grey-light3);
    padding: 0;
    margin: 0px 0 16px;
  }
}

.selected-factories {
  width: 300px;
  max-height: 300px;
  overflow: auto;
  padding: 8px 0;

  > div {
    display: flex;
    padding: 10px 16px;

    .overflow-ellipsis {
      line-height: 20px;
      font-size: 14px;
      color: var(--fill-locofast-black);
    }
  }

  .control {
    padding-top: 0;
    padding-left: 32px;
  }
}
