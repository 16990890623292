.toolbar {
  display: flex;
  align-items: center;

  &-btn {
    &-list {
      .btn + .btn {
        margin-left: 16px;
      }
    }

    & {
      height: 36px;
      padding: 8px 12px;
    }
  }

  &-menu-items {
    margin-left: 16px;
  }
}
