.ui-collection-card {
  --card-size: 182px;

  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 1, 38, 0.12);
  border-radius: 8px;
  min-width: var(--card-size);
  min-height: var(--card-size);
  max-width: var(--card-size);
  max-height: var(--card-size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;

  &__info {
    margin: 12px;
  }

  &__name {
    color: #333340;
    max-width: calc(var(--card-size) - 12px * 2);
    text-align: center;
  }

  &__tag {
    position: absolute;
    bottom: 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #676773;
    background: #f2f8ff;
    border-radius: 8px 8px 0px 0px;
    padding: 2px 8px;
  }
}
