.rf-datepicker {
  input[type="text"] {
    width: 100%;
  }
}

.custom_datepicker {
  padding: 13px 12px;
  border-radius: 2px;
  border: 1px solid var(--fill-grey-light3);
  cursor: pointer;

  &.disabled {
    background: var(--fill-grey-light6);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.rf-datepicker {
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #ffffff;
  }

  .react-datepicker__navigation--next {
    background: url(../../../assets/images/navigation/ic-chevron-right.svg) no-repeat;
    width: 15px;
    height: 20px;
    border: none;
  }

  .react-datepicker__navigation--previous {
    background: url(../../../assets/images/navigation/ic-chevron-left.svg) no-repeat;
    width: 15px;
    height: 20px;
    border: none;
  }

  .react-datepicker__current-month {
    padding-top: 7px;
    border-bottom: 1px solid var(--fill-grey-light4);
    padding-bottom: 12px;
  }

  .react-datepicker {
    box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
    border: none;
  }

  .react-datepicker__header {
    border-bottom: 1px solid var(--fill-grey-light4);
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background: var(--fill-primary-blue);
    &:hover {
      background: var(--fill-primary-blue);
      border-radius: 50%;
    }
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
    background-color: var(--fill-grey-light2);
  }

  .react-datepicker__day--disabled:hover {
    border-radius: 50%;
    background-color: transparent;
  }

  .react-datepicker__day {
    font-size: 12px;
    margin: 5px;
  }

  .react-datepicker__day-name {
    font-size: 12px;
    margin: 5px;
    color: var(--fill-grey-medium);
  }

  .react-datepicker__day--selected {
    border-radius: 50%;
    background: var(--fill-primary-blue);
    &:hover {
      background: var(--fill-primary-blue);
    }
  }

  .react-datepicker {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    &-popper {
      // transform: translateX(-10px) !important;
    }

    &__navigation--next {
      right: 20px;
    }
    &__navigation--previous {
      right: 20px;
    }

    &__current-month {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid var(--fill-grey-light5);
    }

    &__month {
      margin: 3px;
    }

    &__day,
    &__day-name {
      width: 32px;
      height: 32px;
      font-size: 12px;
      line-height: 32px;
      margin: 3px 5px;
    }
  }
}
