.design-avatar {
  display: flex;
  min-width: var(--avatar-size);
  min-height: var(--avatar-size);
  max-width: var(--avatar-size);
  max-height: var(--avatar-size);
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--placeholder {
    background-color: #E1E1EA;

    > img {
      width: 70%;
      height: 70%;
      object-fit: cover;
    }
  }
}
