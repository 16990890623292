.collections {
  display: flex;
  flex-direction: column;
  // height: 100%;
  background: var(--fill-blue-light2);

  .downshift-filter-container {
    > div {
      background: white;
    }
  }

  &.custom-scroll-container {
    margin-bottom: 0;
    // height: 100%;
    // max-height: 100%;
  }
}

//min-height and max-height id commented coz in collection list scroll problem
.collections__content {
  display: flex;
  flex-wrap: wrap;
  // Add manual padding inside dashboard view
  // Can't add padding in parent element because
  // some elements require no padding (e.g. CollectionDetailsContainer)
  padding: 20px 4px 0 24px;
  // min-height: 100vh;
  // max-height: 100vh;

  > .col-card {
    margin-right: 20px;
  }
}

.collections__new-collection {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.collections__header {
  line-height: 32px;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  padding-bottom: 0;
  line-height: 1.33;
}

.collections__list {
  flex-grow: 1;
}

.collections__footer {
  position: fixed;
  bottom: 2vh;
  right: 2vw;
}

.collections__new-btn {
  width: 56px;
  height: 56px;
}
