.factory-container {
  background: var(--fill-grey-light6);

  > header a.nav-left {
    margin-right: 12px;
    img {
      height: 32px;
      margin: -4px 0;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background: var(--fill-white);
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);

    div {
      display: flex;
      align-items: center;
    }

    h3 {
      margin: 0;
      font-size: 20px;
    }

    .chevron {
      padding: 0 8px;
      font-size: 16px;
    }
    .invisible_checkbox {
      position: absolute;
      right: 20px;
      top: 22px;
      width: 40px;
      opacity: 0;
      height: 30px;
    }
  }

  .heading--secondary {
    font-size: 12px;
    padding: 12px 0;
  }

  .details-card {
    background: var(--fill-white);
    margin: 24px;
    border-radius: 4px;
    border: solid 1px var(--fill-grey-light2);

    .card-heading {
      font-size: 16px;
      font-weight: 600;
      color: var(--fill-locofast-black);
      padding: 22px 32px;
      box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
    }

    .card-body {
      padding: 16px 32px;
    }

    .factory_address {
      display: flex;
      flex-direction: column;
      width: 90%;

      > :first-child {
        div input {
          border-bottom: 0;
        }
      }

      .city {
        border-radius: 0;
        border-bottom-left-radius: 4px;
      }

      .state-select {
        border-radius: 0;
      }

      .pinCode {
        border-radius: 0;
        border-bottom-right-radius: 4px;
      }

      .factory_address-group {
        display: flex;

        div {
          flex: 1;
        }
      }
    }
  }
  .full-width {
    width: 100%;
  }

  textarea {
    resize: none;
    vertical-align: top;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .factory-attachment {
    border-radius: 2px;
    border: 1px dashed #bfbfbf;
    background-color: var(--fill-white);
    margin-bottom: 1em;
    max-width: 285px;
    min-width: 285px;
    height: 128px;
    display: flex;
    align-items: stretch;

    .input-file__file-thumb {
      border-radius: 4px;
      overflow: hidden;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      border-radius: 4px;
      background-color: #f5f5f5;
      border-radius: 50%;
      cursor: pointer;

      img {
        margin-left: 10px;
      }
    }

    .input-file__wrapper {
      &.empty {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 15px;
      }

      > div.full_length {
        height: 100%;
        justify-content: space-around;
      }
    }

    .input-file__wrapper.selected {
      width: 100%;

      .flex-col {
        width: 100%;
      }
    }

    .input-file__progress {
      flex-grow: 1;
      padding: 0px 6px;
      align-self: baseline;
    }

    .input-file__delete {
      margin-left: auto;
      padding-right: 6px;
      cursor: pointer;
      user-select: none;
    }

    .input-file__percent {
      // color: var(--fill-green-dark);
      // margin-bottom: 0.7em;
      font-size: 12px;
      color: var(--fill-grey-medium);
    }

    .input-file__progress-bar {
    }

    .input-file__meta {
      color: var(--fill-grey-medium);
      line-height: 1.43;
      letter-spacing: 0.2px;
      // margin-top: 0.5em;
      user-select: none;
      align-self: flex-start;
    }

    .input-header__text {
      color: var(--fill-grey-dark);
    }

    .input-file__meta-selected {
      width: 100%;
      display: flex;
      padding: 16px;
      align-items: center;
      font-size: 12px;
      text-shadow: none;
    }

    .input-file__default-thumb {
      // background-color: #e53935;
      width: 36px;
      height: 44px;
      border-radius: 4px;
      margin: auto;
    }

    .attachment_meta {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 16px;
      background: var(--fill-grey-light5);
      color: var(--fill-grey-medium);
    }

    .input-file__cover-img {
      margin: 1em -8px;
      margin-bottom: -0.5em;
      padding: 1em;
      align-items: center;
      // border-top: 1px solid #bfbfbf;
      color: var(--fill-grey-dark);
      font-size: 11px;
      font-weight: 600;
    }
  }

  .details-error {
    padding: 20px 40px;
    > .error {
      font-size: 18px;
    }
  }

  .select-container {
    .label__class {
      margin-bottom: 4px;
    }
  }

  .select-container.brand {
    flex: 1;
    .downshift {
      margin-right: 10%;
    }
  }

  .lf-supplier-radio {
    margin-top: 16px;

    > div:first-child {
      padding-bottom: 16px;
    }
  }
}
.factory-add-edit-container > .scrollbar-container {
  background-color: var(--fill-grey-light6);
}
