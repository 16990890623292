.modal__wrapper.create-order-change-org {
  .orders-change-org-modal {
    padding: 0 24px;
    max-width: 340px;
  }

  .modal__footer {
    border-top: none;
    padding: 20px 24px;
    margin-top: 12px;
  }
}
