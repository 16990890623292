.sample-row {
  .progress-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
  }

  .track-btn {
    font-size: 14px;
    color: var(--fill-primary-blue);
    align-items: center;
    cursor: pointer;
    background: none;
  }

  .hide-btn {
    font-size: 12px;
    color: var(--fill-primary-medium);
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .current-step {
    color: var(--fill-green-dark);
    margin-left: 20px;

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--fill-green-dark);
      border-radius: 50%;
      position: absolute;
      left: 24px;
    }
  }

  .dispatch_button {
    span {
      top: 24px;
      left: -34px;
      width: 170px;
      z-index: 1;
    }
  }

  .react-tabs__tab--selected {
    border: none;
    border-radius: none;
    border-bottom: 3px solid var(--fill-primary-medium);
  }

  .sample-row-grid {
    display: grid;
    align-items: center;
    padding: 16px 24px;
    font-size: 14px;
    grid-template-columns: 1fr 1.5fr 1fr 140px 1fr;
  }

  .status-img {
    align-self: flex-start;
    margin-right: 8px;
    margin-top: 2px;
  }

  .line-height-24 {
    line-height: 24px;
  }

  .sample-status {
    .update-text {
      color: var(--fill-primary-blue);
      cursor: pointer;
    }

    .btn.info__icon {
      height: 16px;
      margin-left: 8px;
      margin-top: 2px;
    }

    .loco-icon {
      width: 16px;
      height: 16px;
    }

    // .resubmit-text {
    //   text-transform: uppercase;
    // }
  }

  &--last {
    justify-self: flex-end;
    text-align: right;

    .btn {
      font-size: 12px;
      padding: 4px 8px;
      height: unset;
    }
  }
}

.sampling-progress hr {
  border-top: 1px solid var(--fill-grey-light3);
  width: 110%;
  position: relative;
  left: -26px;
}

.update-row {
  .react-datepicker-wrapper {
    width: 264px;
    display: flex;
    justify-content: flex-end;
  }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 80%;
  }

  .label__class {
    font-size: 14px;
    color: var(fill-locofast-black);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  position: relative;
}

.heading {
  font-size: 16px;
  color: var(--fill-locofast-black);
  border-bottom: 1px solid var(--fill-grey-light3);
  padding: 0 0 10px 0;
}

.date-style {
  color: var(--fill-grey-dark);
}
