@import "../../../assets/scss/mixins";

.messaging-design-filters {
  display: flex;
  align-items: center;
  background: white;
  padding: 8px 24px;
  justify-content: flex-end;

  .messages-dropdown {
    padding: 12px;
    display: flex;
    border-radius: 8px;
    padding-right: 32px;
    align-items: center;
    background-color: white;
    border: 1px solid var(--fill-grey-light2) !important;
    min-width: 200px;

    &:hover {
      border: 1px solid var(--fill-grey-light2) !important;
    }

    &-arrow {
      top: 16px;
      border: 1px solid black;
      border-color: #999 transparent transparent;
      border-width: 5px 5px 0;
    }
  }
}
