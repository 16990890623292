.inline__loader {

  .inner-circle {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    left: 50%;
  }

  .inner-circle div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 3px solid var(--fill-primary-blue);
    border-radius: 50%;
    animation: inner-circle 1.2s ease-in-out infinite;
    border-color: var(--fill-primary-blue) transparent transparent transparent;
  }

  .inner-circle div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .inner-circle div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .inner-circle div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes inner-circle {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
