.tna__header_row {
  display: flex;
  padding: 0 24px 12px;
  border-bottom: 1px solid var(--fill-grey-light3);
  background: var(--fill-white);
  flex-wrap: wrap;

  .noClass {
    margin-right: 32px;
    margin-top: 12px;
  }

  .heading_content {
    font-size: 14px;
    line-height: 20px;

    .subcontent {
      font-size: 12px;
      color: var(--fill-grey-medium);
      padding-left: 4px;
    }
  }

  + .tna-graph {
    border-bottom: 0;

    .tna-graph__heading {
      border-top: 0;
    }

    .tna-graph__timeline {
      margin: 0 24px;
      border-bottom: 1px solid var(--fill-grey-light3);
    }
  }
}

.tna__attribute_groups {
  padding: 16px 0;
  background: var(--fill-white);

  button.btn {
    padding: 4px 8px;
    font-size: 12px;
    text-transform: initial;
    height: 28px;

    .fa-check {
      margin-right: 8px;
    }
  }

  .date-difference {
    text-transform: uppercase;
    margin-right: 20px;
    font-weight: 600;
    font-size: 12px;
    min-width: 100px;

    &.grey {
      color: var(--fill-grey-medium);
    }
    &.red {
      color: var(--fill-orangered);
    }
    &.green {
      color: var(--fill-success);
    }
  }

  .switch--locofast {
    width: 65px !important;
  }

  input[type="text"] {
    padding: 8px 12px;
    line-height: 18px;
    font-size: 12px;
  }

  .attribute-label {
    margin-right: 20px;
    color: var(--fill-grey-dark);
  }

  .dates-group {
    display: flex;
    align-items: center;
    flex: 2;

    .sample-files {
      margin-left: auto;
    }
  }

  .sidenote {
    background: var(--fill-yellow-light);
    border: 1px solid var(--fill-grey-light3);
    padding: 12px;
    font-size: 14px;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 12px;
    margin: 0 24px 24px;
    height: 44px;
  }
}

.create-tna-container {
  .btn {
    padding: 8px 12px;
    height: unset;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;

    > img {
      margin: 0 !important;
    }
  }
}

.tna-upload {
  width: 100%;

  &__name {
    margin: 0px;
    padding: 4px 8px;
    color: var(--fill-primary-blue);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: var(--fill-grey-light3);
    border-radius: 4px;
    display: flex;

    &:hover,
    &:focus,
    &:active {
      color: var(--fill-primary-medium);
    }

    .paperclip {
      padding-right: 8px;
    }
  }

  a {
    padding: 8px;
  }

  &__icon {
    width: 15px;
    height: 15px;
  }
}

.inner__wrapper_datepicker {
  font-size: 14px;
  width: 100%;
  color: var(--fill-grey-dark);

  &:first-child {
    padding-right: 20px;
  }

  .date {
    display: inline-block;
    width: 132px;
  }

  button.btn {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }
}

.create-tna-container,
.tna__attribute_groups {
  .plus-icon {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }
}

.tna-submit-tooltip {
  right: 0;
  left: -22px !important;
}

.tna-plan-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 16px;
  background-color: white;

  .copy-tna-container {
    position: relative;
    padding: 16px 0;

    > .empty-list-container {
      position: sticky;
      left: 0;
      transform: none;
      top: 20vh;

      .description {
        width: 200px;
      }

      .cta > .flex-col {
        .btn + .btn {
          margin-top: 8px;
        }
      }

      .design-with-cal {
        background: #f5f5f5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        > img {
          position: absolute;
          right: 33px;
          bottom: 22px;
        }
      }
    }
  }

  &.approved {
    grid-template-columns: 1fr;
  }
}

.design-tna-plan {
  .design-tna-notes {
    padding: 18px 24px 0;
    background-color: white;
  }
}
