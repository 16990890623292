.req-tna-resubmission-modal {
  .modal__form {
    display: flex;
    flex-direction: column;
  }

  .modal_content {
    flex: 1;
    padding: 16px 24px 24px;
    width: 400px;
    height: 240px;
  }

  form {
    height: 100%;

    .formik-textarea {
      height: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: column;

      label {
        text-transform: none;
        font-size: 12px;
        line-height: 16px;
        padding: 0;
      }

      textarea {
        flex: 1;
        margin-top: 6px;
      }
    }
  }

  .modal-footer {
    padding: 12px 24px;
  }
}
