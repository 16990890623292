.collaboration-modal {
  min-width: 500px;
  max-width: 500px;
  max-height: 560px;
  min-height: 560px;

  .label {
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    margin-bottom: 6px;
    color: var(--fill-locofast-black);
  }

  .add-member-container {
    border-bottom: 1px solid var(--fill-grey-light3);
    padding: 16px 24px;
    .label {
      padding: 0;
    }
  }

  .owner-container {
    padding: 24px 8px;
  }

  .members-list {
    padding: 0 8px;

    > .scrollbar-container {
      max-height: 293px;
    }
  }

  .member-item {
    display: flex;

    > .profile-plate {
      flex: 1;
    }

    > .action-list {
      margin: 0 10px;
      display: flex;
      align-items: center;
    }

    .action {
      cursor: pointer;
    }

    .action-close {
      height: 20px;
      padding: 2px;
      > img {
        height: 16px;
      }
    }
  }
}
