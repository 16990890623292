.add-file__footer {
  margin-bottom: -36px;
  margin-left: -24px;
  margin-right: -24px;
}

.add-files__modal {
  max-height: 560px;
}
.add-file-container{
  min-width: 450px;
  max-width: 450px;
  min-height: 461px;
  max-height: 461px;

  .input-file{
    max-width: unset;
    width: 100%;
  }
}
.custom-category__select {
  width: 100%;
  border-radius: 2px;
  padding: 12px 0;
  & > input {
    width: 100%;
  }
  & .loco__radio {
    padding: 12px 14px 12px 0;
    border: 0;
  }

  .loco__radio.loco__radio--left-align [type="radio"]:checked + label:before,
  .loco__radio.loco__radio--left-align
    [type="radio"]:not(:checked)
    + label:before {
      border: 1px solid var(--fill-grey-light);

      &:last-child{
        border: 1px solid red;
      }
  }

  .loco__radio [type="radio"]:checked + label:before{
    border: 1px solid var(--fill-primary-blue) !important;
  }

  .loco__radio.loco__radio--left-align [type="radio"]:checked + label:after, .loco__radio.loco__radio--left-align [type="radio"]:not(:checked) + label:after{
    background: var(--fill-primary-blue);
  }
}


.design_file--form{
  input {
    border: 1px solid var(--fill-grey-light2)
  }

  .files_custom_label{
    padding-top: 16px;
    &:before {
      top: 24px !important;
    }
    &:after {
      top: 28px !important;
    }
  }
}
