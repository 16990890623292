.sample_status_modal {
  // min-height: 674px;
  max-height: 674px;
  min-width: 800px;
  max-width: 800px;
  height: 78vh;

  .split_modal_view {
    grid-template-columns: 1fr 214px;
    grid-column-gap: 32px;
    padding: 16px 24px 0;
    overflow: auto;
  }

  .custom__textarea {
    height: 150px;
    padding: 12px 16px;
    border: 1px solid var(--fill-grey-light3);
    margin-left: 16px;

    resize: none;
    border-radius: 4px;
    outline: none;
    width: 100%;
    max-height: 96px;
  }

  .message_container {
    grid-column: 1/3;
    grid-row: 3;
    background: var(--fill-grey-light5);
    padding: 12px 24px;
    margin: 0 -24px;

    img {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border: 1px solid var(--fill-grey-light3);
      border-radius: 50%;
    }
  }

  .formik_datepicker > label {
    font-size: 12px;
    line-height: 16px;
    color: var(--fill-grey-dark);
  }

  .sample_status_container {
    width: 400px;
    min-height: 440px;
    max-height: 440px;

    .factory_image {
      display: inline-flex;
      background: var(--fill-grey-light5);
      padding: 12px 16px;
      border-radius: 8px;
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 20px;

      .brand-image {
        margin-right: 12px;
        .initials {
          font-size: 24px;
          line-height: 37px;
        }
      }

      .fac-name {
        font-weight: 600;
        margin-bottom: 4px;
        & + div {
        }
      }

      span {
        display: inline-block;
        border-radius: 50%;
        background: var(--fill-purple-light);
        color: var(--fill-white);
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        font-size: 14px;
      }
    }

    .label {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .files_container {
    text-align: left;
    min-height: 40px;
    max-height: 40px;
    text-transform: none;

    img {
      width: 20px;
      height: 20px;
      & + span {
        margin-left: 8px;
      }
    }

    .hidden_input_ref {
      display: none;
      position: relative;
      top: 20px;
    }

    .file_upload {
      position: relative;
      display: inline-flex;
      align-items: center;
      border: 1px dashed var(--fill-grey-light2);
      padding: 8px 10px;
      border-radius: 8px;
      cursor: pointer;

      &--disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    .file_uploaded {
      display: inline-flex;
      background: var(--fill-grey-light5);
      padding: 10px 8px;
      border-radius: 4px;
      position: relative;
      margin-left: 8px;
      max-width: 120px;
      text-transform: none;

      font-size: 14px;
      line-height: 20px;

      span {
        cursor: default;
      }

      img {
        cursor: pointer;
      }

      p {
        padding: 0;
        margin: 8px;
      }
    }
  }

  .custom_datepicker {
    width: 70%;
  }

  .rating {
    margin-top: 16px;

    .text {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.2px;
      margin-bottom: 20.5px;
    }
  }

  .sample-files {
    justify-content: flex-start;
    margin-top: 12px;

    &-item:not(:first-child) {
      margin-left: 4px;
      border: 2px solid white;
    }
  }
}
