.formik_select {
  label {
    display: block;
  }

  &__block {
    position: relative;
    cursor: pointer;
    border: solid 1px var(--fill-grey-light3);
    border-radius: 4px;
    padding: 6px 12px;
    height: 42px;
    outline: 0;
    display: flex;
    align-items: center;

    span {
      width: 100%;

    }

    input {
      border: none;
      background-color: transparent;
      max-width: 100%;
      width: 100%;
      font-weight:600;
      color: var(--fill-locofast-black);
    }
  }
}
