.image-modal {
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 1, 38, 0.5);
  background-color: #ffffff;
  display: flex;
  // grid-template-columns: 2.5fr 1fr;

  width: 90vw;
  height: 88vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__container {
    z-index: 10000;
  }

  &__view {
    width: 70%;
    background-color: var(--fill-locofast-black);
    justify-content: center;
    flex-direction: column;
    height: 100%;
    position: relative;

    &,
    * {
      transition: all 100ms;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__img {
    &-frame {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 120px;
      left: 0;
      right: 0;

      img {
        object-fit: scale-down;
      }

      .btn--img.cta-navigate-left,
      .btn--img.cta-navigate-right {
        position: absolute;
        top: 50%;
        background-color: transparent;
        height: 40px;
        width: 40px;

        > img {
          height: 40px;
          width: 40px;
        }

        &:disabled,
        &.btn--disabled {
          opacity: 0.5;
        }
      }

      .cta-navigate-left {
        left: 20px;
      }

      .cta-navigate-right {
        right: 20px;
      }
    }

    &-list {
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &-item {
      min-width: 80px;
      min-height: 80px;
      max-width: 80px;
      max-height: 80px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;
      background: transparent;
      border: 2px solid transparent;

      &--selected {
        border: 2px solid white;
      }

      & + & {
        margin-left: 16px;
      }
    }
  }

  &__meta {
    width: 30%;
    padding: 24px 32px;
  }

  &__header {
    align-items: center;
    margin-bottom: 32px;
  }

  &__footer {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: var(--fill-secondary-dark);
    margin-top: auto;
    cursor: pointer;
    user-select: none;
    margin-bottom: -2em;
    margin-left: -1.5em;
    margin-right: -1.5em;
    box-shadow: inset 0 1px 0 0 var(--fill-grey-light3);
  }

  &__close-img {
    margin-left: auto;
    cursor: pointer;
    user-select: none;
  }

  &__label {
    line-height: 16px;
    margin-bottom: 4px;
    color: var(--fill-grey-medium);

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__cta-download {
    margin-top: 20px;
    display: flex;
  }
}
