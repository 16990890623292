.sample-version-history {
  min-width: 700px;
  max-width: 700px;

  .sample-version-history__table {
    margin: 1.5em 0;
  }

  &__comments {
    font-size: 12px;
    line-height: 18px;
  }

  &__files {
    text-align: right;
  }
}
