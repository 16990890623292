.brandDetailsModal {
  .form {
    width: 430px;
    padding: 0px 24px;
    padding-bottom: 16px;
    min-height: 440px;;
  }
  // max-height: 432px;

  .tabs__panel {
    background-color: white;
  }

  &__textarea {
    resize: none;
    border-bottom: 0;
    padding: 12px 16px;
    border-radius: 4px 4px 0 0 !important;
    width: 100%;
  }

  &__scroll {
    max-height: 540px;
  }

  &__input_grp {
    padding: 10px 0px;
    flex-direction: column;

    & > :first-child {
      padding: 11px 0px;
    }
  }

  &__input_grp_no_label {
    flex: 1;

    input {
      width: 100%;
    }
  }

  &__select {
    width: 250px;
    * {
      height: 40px;
    }
  }

  &__address_group {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
  }

  .input-city-container > input {
    border-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    // border-right: none;
  }
  .input-pincode-container > input {
    border-radius: 0 !important;
    border-bottom-right-radius: 4px !important;
    // border-left: none;
  }

  .input-state-container {
    > div {
      border-radius: 0 !important;
      border-color: var(--fill-grey-light3) !important;
      height: 43px !important;

      > span {
        margin-top: 5px;
        > input {
          font-weight: normal !important;
        }
      }
    }
  }

  :last-child {
    box-shadow: none !important;
  }

  :global .input-file__progress {
    align-self: auto;
  }

  :global .input-file__delete {
    padding-right: 16px;
  }

  :global .input-file__wrapper {
    padding: 6px;
  }

  :global .scrollbar-container {
    height: 250px;
    border: 1px solid var(--fill-grey-light4);
    border-top: 0;
    left: -2px;
  }

  &__adjacent {
    overflow: auto;
    padding: 14px 0;

    > div {
      margin: 0 12px;
      padding: 0;
    }
  }

  .column {
    flex-direction: column;
  }

  .field_err {
    color: var(--fill-error);
  }

  .tabs {
    .tabs__navbar {
      padding: 0 16px;
    }
    .tabs__tab {
      padding: 10px;
      color: var(--fill-grey-medium);
      background-color: white;
      border-radius: 0;

      span {
        background-color: transparent;
      }

      &:hover {
        color: var(--fill-primary-blue);
        background-color: white;
      }
    }

    .tabs__tab--active {
      position: relative;
      background-color: white;
      overflow: hidden;

      span {
        font-weight: bold;
      }

      &::after {
        content: " ";
        position: absolute;
        background-color: var(--fill-primary-blue);
        height: 3px;
        border-radius: 3px 3px 0px 0px;
        left: 10px;
        right: 10px;
        bottom: 0;
      }
    }
  }

  .brandFeaturesForm {
    padding-top: 16px;
  }

  .feature-checkbox {
    font-size: 14px;
    line-height: 20px;

    label {
      margin-bottom: 0;
      letter-spacing: 0.2px;
    }

    .input-grp {
      margin-bottom: 0 !important;
      > .flex {
        padding: 10px 0;
      }
    }
    .input-grp__lower {
      display: none;
    }
  }
}

.modal__wrapper.brand-details {
  .modal__header {
    margin: 20px 24px 8px;
  }
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.addAddress {
  color: var(--fill-primary-medium);
  line-height: 1.43;
  font-weight: bold;
}

.remove_address {
  position: absolute;
  top: 10px;
  right: 10px;
}

.brandDetailsForm .brandFeaturesForm {
  width: 400px;
  padding: 0px 24px;
  padding-bottom: 16px;
  min-height: 460px;
}
