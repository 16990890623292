.input-grp {
  width: 100%;
  margin-bottom: 16px;

  label,
  textarea {
    display: block;
    width: 100%;
  }

  textarea {
    border-radius: 2px;
    resize: none;
    line-height: 1.43;
    letter-spacing: 0.2px;
    padding: 12px 16px;
    border: 1px solid var(--fill-grey-light3);

    &:focus {
      outline: none;
      border: 1px solid var(--fill-primary-blue);
    }
  }

  label {
    // margin-left: 12px;
    color: #333340;
  }

  .input-grp__lower {
    margin-top: 4px;
    padding: 0 12px;
    min-height: 1.2em;
  }

  .input-len-count {
    margin-left: auto;
  }

  .error {
    color: #d32f2f;
  }
}

.input-grp--error {
  textarea {
    border: 1px solid #d32f2f !important;
  }
}
