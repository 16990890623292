.log-container {
  background-color: var(--fill-blue-light2);
  padding: 20px 24px;

  //comtainer
  .log-container-header {
    .title {
      display: inline-block;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      min-height: 24px;

      & + img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        vertical-align: -3px;
      }
    }
  }

  //title
  .title {
    margin-top: 0px;
    font-size: 14px;
    line-height: 20px;
  }

  //img
  .help__icon {
    margin-left: 6px;
  }

  //container body
  .log-container-body {
    padding: 20px 0;

    .log-container-details {
      display: flex;
      align-items: flex-start;

      //left container
      .container-details-left {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        //img
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }

      //right container
      .container-details-right {
        padding-left: 1.2rem;
        border-bottom: 1px solid var(--fill-grey-light3);
        padding-bottom: 1rem;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 3px;
        &:last-child {
          border-bottom: none;
        }
      }

      //date title
      .date {
        color: var(--fill-grey-medium);
        margin-left: 9px;
        font-size: 12px;
        line-height: 16px;
      }
    }

    //right content
    .container-details-pdf {
      display: flex;
      align-items: center;
      background-color: var(--fill-white);
      padding: 12px 16px;
      border-radius: 5px;
      max-width: 250px;

      // &:hover {
      //   .link-hover {
      //     color: var(--fill-primary-blue);
      //   }
      // }
    }

    //content
    .pdf-title {
      margin-left: 16px;
      flex: 1;

      .pdf-title-sub {
        margin-bottom: 4px;
        margin-top: 0px;
        font-size: 14px;
        line-height: 20px;
      }

      > .dot-text {
        font-size: 12px;
        line-height: 16px;
        color: var(--fill-grey-dark);
      }
    }

    .pdf-img-box {
      width: 32px;
      height: 32px;

      .pdf-img {
        height: 100%;
        width: 100%;
      }
    }


    .attached-img {
      width: 328px;
      height: 208px;
      object-fit: cover;
      border-radius: 5px;
      margin-bottom: 6px;
    }
  }

  .file_title {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 73px);
    vertical-align: bottom;
  }

  .container-right-img {
    > .dot-text {
      font-size: 12px;
      line-height: 16px;
      color: var(--fill-grey-dark);
    }
    img {
      object-position: top center;
    }
  }
}

.dot-text {
  color: var(--fill-grey-medium);
}

//right text
.dot {
  position: relative;

  //dot
  &::before {
    content: "\00B7";
    margin-left: 5px;
    margin-right: 5px;
  }
}
