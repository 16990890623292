.order-select-factory {
  display: flex;
  background: white;
  height: 100%;
  justify-content: center;

  &__search {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 28px;

    > .heading {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      padding: 0 4px;
    }

    &__field {
      margin: 20px 4px 16px;
      .custom-input input {
        --padding-left: 18px;

        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        border: 1px solid #D4D4D4;
      }
    }

    &__results {
      position: relative;
      flex: 1;
      min-width: 808px;
      max-width: 808px;

      > div {
        display: grid;
        grid-template-columns: 1fr;
        padding: 4px;
      }

      .order-org-row {
        margin-bottom: 4px;
      }
    }
  }
}
