.quote-card {
  &--factory {
    .card-horizontal {
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin: 16px 24px 24px;
      overflow: hidden;
    }

    .quote-card__empty-icon {
      margin-left: -12px;
    }

    .cover-image {
      min-width: 180px;
      max-width: 180px;
      min-height: 210px;
      max-height: 210px;
      border-right: 1px solid #d4d4d4;
      margin-right: 32px;
    }

    .quote-card__submit-price {
      &-details {
        display: flex;
        margin-top: 20px;
        align-items: center;

        .loco-icon--arrow-long {
          margin: 0 12px;
          height: 24px;
          width: 24px;
        }
      }

      &--old {
        font-size: 20px;
        text-decoration: line-through;
        color: #676773;
      }

      &--new {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .quote-card__target-price {
      font-size: 16px;
      line-height: 24px;
      color: #676773;
    }

    &.quote-card--price {
      &-lower {
        .quote-card__submit-price--new {
          color: #27ae60;
        }
      }
      &-matched {
        .quote-card__submit-price--new {
          color: #f2994a;
        }
      }
      &-higher {
        .quote-card__submit-price--new {
          color: #eb5757;
        }
      }
    }

    .footer-note {
      padding: 12px 24px;
      background: rgba(235, 87, 87, 0.1);
      border: 1px solid rgba(0, 1, 38, 0.1);
      border-width: 1px 0;
      display: flex;
      align-items: center;

      &__img {
        margin-right: 8px;
      }
    }
  }
}
