.sample_request {
  display: grid;
  grid-template-columns: 390px 214px;
  grid-column-gap: 148px;

  // min-height: 617px;
  max-height: 617px;
  min-width: 800px;
  padding: 24px;
  padding-top: 0px;
  height: 66vh;
  overflow: auto;
  .input_grp {
    padding-right: 32px;
    margin-bottom: 16px;
    .label__class {
      padding: 6px 2px 6px 2px;
    }

    &.custom {
      .flex {
        flex-direction: column;
      }

      .radio_group {
        min-height: 40px;
      }
    }
  }

  .static-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 22px;
  }

  .design-card {
    margin-top: 72px;
  }

  &__wrapper {
    min-width: 360px;
    position: relative;
    margin-top: 24px;

    .formik_datepicker.v2 .react-datepicker {
      transform: translateY(0px) !important;
      border: 1px solid #d4d4d4;
      box-shadow: none;
    }

    .react-datepicker-popper[data-placement^="bottom"] {
      transform: translateY(2px, 170px, 0px) !important;
    }

    .formik_radio > label {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    .input_grp,
    .formik_select,
    .formik_datepicker {
      > label {
        font-size: 12px;
        line-height: 16px;
        color: var(--fill-grey-dark);
      }
    }
  }
}

.request_sample_modal {
  .modal__footer {
    justify-content: flex-end;

    button {
      svg path {
        fill: var(--fill-white);
      }
    }
  }
}
