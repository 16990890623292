.element {
    padding: 10px;
}
.basicInfocontainer {
    padding : 10px;
    border-style: 1px solid black;
}
.inputFormikClear {
    padding: 10px;
    background-color: white;
    color: black;
}