.quote-details {
  // padding: 16px 32px;
  //background: var(--fill-grey-light5);

  min-height: 500px;

  & > .card {
    background-color: var(--fill-white);
  }

  &.custom-scroll-container {
    display: flex;
    flex-direction: column;

    .empty-quote {
      flex: 1;
      position: relative;
    }
  }

  .cta-new-quote {
    position: relative;
    > .btn {
      position: absolute;
      top: 12px;
      right: 4px;
    }
  }

  .btn {
    user-select: none;
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 4px;
  }

  .btn:focus {
    outline: 0;
  }

  .btn--blue {
    // border: 1px solid var(--fill-primary-blue);
    color: var(--fill-primary-blue);
  }

  .btn--fill.btn--blue {
    color: var(--fill-primary-blue);
    //color: var(--fill-white);
    //margin-top: 16px;
  }

  .btn--primary-blue {
    border: 1px solid var(--fill-primary-blue);
  }

  .btn--primary-blue:hover {
    background-color: white;
    color: var(--fill-primary-blue);
    box-sizing: border-box;
  }
  .btn--primary-blue:disabled {
    background-color: var(--fill-grey-light5) !important;
    border: none;
    color: var(--fill-grey-medium);
  }

  .btn--primary-blue:disabled {
    background-color: var(--fill-grey-light5) !important;
    border: none;
    color: var(--fill-grey-medium);
  }

  .requested-quote {
    //display: flex;
    padding: 20px 24px 0;
    background: var(--fill-white);
    //border-radius: 8px;
    border-bottom: 1.5px solid var(--fill-grey-light5);

    &__header {
      margin-bottom: 20px;

      .title-qoute-req {
        margin-bottom: 0;
      }
    }

    img {
      padding: 8px;
    }

    .cta-edit-quote-request {
      font-weight: 600;
      font-size: 14px;
    }

    .container-block {
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;

      > p {
        margin-left: 16px;
      }

      .details {
        justify-content: space-between;
      }

      .meta-data {
        width: 100%;
      }

      .meta-data > div {
        flex: 1;
        max-width: 150px;
        white-space: nowrap;

        & + div {
          margin-left: 20px;
        }
      }
      .title-req {
        color: var(--fill-grey-dark);
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
        & + div {
          line-height: 20px;
        }
      }

      .with-edit {
        display: flex;
        align-items: center;

        > :first-child {
          margin-left: 8px;
        }

        span {
          height: 16px;
          cursor: pointer;
        }
        svg {
          width: 16px;
          height: 16px;
          line-height: 20px;
          path {
            fill: var(--fill-locofast-black);
          }
        }
      }
    }

    &.has-sizes {
      .container-block {
        padding-bottom: 17px;
      }
    }
  }

  .title-shared {
    .btn.action-new-quote {
      padding: 2px;
      font-weight: 600;
      .img {
        width: 20px;
        * {
          fill: var(--fill-primary-blue);
        }
      }
    }
  }
}

.mr-2 {
  margin-right: 0.3rem;
}

.quote-details__meta {
  & > * {
    padding: 16px 32px;
    padding-right: 96px;
  }

  & > .flex {
  }

  & > .flex > * {
    flex-basis: 30%;
  }
}

.title-shared,
.title-qoute-req,
.title-sampling-req {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.m-l-r {
  margin: 0 35px;
}

// shared quote css
.title-shared {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 0;

  .split-cols {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .btn--plain-blue {
      padding-right: 0;
    }
  }
}

.file-title {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 13px;
}

.file-image {
  height: 100%;
}

.date-by-title {
  color: var(--fill-grey-medium);
}

.quote-dropdowns {
  padding: 0 24px 20px;

  &--factory {
    padding: 0;
    background-color: var(--fill-blue-light2) !important;

    .file-image {
      margin-right: 4px;
      > img {
        margin-top: 4px;
      }
    }
  }

  .file-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .date-by-title {
    font-size: 12px;
    line-height: 16px;
    color: var(--fill-grey-dark);
  }

  .dropdown__header {
    padding: 16px 24px;
    min-height: 72px;
  }

  .card__section {
    border-top: 1px solid var(--fill-grey-light4);
    box-shadow: none !important;
    padding: 20px 24px;
  }
  .card__section + .dropdown__header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dropdown.card {
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light4);

    & + .card {
      margin-top: 8px;
    }

    &.dropdown--popover,
    &:hover {
      .quote-status {
        &__popover {
          display: flex;
        }
      }
    }
  }

  .quote-type {
    text-transform: capitalize;
    font-size: 12px;
    line-height: 16px;
    color: var(--fill-grey-dark);
  }

  .quote-price {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .input-grp label {
    margin-bottom: 8px;
  }

  .bundled-quote-container {
    .input-grp {
      flex: 1;
      & + .input-grp {
        margin-left: 24px;
      }
    }
  }
}

.conditional-card {
  display: flex;
  align-items: center;
}

.new-quote-form {
  padding: 0 28px 28px;
  max-width: 450px;

  .loco__radio {
    border-radius: 4px;
  }
}

.empty-list-container-quotes {
  .img-container-quote {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;

    img {
      height: 96px;
      width: 96px;
    }

    .cover-pic {
      border: 4px solid #ffffff;
      box-sizing: border-box;
      border-radius: 50%;
      z-index: 10000;
      object-fit: cover;
      object-position: top;
      margin-right: -34px;
      background: #fff;
    }

    .empty-quote-icon {
      border-radius: 50%;
      // transform: translate(-6px, 1px);
    }
  }

  .cta-create-quote {
    display: flex;
    align-items: center;

    .icon-text {
      margin-left: 8px;
    }
  }

  .content .closure-expired {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #eb5757 !important;
  }
}
