.notification-list__item {
  height: 70px;
  padding: 16px 20px;
  //   padding-right: 6px;
  border-bottom: 1px solid rgba(212, 212, 212, 0.3);
  cursor: pointer;
}

.notification-list__item--active {
  //   background-color: #d4efff;
  background-color: #025a8f;
  position: relative;
  &:before {
    // position: "absolute";
    position: absolute;
    background-color: red;
    left: -3px;
    top: 7px;
    content: "  ";
    height: 55px;
    width: 6px;
    border-radius: 2.5px;
  }
}

.notification-list__title {
  margin-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  color: var(--fill-white);
}

.notification-list__subtitle {
  color: var(--fill-white) !important;
  opacity: 0.7;
}
