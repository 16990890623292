.custom-file {
  .input-grp &__field-box {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--selected &__field-box {
    background-color: #f2f2f2;
    border-radius: 4px;
  }

  &__file-name {
    flex: 1;
    margin-right: 8px;
  }

  input {
    display: none;
  }

  .btn {
    height: 20px;
    width: 20px;
    background-color: transparent;

    svg {
      height: 20px;
    }

    & + .btn {
      margin-left: 8px;
    }
  }
}
