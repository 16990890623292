.withdraw-quote-modal {
  .modal {
    &__footer{
      border-top: none;
    }
  }

  &__content {
    margin: 0 24px 20px;
    max-width: 300px;
  }
}
