.dropdown-container {
  position: relative;


  .items {
    position: absolute;
    background: var(--fill-white);
    width: 100%;
    z-index: 99;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light3);
    padding: 8px 0;
  }

  .list-item {
    padding: 11px 16px 10px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background: var(--fill-grey-light3)
    }
  }
}
