$orders-gt-cols: 0.8fr 1fr 0.5fr 0.5fr 200px 1fr;
$orders-brand-factory-gt-cols: 1fr 1fr 1fr 0.8fr 0.8fr 200px 1fr;

.orders-wrapper {
  &.scrollable {
    overflow: auto;
    max-height: 100%;
  }
  position: relative;
  height: 100%;

  .row {
    background-color: #fff;
    padding: 12px 24px;

    &.header-row:first-child {
      padding: 16px 24px;
      display: flex;
      position: absolute;
      right: 20px;

      .cta-create-order{
        height: unset;
        padding: 8px 12px;
        position: relative;
        margin-right: 16px;

        &::after{
          content: "";
          position: absolute;
          right: -16px;
          width: 1px;
          height: 24px;
          background: var(--fill-grey-light3);
        }
      }

      > div:first-child {
        flex: 1;
      }
    }

    > :nth-child(2){
      div {
        padding-right: 0;
      }
    }

  }

  .btn--filter {
    margin: 0 16px;
    padding: 7px 12px;
  }

  .filter-group {
    display: flex;

    .debounced-input {
      width: 200px !important;
      height: 36px;
      position: relative;
      margin-left: 16px;
      background: var(--fill-grey-light5);
      border: 1px solid var(--fill-grey-light2);
    }
  }

  .pagination-wrapper {
    position: relative;
    margin-left: 16px;

    &::before{
      content: "";
      position: absolute;
      left: -16px;
      top: 6px;
      width: 1px;
      height: 24px;
      background: var(--fill-grey-light3);
    }
  }

  .filters__wrapper {
    display: flex;
    align-items: center;
  }

  // styles for table ====
  .table-header {
    display: grid;
    grid-template-columns: $orders-brand-factory-gt-cols;

    font-size: 12px;
    color: var(--fill-grey-dark);

    border-bottom: 1px solid var(--fill-grey-light3);
    text-transform: uppercase;
    font-weight: 600;

    & > div {
      padding: 10px 0;
    }
  }

  &-one-col {
    .table-header {

      grid-template-columns: $orders-gt-cols;
    }
  }

  // === styles for order item in table ===
  .order__wrapper_outer {
    background-color: #fff;
    border-radius: 4px;

    & + .order__wrapper_outer {
      border-top: 1px solid var(--fill-grey-light5);
    }

    &:hover {
      .f-color-faded.link {
        color: var(--fill-primary-blue);
      }

      .action img {
        opacity: 1;
      }
    }
  }

  .order {
    display: grid;
    grid-template-columns:$orders-brand-factory-gt-cols;
    align-items: center;

    color: var(--fill-locofast-black);
    font-size: 14px;

    border-radius: 6px;

    &.order-one-col {
      grid-template-columns:  $orders-gt-cols;
    }
  }

  .order__item {
    .order-status {
      &::before {
        content: " ";
        width: 9px;
        height: 9px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
        vertical-align: 1px
      }

      &.on-time {
        &::before {

        background: var(--fill-success);
        }
      }
      &.delayed {
        &::before {
        background: var(--fill-orangered)
        }
      }
    }

    .date-tooltip {
      left: -4px;
    }

    &-img-prefix {
      position: relative;

      > img,
      svg {
        position: absolute;
        top: 2px;
      }

      > div {
        margin-left: 24px;
      }
    }
  }

  .order__item_large_font {
    font-size: 14px;
    line-height: 25px;
    color: var(--fill-locofast-black);
  }

  .order__item_small_font {
    font-size: 12px;
    line-height: 25px;
    color: var(--fill-grey-medium);
  }

  .order__item_green {
    color: var(--fill-green-dark);
  }

  .order__item_red {
    color: var(--fill-error);
  }

  .order__item_orange {
    color: #e6a725;
  }

  .order__item_large_font_blue {
    color: var(--fill-primary-medium);
    font-size: 12px;
    line-height: 20px;

    cursor: pointer;

    &.designs {
      color: var(--fill-locofast-black);
    }
  }

  .f-color-faded.link {
    text-transform: none;

    &.designs {
      display: inline-block;
    }
  }

  .action {
    & + .action {
      > img {
        margin-left: 5px;
      }
    }
    img {
      opacity: 0;
      vertical-align: sub;
    }
  }

  .order__item__with__ARROW {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    margin-left: 21px;

    > div:not(:empty) + div::before {
      content: " ";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: 0 8px 1px;
      background-color: var(--fill-grey-medium);
    }
  }

  svg.location {
    * {
      fill: var(--fill-grey-medium);
    }
  }

  .tabs__navbar{
    border-bottom: none;
    padding: 16px 44px 12px
  }

  .tabs__panel{
    background: white;
    overflow: initial;
  }
}

.dispatch-orders-modal {
  position: relative;
  min-width: 600px;
  min-height: 400px;
  max-height: calc(750px - 56px);

  .row-order-complete {
    margin: 0 24px;
    background: #e8fff1;
    border-radius: 8px;
    padding: 12px 20px;
    display: flex;
    margin-bottom: 16px;

    .content {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding: 14px 0;
      flex: 1;
    }

    .line-2 {
      color: #219653;
      margin-top: 4px;
    }

    .img {
      height: 72px;
    }
  }

  .complete-indication {
    color: var(--fill-success);
    font-weight: normal;
    font-size: 12px;
    margin-left: 8px;
  }

  .dispatch-items {
    padding-bottom: 32px;
  }

  .row-dispatch-item {
    display: flex;
    padding: 20px 0;
    margin: 0 24px;

    & + .row-dispatch-item {
      border-top: 1px solid var(--fill-grey-light3);
    }

    .main-section {
      flex: 1;
      margin-left: 20px;
      font-size: 14px;
      line-height: 20px;

      .heading {
        margin-bottom: 16px;
        font-weight: 600;
        border-bottom: 0;
        padding: 0;
      }

      .details {
        > .row-detail {
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr;

          &.header {
            font-size: 12px;
            line-height: 16px;
            color: var(--fill-grey-dark);
            margin-bottom: 6px;
          }
        }
      }

      .documents {
        margin-top: 16px;

        .download-document-item {
          padding: 7px 8px;
          border: 1px solid var(--fill-grey-light3);
          border-radius: 4px;
          color: var(--fill-locofast-black);
          margin-right: 8px;
          font-size: 12px;

          &:after {
            content: " ";
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-bottom: -5px;
            margin-left: 5px;
            background-image: url("../../assets/images/action/ic-download-grey.svg");
          }
        }
      }
    }
  }
}
