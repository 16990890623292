.assign_factory_modal {
  height: 556px;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    font-weight: bold;
  }

  .info-note {
    background: var(--fill-yellow-light);
    padding: 12px 16px;
    width: 100%;
    display: flex;
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light3);
    margin-bottom: 16px;

    > :first-child {
      width: auto;
      margin-right: 8px;
    }
  }

  form {
    padding-right: 12px;
  }

  .scrollbar-container {
    height: 90%;
  }

  .react-select__indicator-separator {
    width: 0;
  }

  .form__row {
    padding-bottom: 24px;
    > :first-child {
      padding: 8px 0;
      color: var(--fill-grey-dark);
    }
  }

  .details__item {
    margin-bottom: 24px;

    .label {
      color: var(--fill-grey-medium);
      line-height: 20px;
      margin-bottom: 4px;
    }

    &.with_image {
      .label {
        margin-bottom: 8px;
      }
    }
  }

  .option {
    display: flex;
    padding: 4px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }

    &__heading {
      color: var(--fill-locofast-black);
    }

    &__subheading {
      color: var(--fill-grey-medium);
    }
  }

  .hidden {
    visibility: hidden;
  }

  .l-height-50{
    line-height: 50px;
  }

  .padding-0 {
    .form__row{
      padding: 0;
    }
  }

  .input-file__meta-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    background: var(--fill-grey-light5);
    border-radius: 4px;
    font-size: 14px;

    .clickable {
      height: 20px;
    }
  }
}
