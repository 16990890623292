.formik_datepicker {
  input[type="text"] {
    width: 100%;
  }
}

.custom_datepicker {
  padding: 13px 12px;
  border-radius: 2px;
  border: 1px solid var(--fill-grey-light3);
  cursor: pointer;

  &.disabled {
    background: var(--fill-grey-light6);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.formik_datepicker.v2 {
  .react-datepicker {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    &-popper{
      // transform: translateX(-10px) !important;
    }

    &__navigation--next {
      right: 20px;
    }
    &__navigation--previous {
      right: 20px;
    }

    &__current-month {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid var(--fill-grey-light5);
    }

    &__month {
      margin: 3px;
    }

    &__day,
    &__day-name {
      width: 32px;
      height: 32px;
      font-size: 12px;
      line-height: 32px;
      margin: 3px 5px;
    }
  }
}
