.wrapper {
  // padding: 0px 108px 56px 108px; // remove top padding after geting headers ..

  max-height: 83vh;
}

.scroll_wrapper_design_list {
  padding: 0 0px 10px 0px;
  min-height: 100vh;
}

.scroll_wrapper_design_list_override {
  min-height: initial !important;
  height: calc(100% - 50%);
}

.info_p {
  color: var(--fill-grey-medium);
  margin-top: -10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    opacity: 0.5;
    width: 16px;
    margin-right: 6px;
  }
}

// ======  STYLES FOR DESIGN ITEM ==========

.design {
  display: grid;
  // grid-template-columns: repeat(6, 1fr);

  // justify-items: center;
  align-items: center; // centers items vertically ..

  background-color: #fff;
  border: 1px solid var(--fill-grey-light2);

  line-height: 20px;
  transition: all 0.2s;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
  }

  &__link {
    // display: block;
  }

  &__image_wrapper {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    transform: translateY(-7px);
    padding-right: 25px;
  }

  &__image_outer {
    width: 80px;
    height: 94px;

    border-radius: 2px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;

    overflow: hidden;
  }

  &_details {
  }

  &__name {
    color: var(--fill-secondary-dark);
  }

  &__id {
    color: var(--fill-grey-medium);
    // color:#00213d;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 4px;
  }

  &__collection_details {
    font-size: 14px;
    color: var(--fill-locofast-black);

    padding-right: 10px;
  }

  &__brand_details {
    font-size: 14px;
    color: var(--fill-locofast-black);

    padding-right: 10px;
  }

  &__status {
    padding-left: 20px;
    padding-right: 10px;

    &__is_sampling {
      padding-left: 0;
    }
    &_type {
      font-size: 14px;
      color: var(--fill-locofast-black);
    }

    &_state {
      font-size: 12px;
      font-weight: 600;
      color: var(--fill-green-dark);
      margin-top: 4px;
    }

    &_date {
      font-size: 14px;
      color: var(--fill-grey-medium);
      margin-top: 4px;
      &.isFactory {
        margin-top: 0;
      }
    }

    &__viewmore {
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
      text-transform: uppercase;
      color: var(--fill-grey-medium);
    }
  }

  &__sample_wrapper {
    padding: 10px 0;
  }

  &__quote {
    &_amount {
      font-size: 14px;
      color: var(--fill-grey-medium);

      text-transform: uppercase;
    }

    &_state {
      font-size: 12px;
      font-weight: 600;
      color: var(--fill-green-dark);
    }

    &_date {
      font-size: 11px;
      color: var(--fill-grey-medium);
    }
  }
}

// ===========  STYLES FOR DESIGNS HEADER ========
.header {
  // display: grid;
  // grid-template-columns: repeat(6, 1fr);

  border-bottom: 1px solid var(--fill-grey-light3);

  padding: 14px 0;
  margin: 0px 108px 10px 108px;

  &__item {
    font-size: 14px;
    color: var(--fill-grey-medium);
  }
}

// ============== STYELS FOR HEADER WITH FILTERS =========
.header__filter_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: white;
}

.filters__wrapper {
  display: flex;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: "20px";
  }
}

// ============ STYLES FOR BOTTOM STATIC CREATE ORDER BAR ========
.bottom__bar_wrapper {
  padding: 12px 208px 12px 108px;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 72px;

  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 8px 16px 0 rgba(0, 1, 38, 0.5);

  background-color: #fff;
  width: 100%; // make this dynamic please

  z-index: 1000;
  // ======================================================================  this is for bottom bar ====
  // @media only screen and (max-width: 75em) { // this is for 1200px
  //     // font-size: 50%;
  //     width: 92%;
  // }

  // @media only screen and (max-width: 1100px) { // this is for 1200px
  //     // font-size: 50%;
  //     width: 90.6%;
  // }
}

.bottom__create_order_wrapper {
  padding: 14px 20px;

  background-color: var(--fill-primary-blue);
  border-radius: 50px;

  font-size: 14px;
  color: #fff;
  text-transform: uppercase;

  cursor: pointer;
}

// ==========  STYLES FOR CREATE ORDER MODEL ============
.model {
  width: 800px;
  height: 600px;
  border-top: 1px solid #f5f5f5;

  &_add_dispatch {
    width: 800px;
    height: 600px;

    border-top: 1px solid #f5f5f5;
  }

  &_view_designs {
    width: 700px;
    height: 600px;
  }
}

.bar__wrapper {
  display: flex;

  padding: 0 15px;
}

.bar__item {
  display: flex;
  flex: 1;
  flex-grow: 1;

  align-items: center;
  justify-content: center;

  padding: 15px;

  padding-right: 0;

  &_last {
    display: flex;
    align-items: center;
    padding: 15px;

    padding-right: 5;
  }
}

.bar__label {
  color: var(--fill-grey-medium);
}

.bar__icon {
  padding: 10px;
}

.bar__line {
  height: 3px;
  background-color: var(--fill-grey-light);

  flex-grow: 1;
}

// ==== contains order items ...
.scroll__wrapper {
  min-height: 530px;
}
.delete__design_icon {
  // padding: 10px;
  // padding-left: 30px;

  cursor: pointer;
  visibility: hidden;
  transition: 0.2s all;
}
// ==== order item single ....
.order__item {
  &_wrapper {
    display: grid;
    grid-template-columns: 100px 1fr 1.2fr 1.3fr;

    grid-auto-flow: column;

    padding: 8px 24px;
    align-items: center;
    line-height: 18px;
    border-bottom: 1px solid var(--fill-grey-light2);

    &_view_design {
      // edit quantity
      display: grid;
      grid-template-columns: 1fr 0.4fr 0.4fr;
      column-gap: 40px;
      align-items: center;

      grid-auto-flow: column;
      margin: 0 24px;

      padding: 8px 0;
      align-items: center;
      line-height: 18px;
      border-bottom: 1px solid var(--fill-grey-light3);

      &:hover .delete__design_icon {
        visibility: visible;
      }
    }

    &:hover .delete__design_icon {
      visibility: visible;
    }
  }

  &_info {
    display: flex;
    align-items: center;
  }

  &_image_wrapper {
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 16px;
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_details {
  }

  &_name {
  }

  &_id {
    text-transform: uppercase;
    color: var(--fill-grey-medium);
  }

  &_qty {
    display: flex;
    // justify-content: center;
  }

  &_qty_input {
    height: 36px;
    width: 150px;
  }
}

// model header styles =======
.model__header {
  &_item {
    font-size: 14px;
    color: var(--fill-grey-dark);
  }

  &_wrapper {
    display: grid;
    grid-template-columns: 100px 1fr 1.2fr 1.3fr;

    grid-auto-flow: column;

    padding: 12px 24px;

    border-bottom: 1px solid var(--fill-grey-light3);

    &_add_dispatch {
      // index 1
      display: grid;
      grid-template-columns: 100px 1fr 1.2fr 1.3fr;

      grid-auto-flow: column;

      padding: 12px 24px;

      border-bottom: 1px solid var(--fill-grey-light3);
    }

    &_view_designs {
      display: grid;
      grid-template-columns: 1fr 0.4fr 0.4fr;
      column-gap: 40px;

      grid-auto-flow: column;

      padding: 12px 24px;

      border-bottom: 1px solid var(--fill-grey-light3);
    }
  }
}

// == styles for footer in design order model
.order__footer {
  &_wrapper {
    padding: 8px 24px;
    margin-top: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--fill-grey-light5);
    color: var(--fill-locofast-black);

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &_details {
    &_label {
      font-size: 14px;
      color: var(--fill-grey-medium);
      line-height: 25px;
    }

    &_qty {
    }
  }

  &_btn_wrapper {
  }
}

// =======  STYLES FOR PO DETAILS INPUT =====
.input__with_text_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-auto-flow: column;

  justify-content: space-between;
  align-items: center;

  padding: 10px 0;
}

.with__text_label {
  font-size: 16px;
  color: var(--fill-locofast-black);
}

.with__text_input {
  width: 264px;
  height: 44px;
  // margin-left: 20px;  // tyr to remove this ..
}

// ==== parent for po text inputs ... ====
.po__wrapper {
  padding: 8px 24px;
}

.po__back_btn {
  padding: 0px 32px;

  width: 100px;

  font-size: 14px;
  color: var(--fill-primary-medium);
  font-weight: 600;

  cursor: pointer;
}

// === styles for custom file uploader ===
.with_input_type_file_wrapper {
  position: relative;
}

.with__text_input_hidden {
  width: 264px;
  height: 44px;
  margin-left: 20px;

  z-index: 1000;
  opacity: 0;

  cursor: pointer;
  // display: contents;

  // visibility: hidden;
}

.visible__file_uploader_wrapper {
  // class containig icon
  z-index: -10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 264px;
  height: 44px;
  // margin-left: 20px;

  border: 1px solid var(--fill-grey-light);

  padding: 10px;

  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
}

.visible__file_text {
}

.visible__file_icon {
  // transform: skew(-60deg);
}

// ==== STYLES FOR PAGINTION ===
.pagination {
  &__wrapper {
    display: flex;

    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    font-weight: 600;

    &:not(:last-child) {
      margin-right: 24px; // margin for filter button
    }
  }

  &__inner_wrapper {
    display: flex;
    align-items: center;
    font-weight: normal;
  }

  &__btn_wrapper {
    display: flex;
    align-items: center;

    padding-left: 20px;
  }

  &__btn {
    padding: 6px;

    cursor: pointer;

    color: var(--fill-grey-medium);
    transition: all 0.2s;

    &:hover {
      color: var(--fill-primary-dark);
    }
  }
}

.metrics {
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
  background: var(--fill-white);
}
