.design-card {
  break-inside: avoid;
  // flex-direction: column;
  max-width: 214px;
  min-width: 214px;
  min-height: 340px;
  border-radius: 8px;
  // overflow: hidden;
  // box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
  background-color: #ffffff;

  display: inline-table;
  // margin: 0 0 16px;
  // margin-top: 5px;
  // width: 100%;

  max-height: 360px;
  overflow: hidden;
  border: 1px solid var(--fill-grey-light3);
}

.modal {
  .design-card {
    min-height: unset;
  }
}

.design-card.grey-card {
  filter: grayscale(1);
}

.design-card__img.design-card__img--placeholder {
  // object-fit: contain;
  object-fit: scale-down;
}

.design-card__cover {
  flex-basis: 60%;
  width: 100%;
  position: relative;
  min-height: 250px;
  max-height: 250px;
}

.design-card__badge {
  position: absolute;
  top: 1em;
  right: 1em;
}

.design-card__img {
  object-fit: cover;
  width: 100%;
  max-height: 250px;
  min-height: 250px;
  background-image: url("../../../assets/img/model.jpg");
}

.design-card__img-grad {
  background-image: linear-gradient(to bottom, rgba(229, 229, 229, 0), rgba(0, 1, 38, 0.16) 38%, rgba(0, 1, 38, 0.6));
  position: absolute;
  height: 50%;
  // For proper gradient positioning
  bottom: 3px;
  width: 100%;
}

.design-card__category {
  position: absolute;
  bottom: 1em;
  color: #ffffff;
  text-shadow: 0 2px 2px #000126;
  width: 100%;
  padding: 0 16px;
  user-select: none;
  hyphens: auto;
}

.design-card__caption {
  padding: 16px;
  min-height: 90px;
  flex-direction: column;
}

.design-card__name {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding-right: 24px;
  min-height: 38px;
  max-height: 38px;
  min-width: 156px;
  max-width: 156px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.design-card__status {
  text-transform: capitalize;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.design-card__status.draft {
  color: var(--fill-orange);
}

.design-card__status.sampling,
.design-card__status.production,
.design-card__status.quote,
.design-card__status.shipped {
  color: var(--fill-success);
}

.design-card__more {
  margin-left: auto;
}

.hover-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.design-card:hover {
  cursor: pointer;
  box-shadow: 0 0px 4px 0 rgba(0, 1, 38, 0.25);
}
