.add-material-modal {
  width: 1000px;
  height: 580px;
  display: grid;
  padding: 24px 32px;
  grid-template-columns: 300px 1fr;

  .file-vertical {
    border-radius: 8px;
    border: 1px dashed var(--fill-grey-light);
    background-color: var(--fill-white);
    width: 260px;
    height: 300px;
    display: flex;
    background-size: 100% 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .attachment_icon {
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      display: flex;
      border-radius: 50%;
      background: var(--fill-grey-light5);
    }

    .input-file__delete {
      margin-left: auto;
      padding-right: 6px;
      cursor: pointer;
      user-select: none;
    }

    .input-file__percent {
      // color: var(--fill-green-dark);
      // margin-bottom: 0.7em;
      font-size: 12px;
      color: var(--fill-grey-medium);
    }

    .input-file__meta {
      color: var(--fill-grey-medium);
      line-height: 1.43;
      letter-spacing: 0.2px;
      // margin-top: 0.5em;
      user-select: none;
      align-self: flex-start;
    }

    .input-header__text {
      color: var(--fill-grey-dark);
    }

    .input-file__meta-selected {
      background-color: rgba($color: #000000, $alpha: 0.7);
      width: 100%;
      color: var(--fill-white);
      padding: 6px;
      display: flex;
      align-items: center;
      font-size: 12px;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
    }
    .input-file {
      &__upload_image {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 12px;
        background: var(--fill-grey-light5);
        color: var(--fill-grey-dark);
        line-height: 20px;
        font-size: 14px;
        > div {
          line-height: 20px;
        }
      }
    }
  }

  .form-row {
    margin-bottom: 32px;

    .form-label {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .react-select {
    &__indicator-separator {
      background: none;
    }

    &__control {
      border-color: var(--fill-grey-light2);
    }
  }

  .color-select__control {
    height: auto;
  }

  .multi-options {
    display: flex;
  }

  .loco__radio {
    border: 0;
    padding: 0;
    width: 120px;
  }

  .input-grp input {
    border-color: var(--fill-grey-light2);
  }

  .input-grp__lower {
    height: 0;
    padding: 0;
    margin: 0;
    min-height: 0;
  }

  .control {
    padding-top: 0;
    width: 120px;
  }

  .err,
  .field_err,
  .error {
    font-size: 12px;
  }

  .col-equal {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .input-grp .custom-file__field-box {
    padding: 12px 16px;
  }

  .test-report-ts {
    margin-top: -8px;
    margin-left: 12px;
  }

  .field-row-alert {
    > .grid-2 {
      height: 44px;
    }

    .loco__radio {
      height: 40px;
    }

    .input-grp > label {
      height: 0;
      margin-bottom: 0;
    }
  }
}

.add_material_container {
  .modal__wrapper {
    .ps__rail-y {
      opacity: 1 !important;
      .ps__thumb-y {
        opacity: 1 !important;
      }
    }
  }

  .material-file {
    position: fixed;
  }
}
