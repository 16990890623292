.ui-design-card {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 1, 38, 0.12);
  border-radius: 8px;
  width: 174px;
  overflow: hidden;

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__info {
    padding: 8px 12px 12px;
    display: grid;
    grid-template-rows: auto;
    row-gap: 4px;
  }

  &__source-id {
    font-size: 12px;
    line-height: 16px;
    color: var(--fill-light-gray);
  }

  &__creator {
    font-size: 12px;
    line-height: 16px;
    color: var(--fill-light-gray);

    display: flex;
    flex-direction: column;
  }

  &__img {
    min-height: 138px;
    max-height: 138px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1E1EA;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--placeholder {
      > img {
        width: 70%;
        height: 70%;
      }
    }
  }
}
