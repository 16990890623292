.select-grp {
  position: relative;

  label,
  select {
    display: block;
    width: 100%;
  }

  label {
    margin-left: 1px;
    margin-bottom: 6px;
    color: #333340;
  }
}
