.side-drawer {
  &.left,
  &.right {
    .side-drawer-container {
      width: 400px;
    }
  }

  &.left {
    .side-drawer-container {
      right: unset;
    }
  }

  &.right {
    .side-drawer-container {
      left: unset;
    }
  }

  .side-drawer-container {
    > .header {
      padding: 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }

    > .footer {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      border-top: 1px solid var(--fill-grey-light3);
    }
  }

  .title-container {
    display: flex;
    > .title {
      flex: 1;
    }
  }

  &.hide {
    display: block !important;

    .side-drawer-backdrop,
    .side-drawer-container {
      opacity: 0;
    }

    &.left {
      .side-drawer-backdrop,
      .side-drawer-container {
        transform: translateX(-120%);
      }
    }
    &.right {
      .side-drawer-backdrop,
      .side-drawer-container {
        transform: translateX(120%);
      }
    }
  }
}

.side-drawer-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: all 200ms;
  opacity: 1;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.5);
}

.side-drawer-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 9999;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  transition: all 200ms;
  opacity: 1;
}

.side-drawer-content {
  position: relative;
  flex: 1;
  > .side-drawer-content-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px 0 8px 24px;

    > .scrollbar-container {
      padding-right: 24px;
    }
  }
}
