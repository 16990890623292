.floating-cta {
  position: absolute;
  bottom: 1.7em;
  right: 1.6em;

  button {
    border: 0;
    user-select: none;
    cursor: pointer;
    padding: 14px 20px;
    border: none;
    background-color: var(--fill-white);
    box-shadow: 0 3px 8px 2px rgba(0, 1, 38, 0.4);
    border-radius: 24px;
    text-transform: uppercase;
    color: var(--fill-primary-blue);
    font-weight: var(--f-w-semi-bold);
  }

  button:focus {
    outline: 0;
  }

  button:disabled {
    background-color: var(--fill-grey-medium);
    color: var(--fill-white);
  }
}

.floating-cta--top {
  top: 1em !important;
  height: 20px !important;
}
