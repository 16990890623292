.order-summary {
  background-color: white;
  width: 100%;
  min-width: 350px;
  max-width: 350px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div {
    & + div {
      border-top: 1px solid #d4d4d4;
    }
  }

  &__org {
    padding: 16px 24px;
    align-items: center;
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    grid-column-gap: 16px;

    &-logo {
      height: 40px;
      width: 40px;

      img {
        max-height: 40px;
        max-width: 40px;
      }

      .initials {
        font-size: 20px;
        line-height: 28px;
        padding: 6px 0;
      }
    }

    &-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }

    &-type {
      text-transform: uppercase;
      color: #676773;
      margin-right: 8px;
    }

    .cta-change-org {
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      margin-top: 4px;
      align-self: flex-start;
    }

    &--reverse &-details {
      flex-direction: column-reverse;
    }

    &--factory {
      .order-cart__org-logo {
        .initials {
          font-family: Volkhov;
        }
      }
    }

    &--brand {
      .order-cart__org-logo {
        .initials {
          font-family: Volkhov;
        }
      }
    }

    &--brand + &--factory {
      padding: 16px 0;
      margin: 0 24px;
      border-top: 1px solid #d4d4d4;
    }

    &-list {
      box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.2);
    }
  }
  .empty-list-container {
    .heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
