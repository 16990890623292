.select-grp {
  position: relative;

  label,
  select {
    display: block;
    width: 100%;
  }

  label {
    margin-left: 1px;
    margin-bottom: 6px;
    color: #333340;
  }

  .field_err {
    color: var(--fill-error);
  }

  .cta-add-custom {
    position: absolute;
    right: 12px;
    top: 3px;
    padding: 8px 6px;

    color: var(--fill-primary-blue);
    background: #f2f8ff;
    font-weight: 600;
    border-radius: 24px;
  }
}
