.messaging-design-item {
  &-list {
    padding: 8px 0;
    background-color: white;
  }

  & {
    user-select: none;
    padding-left: 16px;
    cursor: pointer;

    &:hover:not(&--selected) {
      background-color: var(--fill-grey-light5);
    }

    &--selected {
      background-color: var(--fill-blue-light);
      border-right: 1px solid var(--fill-grey-light3);
    }

    &-unread {
      .message-queue__new {
        display: inline-block;
      }

      .message-queue__title {
        font-weight: 600;
      }
    }

    &__body {
      display: flex;
      padding: 18px 16px;
      padding-left: 0px;
      border-bottom: 1px solid var(--fill-grey-light3);
    }

    &__icon {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      border-radius: 50%;
      position: relative;
      display: inline-block;

      &.dummy {
        border-radius: 0;
      }
    }

    &__new {
      top: 6px;
      right: 2px;
      width: 10px;
      height: 10px;
      display: none;
      position: absolute;
      border-radius: 50%;
      background-color: var(--fill-secondary-dark);
    }

    &__content {
      width: 100%;
      overflow: hidden;
      padding-left: 16px;

      > .row {
        display: flex;
        line-height: 18px;
        & + .row {
          margin-top: 4px;
        }
      }

      .design-name {
        color: var(--fill-locofast-black);
        font-weight: 600;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      .timestamp {
        font-size: 12px;
        color: var(--fill-grey-dark);
        margin-left: 16px;
      }

      .comment-info {
        flex: 1;
        font-size: 14px;
        color: var(--fill-grey-dark);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .unread-count {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        background: var(--fill-secondary-dark);
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        margin-left: 8px;
      }
    }

    &__description {
      margin: 0px;
      max-width: 100%;
      color: var(--fill-grey-dark);
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 10px;
      text-overflow: ellipsis;
    }

    &__arrow-down,
    &__arrow-up {
      width: 18px;
      height: 12px;
      color: #0077bd;
      margin-right: 4px;
      display: inline-block;
    }

    &__arrow-up {
      background: url("../../../assets/images/messages/ic-arrow-upward.svg") center no-repeat;
      background-size: cover;
    }

    &__arrow-down {
      background: url("../../../assets/images/messages/ic-arrow-downward.svg") center no-repeat;
      background-size: cover;
    }

    &__meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__timestamp {
      color: var(--fill-grey-dark);
    }
  }
}
