.orders-design-card {
  padding: 16px;
  min-width: 300px;
  max-width: 400px;
  height: 132px;
  border-radius: 8px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 85.1px minmax(0, 1fr);
  grid-column-gap: 16px;
  text-align: left;

  &--selected {
    background: #e5f1ff;
    border: 1px solid #82bbff;
  }

  &:hover,
  &--hover {
    box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.25);
    outline: none;

    &.orders-design-card--selected {
      box-shadow: 0px 0px 4px rgba(0, 10, 20, 0.25);
    }

    .orders-design-card__cta {
      &-add {
        color: white !important;
        background-color: var(--fill-primary-blue);
        svg path {
          stroke: white;
        }
      }

      &-remove {
        color: white !important;
        background-color: var(--fill-orangered);
        .trash-red {
          display: none;
        }
        .trash-white {
          display: block;
        }
      }
    }
  }

  &__cover-pic-container {
    width: 85.1px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
  }
  &__cover-pic {
    max-width: 100%;
    max-height: 100%;
  }

  &__design {
    &-price {
      font-size: 14px;
      font-weight: 600;
      line-height: 2;
    }
    &-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }
    &-id {
      font-size: 14px;
      line-height: 20px;
      color: #333340;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__header {
    }

    &__footer {
      justify-content: space-between;
    }
  }

  &__cta {
    &-list .btn {
      padding: 4px 8px;
      font-size: 12px;
      line-height: 20px;
      height: unset;
    }

    &-remove {
      .trash-white {
        display: none;
      }
    }
  }
}
