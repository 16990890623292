.addUserModal {
  &__main_container {
    padding: 24px 24px 86px;
    position: relative;
    width: 560px;
  }

  &__invisible_checkbox {
    position: absolute;
    right: 22px;
    top: 16px;
    width: 40px;
    opacity: 0;
    height: 30px;
  }

  &__footer {
    font-size: 14px;
    font-weight: 600;
    color: var(--fill-secondary-dark);
    cursor: pointer;
  }

  &__label {
    color: var(--fill-grey-dark);
    font-size: 12px;
    padding-bottom: 4px;
  }

  &,
  &__main_container {
    .switch > .switch-toggle {
      height: 14px;
      width: 38px;

      &::after {
        margin-top: -1px;
      }
    }
  }
}

.modal__footer-factory-user {
  padding: 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid var(--fill-grey-light3);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
  padding-right: 24px;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
