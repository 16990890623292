.size-quote-modal {
  width: 500px;
  padding: 0;
  padding-bottom: 24px;

  .design {
    display: grid;
    grid-template-columns: 96px 1fr;
    padding: 0 24px 24px 24px;
    column-gap: 20px;

    &__pic {
      max-height: 96px;
    }

    img {
      min-height: 96px;
      min-width: 96px;
      max-height: 96px;
      max-width: 96px;
      object-fit: cover;
      border-radius: 4px;
    }

    &__details {
      .design-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .design-info {
        .label {
          color: var(--fill-grey-dark);
          font-size: 12px;
          line-height: 16px;
        }
        .info {
          font-size: 14px;
          line-height: 20px;
          margin-top: 4px;
          color: var(--fill-locofast-black);
        }
      }
    }
  }
  .sizes-container {
    &__header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 40px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid var(--fill-grey-light3);

      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        color: var(--fill-grey-medium);
      }
      div {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        color: var(--fill-grey-medium);
        font-weight: 600;
      }
    }
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 44px;
      align-items: center;
      padding: 0 24px;

    }
    &__list {
      max-height: calc(44px * 6);
    }
  }
}
