.calendar-page {
  border: 1px solid var(--fill-grey-light3);
  background: var(--fill-grey-light5);
  border-radius: 8px;
  text-align: center;
  width: 80px;
  height: 80px;
  padding: 8px 12px;

  > div + div {
    margin-top: 2px;
  }

  .month {
    color: var(--fill-grey-dark);
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }

  .date {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }

  .year {
    font-size: 12px;
    line-height: 18px;
  }
}
