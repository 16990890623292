.wrapper {
  background-color: var(--fill-blue-light2);
  position: relative; //this is for floating submit button ..
  flex: 1;

  h3 {
    padding: 16px 24px;
    margin: 0;
    background: var(--fill-white);
  }
}

.main__heading {
  .primary__heading {
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 4px;
  }
}

.tna__heading_item {
  .primary__heading {
    font-size: 12px;
    font-weight: normal;
    color: var(--fill-grey-dark);
  }
}

.primary__heading {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.secondary__heading {
  font-size: 12px;
  line-height: 16px;
  color: #676773;
}

.calender__label {
  font-size: 12px;
  line-height: 16px;
  color: var(--fill-grey-dark);
}

.row {
  display: grid;
  padding: 6px 24px;
  grid-template-columns: 1.5fr 192px;
  align-items: center;
  white-space: nowrap;

  &.approved {
    grid-template-columns: 0.75fr 2fr 65px;
  }

  & > div {
    flex: 1;
    line-height: 20px;

    &:not(:last-child) {
      margin-right: 20px;
      color: var(--fill-grey-dark);
    }
  }

  input[type="number"]:disabled {
    background-color: #ededed;
    cursor: default;
  }

  .clickable {
    border: 1px solid grey;
  }
}

.submit__btn_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: var(--fill-white);
  color: var(--fill-primary-blue);
  border-radius: 100px;
  box-shadow: 2px 3px 10px 2px #999;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  position: fixed;
  top: 85%;
  right: 50px;
}

.detail__header_with_edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 0 0;
  background: var(--fill-white);

  div:first-child {
    padding-top: 0;
  }
}

.edit__tna_btn,
.submit__tna_btn {
  padding: 8px 25px;
  border: 1px solid var(--fill-primary-blue);
  color: var(--fill-primary-blue);
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all;
  margin-left: 16px;
  line-height: 20px;
  background: var(--fill-white);

  &:hover {
    outline: none;
    box-shadow: none;
    background: var(--fill-white);
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: var(--fill-white);
    color: var(--fill-grey-medium);
    border: 1px solid var(--fill-grey-light2);
    cursor: not-allowed;

    .edit__tna_btn:hover {
      background-color: var(--fill-white);
      color: var(--fill-grey-light);
    }
  }
}

.edit__tna_btn {
  font-weight: bold;
}

.submit__tna_btn {
  background-color: var(--fill-primary-blue);
  color: var(--fill-white);

  &:disabled {
    background-color: var(--fill-grey-light2);
    color: var(--fill-white);
    font-weight: bold;
  }

  &:hover:not(:disabled) {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--fill-primary-blue);
    color: var(--fill-primary-blue);
    background: var(--fill-white);
  }
}

.cancel__tna_btn {
  padding: 8px 25px;
  border: 1px solid var(--fill-white);
  color: var(--fill-primary-blue);
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.custom-input {
  input[type="number"]:disabled {
    background: #999;
  }
}

.detail {
  display: grid;
  grid-template-columns: 1.5fr 192px;
  align-items: center;
  padding: 10px 24px;
  white-space: nowrap;

  &.approved {
    grid-template-columns: 0.75fr 2fr 65px;
  }

  span {
    display: inline-block;
    width: 192px;
    text-transform: uppercase;
    color: var(--fill-grey-medium);
    font-size: 12px;
  }
}

.inner__wrapper_datepicker {
  width: 192px;
}

.tna__action_heading {
  background: white;
  padding: 16px 24px 0;

  > div {
    font-size: 16px;
  }
}
