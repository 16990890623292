.design-grid {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 2px;
  padding: 21px 25px;

  width: 100%;
}

.design-grid__list-container {
  // display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  &::after {
    content: "";
    flex: auto;
    width: 17vw;
    min-width: 17vw;
    max-width: 17vw;
  }

  & > * {
    // flex-basis: 50%;
    margin-right: 21px;
    margin-bottom: 21px;
  }
}

// .design-grid__list-container {
//     margin: 1em;
// }

// // // Work around for css-columns issues
// // // https://css-tricks.com/guide-responsive-friendly-css-columns/#article-header-id-11

// @media (min-height: 400px) {
//     .design-grid__list-container {
//         column-count: 4;
//         column-width: 214px;
//         column-gap: 1.25em;

//     }
// }

.design-grid__btn {
  width: 56px;
  height: 56px;
}

// // https://w3bits.com/css-masonry/

.design-grid__footer {
  position: fixed;
  bottom: 2vh;
  right: 2vw;
  z-index: 10;
}
