.dashboard {
  display: flex;
  background-color: var(--fill-grey-light5);

  //left section
  .dashboard-left-section {
    width: 75%;
    padding: 16px 0px 16px 20px;
  }

  //right section
  .dashboard-right-section {
    width: 23.5%;
    background-color: var(--fill-white);
    position: fixed;
    right: 0;
    top: 56px;
    bottom: 0;
  }

  .empty-list-container {
    .content {
      margin-top: 8px;
    }

    .heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .img-container {
      height: 72px;
      width: 72px;
    }
  }

  .blue-on-hover:hover {
    color: var(--fill-primary-blue);
  }

  .tabs {
    border: 1px solid var(--fill-grey-light5);
    border-radius: 4px;
    > div {
      border-left: 1px solid var(--fill-grey-light5);
      border-right: 1px solid var(--fill-grey-light5);
    }
    :last-child,
    :first-child {
      border: 0;
    }
  }
}

/*-------------Top Cards CSS-------------*/
.dashboard-cards-container {
  display: flex;
}

//card start
.dashboard-cards-design {
  width: 25%;
  height: 165px;
  border-radius: 8px;
  background-color: var(--fill-white);
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  //card centent
  .dashboard-cards-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  //card title
  .cards-content-title {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  //number text
  .cards-content-number {
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 4px;

    .span-normal {
      font-size: 14px;
      font-weight: normal;
      padding-left: 8px;
    }
  }

  .dashboard-cards-end {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  //coloured bottom
  .card-divider-bottom {
    background: var(--fill-primary-blue);
    height: 6px;
    border-radius: 0px 0px 8px 8px;
  }

  //title
  .cards-end-title {
    font-size: 14px;
    line-height: 20px;
    color: var(--fill-grey-medium);
    font-weight: 600;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &.span {
      padding-left: 8px;
      font-weight: normal;
    }
  }

  //number
  .cards-end-num {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  .cards-end-num.red {
    color: var(--fill-orangered);
  }

  .cards-end-num.green {
    color: var(--fill-green-light);
  }

  //title view
  .cards-title-view {
    color: var(--fill-primary-blue);
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  //number status
  .status-number {
    color: var(--fill-locofast-black);
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-align: right;

    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  .text-float-left {
    justify-content: normal;

    .cards-end-title {
      margin: 0;
      padding-left: 8px;
    }
  }
}

//sucess text
.dot-green {
  padding-left: 16px;
  position: relative;
  &::before {
    height: 8px;
    width: 8px;
    background-color: var(--fill-green-light);
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 6px;
    left: 0;
  }
}

//danger text
.dot-red {
  padding-left: 16px;
  position: relative;
  &::before {
    height: 8px;
    width: 8px;
    background-color: var(--fill-orangered);
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 6px;
    left: 0;
  }
}

.dot-orange {
  padding-left: 16px;
  position: relative;
  &::before {
    height: 8px;
    width: 8px;
    background-color: var(--fill-orange);
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 6px;
    left: 0;
  }
}

/*-------------Sidebar CSS-------------*/
.sidebar {
  background-color: var(--fill-white);
  padding: 16px 20px;

  .tabs {
    width: auto;
    display: inline-flex;
  }

  .sidebar-listing {
    margin-top: 8px;
  }

  //heading
  .sidebar-heading {
    font-size: 18px;
    line-height: 32px;
    color: var(--fill-locofast-black);
    font-weight: bold;
    margin-bottom: 10px;
  }
}

@mixin dashboard-tabs {
  text-transform: capitalize;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 10px;
  font-weight: 400;
  cursor: pointer;
}

//tab active
.dashboard-tabs-active {
  @include dashboard-tabs();
  font-weight: 600;
  color: var(--fill-primary-blue);
  background-color: var(--fill-blue-light);
}

//tab default
.dashboard-tabs-default {
  @include dashboard-tabs();
  color: var(--fill-locofast-black);
  &:hover {
    background: var(--fill-grey-light5);
  }
}

//profile section
.quote-status-section {
  display: flex;
  align-items: center;
  padding-top: 16px;
  border-bottom: 1.8px solid var(--fill-grey-light5);
  padding-bottom: 16px;

  //img container
  .status-section-img {
    width: 40px;
    height: 40px;
    margin-right: 12px;

    //img
    .status-img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  //title container
  .status-section-content {
    display: flex;
    flex-direction: column;

    //title top
    .status-title-top {
      color: var(--fill-locofast-black);
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
    }

    //title bottom
    .status-title-bottom {
      color: var(--fill-grey-medium);
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      margin-top: 5px;
    }
  }
}

//top space
.recent-activity-title {
  margin-top: 16px;
}

.hover-sidebar {
  .quote-status-section:hover {
    background-color: var(--fill-grey-light5);
    border-radius: 8px;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
    .blu-title {
      color: var(--fill-primary-blue);
      display: block;
      font-weight: 600;
    }
    .hover-none {
      display: none;
    }
  }
  .d-none {
    display: none;
  }
}

/*-------------Active card CSS-------------*/
.active-design {
  background: var(--fill-white);
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 8px;

  //main container
  .active-design-conatiner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 0;

    //title
    .design-conatiner-heading {
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      color: var(--fill-locofast-black);
    }
  }

  .design-active-table {
    margin-top: 10px;
  }

  //
  .design-table-header {
    grid-template-columns: repeat(1, 1fr) 165px 220px 220px;
    padding: 12px 20px;
    display: grid;
    border-bottom: 1px solid var(--fill-grey-light3);
    line-height: 15px;

    //title
    .table-header-column {
      color: var(--fill-grey-medium);
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }
  }

  //table body
  .design-table-row {
    grid-template-columns: repeat(1, 1fr) 165px 220px 220px;
    display: grid;
    padding: 12px 20px;
    align-items: center;
  }

  .design-table-container {
    min-height: 336px;
    max-height: 336px;
    position: relative;
  }

  //column design
  .design-col {
    display: flex;
    align-items: center;

    // Design name
    :nth-child(2) {
      max-width: 200px;
    }
    //design col
    .col-img {
      width: 24px;
      height: 24px;
      margin-right: 12px;

      //img
      .design-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }
  //title
  .col-title {
    font-size: 14px;
    line-height: 20px;
    color: var(--fill-locofast-black);
  }

  //title
  .request-col {
    text-align: center;
  }

  //pagination
  .design-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--fill-grey-light3);
    padding: 8px 20px;
  }

  //pagination
  .bottom-pagination {
    display: flex;
    align-items: center;

    //img
    .arrow-img {
      margin-left: 15px;
      cursor: pointer;
      padding: 0;

      &.disabled {
        cursor: default;
        svg * {
          stroke: var(--fill-grey-light2);
        }
      }
    }

    //title
    .pagination-title {
      font-size: 12px;
      line-height: 16px;
      color: var(--fill-locofast-black);
    }

    button[disabled] {
      cursor: not-allowed;
      img {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }
}

//hover
.design-table-row:hover {
  background-color: var(--fill-blue-light);
  padding-left: 20px;
  padding-right: 20px;

  .request-col {
    display: block;
    color: var(--fill-primary-blue);
  }

  .blu-title {
    color: var(--fill-primary-blue);
  }
}

.d-none {
  display: none;
}

.design-conatiner-heading {
  display: flex;
  align-items: center;
}

//img
.active-icon {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

/*-------------Factories and task  card CSS-------------*/
.factories-tast-section {
  display: flex;
  width: 100%;
}

.active-factory {
  width: 70%;
  background: var(--fill-white);
  margin-top: 20px;
  margin-right: 16px;
  border-radius: 8px;

  .design-table-section {
    height: 336px;
    overflow: auto;
    position: relative;
  }
  .design-table-footer {
    padding: 12px 20px;
  }
}

.task-design {
  background: var(--fill-white);
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 8px;
  padding: 16px 20px 0px;
  width: 30%;

  .design-conatiner-heading {
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
    color: var(--fill-locofast-black);
    margin-bottom: 16px;
  }

  .task-section {
    height: 400px;
    position: relative;
  }
}

.active-factory {
  .design-table-header {
    grid-template-columns: 232px repeat(3, 1fr);
  }
  .design-table-row {
    padding: 14px 20px;
    grid-template-columns: 232px repeat(3, 1fr);
  }
}

//title blue
.req-sample-blue {
  color: var(--fill-primary-blue);
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 600;
}

//profile
.task-req-section {
  border-bottom: 0px;
}
/*-------------Dispatch card CSS-------------*/
.dispatch-design {
  background: var(--fill-white);
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 8px;
  padding: 16px 20px 0px;

  .empty-dispatches {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 120px 0;

    h3 {
      font-size: 16px;
    }

    p {
      margin: 0;
      font-size: 14px;
      width: 200px;
      text-align: center;
      color: var(--fill-grey-dark);
    }
  }

  //content
  .dispatch-design-conatiner {
    display: flex;
    align-items: flex-start;

    //calender
    .calender-section {
      width: 50%;
    }
  }

  //title
  .design-conatiner-heading {
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
    color: var(--fill-locofast-black);
  }

  .order-title {
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    padding: 2px 12px;
    color: var(--fill-grey-medium);
    background: var(--fill-grey-light5);
  }
}

//title
.status-title {
  font-size: 16px;
  line-height: 32px;
  color: var(--fill-locofast-black);
  margin-bottom: 20px;
}

//parent of status
.timing-status-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

//right container
.dispatch-status-section {
  width: 50%;
  height: 436px;
  max-height: 436px;
  display: flex;
  flex-direction: column;

  &-content {
    position: relative;
    flex: 1;
    height: 100%;
  }
}

.profile-section-status {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
//
.gray-dot-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .grey-circle-new {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--fill-grey-medium);
    margin-bottom: 3px;
  }
  .grey-line {
    width: 1px;
    height: 10px;
    background-color: var(--fill-grey-medium);
    margin-bottom: 3px;
  }
  .grey-square {
    width: 8px;
    height: 8px;
    background-color: var(--fill-grey-medium);
  }
}

//title
.profile-section-title {
  margin-left: 10px;
}

.due-status-section {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  border-bottom: 1.8px solid var(--fill-grey-light5);
  padding-bottom: 14px;

  //side img
  .status-section-img {
    width: 40px;
    margin-right: 12px;
    .status-img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

//title
.status-title-location {
  font-size: 14px;
  line-height: 20px;
  color: var(--fill-grey-medium);
  position: relative;

  .visible-on-hover {
    left: -22px;
    bottom: 25px;
    width: 250px;

    span {
      color: var(--fill-grey-medium);
      font-size: 12px;
    }

    p {
      margin: 0;
      padding-top: 4px;
      line-height: 20px;
      color: var(--fill-locofast-black);
    }
  }

  &:hover {
    .visible-on-hover {
      opacity: 1;
      height: auto;
      display: block;
    }
  }
}

.title--pb {
  padding-bottom: 3px;
}

//title
.timing-status-title {
  font-size: 14px;
  line-height: 20px;
  color: var(--fill-locofast-black);
  margin-bottom: 8px;
}

//title success
.timing-status-success {
  font-size: 14px;
  line-height: 20px;
  color: var(--fill-success);
}

//title delayed
.timing-status-delayed {
  font-size: 14px;
  line-height: 20px;
  color: var(--fill-orangered);
}

.timing-status-right {
  text-align: right;
}

/*-------------My team card CSS-------------*/
.my-team-section {
  .design-table-header {
    grid-template-columns: repeat(5, 1fr);
  }
  .design-table-row {
    grid-template-columns: repeat(5, 1fr);
  }

  .wid-192 {
    width: 195px;
  }

  .design-table-section {
    min-height: 336px;
    position: relative;
  }
}

// brand-dash__content
.dashboard-body-style .brand-dash__content {
  overflow: auto;
}

.visible-on-hover {
  opacity: 0;
  height: 0;
  display: none;
  position: absolute;
  z-index: 2;
  background: var(--fill-white);
  padding: 12px;
  box-shadow: 0px 2px 8px rgba(0, 1, 38, 0.1);
  color: var(--fill-locofast-black);
  border: 1px solid var(--fill-grey-light3);
  border-radius: 4px;
}
