.input-attachments {
  display: flex;
  background-color: white;

  &__label {
    display: flex;
    align-items: center;
    padding: 8px;
    white-space: nowrap;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }

    .text {
      margin-left: 8px;
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &__file {
    &-item {
      display: flex;
      background: #f2f2f2;
      border-radius: 4px;
      padding: 8px;
      align-items: center;
      margin-top: 8px;

      .btn--img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        height: 20px;
        width: 20px;
        margin-left: 4px;

        .loco-icon {
          height: 12px;
          width: 12px;
        }
      }
    }

    &-name {
      flex: 1;
    }
  }

  &--dir {
    &-col {
      flex-direction: column;
    }

    &-row {
      flex-direction: row;

      .input-attachments__file {
        &-list {
          display: flex;
          flex-direction: row;
        }

        &-item {
          margin-left: 8px;
          margin-top: 0;
          max-width: 150px;
        }
      }
    }
  }
}
