.datepicker-inline-view {
  // margin-right: 4rem;
  margin-top: 12px;
  margin-bottom: 20px;

  .react-datepicker {
    box-shadow: none;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
  }

  .visible-on-hover {
    right: -50px;

    .green,
    .red {
      line-height: 16px;
      padding-left: 16px;

      span {
        color: var(--fill-grey-medium);
        padding-right: 8px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 12px;
        top: 20px;
        border-radius: 50%;
      }
    }

    .green {
      &::before {
        background: var(--fill-success);
      }
    }
    .red {
      &::before {
        background: var(--fill-orangered);
        top: 38px;
        left: 12px;
      }
    }
  }

  .react-datepicker__day {
    font-size: 12px;
    width: 56px;
    border-radius: 4px !important;
    line-height: 50px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 28px;
    height: 60px;
    margin: 0;
    position: relative;

    &:hover {
      background-color: var(--fill-grey-light5);

      .visible-on-hover {
        opacity: 1;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        transform: translate(56px, 8px);
      }
    }
  }

  .react-datepicker__day-name {
    width: 48px;
  }

  .react-datepicker__current-month {
    color: var(--fill-primary-blue);
    border-bottom: 0;
  }

  .react-datepicker__day--today.react-datepicker__day--selected {
    background-color: var(--fill-selection-dark);
    color: var(--fill-white);
  }

  .react-datepicker__day--today:not(.react-datepicker__day--selected) {
    background-color: var(--fill-white);
    color: var(--fill-primary-blue);
  }

  .react-datepicker__header {
    border-bottom: 0 !important;
    .grid-header {
      display: grid;
      grid-template-columns: repeat(5,1fr);
      width: 90%;
      font-size: 14px;
      font-weight: 600;
      color: var(--fill-grey-medium);

      .prev {
        color: var(--fill-grey-medium);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          margin-right: 4px;
          width: 4px;
          height: 4px;
          background: var(--fill-orangered);
          border-radius: 50%;
          display: inline-block;
        }
      }

      .next {
        color: var(--fill-locofast-black);
        cursor: pointer;
      }

      .current {
        color: var(--fill-primary-blue);
      }
    }
  }

  .react-datepicker__day--selected {
    background-color: var(--fill-blue-light2);
    color: var(--fill-primary-blue);
    position: relative;
    font-weight: bold;

    &:not(.highlighted-dates) {
      &::before {
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin: 0 auto;
        bottom: 12px;
        content: "";
        left: 0;
        right: 0;
        border: 1px solid var(--fill-grey-medium);
        background: transparent;
      }
    }
  }

  .react-datepicker__day--today {
    &:not(.highlighted-dates) {
      &::before {
        border: 1px solid var(--fill-white);
      }
    }
  }

  // TODO: Move the + icon to day render element.
  .dummy-class {
    background-image: url("./../../../assets/images/dashboard/plus.svg") !important;
    background-size: 8px !important;
    background-repeat: no-repeat !important;
    background-position: 36px 40px !important;
  }

  .react-datepicker__day--outside-month {
    pointer-events: none;
    color: var(--fill-grey-light2)
  }
}

@mixin pseudoStyles {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 auto;
}

@mixin withoutPlus {
  bottom: 12px;
  content: "";
  left: -24px;
  right: 0;
}

@mixin withPlus {
  font-family: "FontAwesome";
  bottom: 12px;
  content: "\f111";
  font-size: 9px;
  top: 19px;
  right: 24px;
}

.react-datepicker__day--highlighted-custom {
  &-green {
    position: relative;
    &:not(.dummy-class) {
      &:before {
        left: 0;
      }
    }
    &:before {
      @include pseudoStyles();
      @include withoutPlus();
      background: var(--fill-green-light);
    }
  }

  &-red {
    position: relative;
    &:not(.dummy-class) {
      &:before {
        left: 0;
      }
    }
    &:before {
      @include pseudoStyles();
      @include withoutPlus();
      background: var(--fill-orangered);
    }
  }

  &-grey {
    position: relative;
    &:not(.dummy-class) {
      &:before {
        left: 0;
      }
    }
    &::before {
      @include pseudoStyles();
      @include withoutPlus();
      background: var(--fill-grey-light2);
    }
  }

  &-greydouble {
    position: relative;

    &:not(.dummy-class) {
      &:before {
        left: 14px;
      }
    }

    &::after {
      @include pseudoStyles();
      @include withoutPlus();
      left: 0;
      right: 12px;
      background: var(--fill-grey-light2);
    }
  }

  &-greendouble {
    position: relative;

    &:not(.dummy-class) {
      &:before {
        left: 14px;
      }
    }

    &::after {
      @include pseudoStyles();
      @include withoutPlus();
      left: 0;
      right: 12px;
      background: var(--fill-green-light);
    }
  }

  &-reddouble {
    position: relative;

    &:not(.dummy-class) {
      &:before {
        left: 14px;
      }
    }

    &::after {
      @include pseudoStyles();
      @include withoutPlus();
      left: 0;
      right: 12px;
      background: var(--fill-orangered);
    }
  }


  &-greenplus {
    position: relative;
    &:after {
      @include pseudoStyles();
      @include withPlus();
      color: var(--fill-green-light);
    }
  }

  &-redplus {
    position: relative;
    &::after {
      @include pseudoStyles();
      @include withPlus();
      color: var(--fill-orangered);
    }
  }

  &-greyplus {
    position: relative;
    &::after {
      @include pseudoStyles();
      @include withPlus();

      color: var(--fill-grey-light2);
    }
  }


}
