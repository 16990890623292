$profile-image-pill-size: 40px;

.factory-list-container {
  display: flex;
  flex-direction: column;
  background-color: var(--fill-blue-light2);

  > .factory-list {
    flex: 1;
    overflow: auto;
  }

  .no-factories {
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 25px;

    .heading {
      font-weight: bold;
      border-bottom: 0;
      padding: 0;
    }
    .sub-heading {
      color: var(--fill-grey-medium);
      font-size: 14px;
    }
  }
}

.factory-list {
  padding: 16px 24px;
}

.factory_row {
  padding: 12px 20px;
  align-items: center;
  background: var(--fill-white);
  color: var(--fill-grey-medium);

  > .factory-cell {
    flex: 1;
    &:last-child {
      flex: 0;
      min-width: 200px;
    }

    &.name {
      display: flex;
      align-items: center;

      .name-container {
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-gap: 16px;
        align-items: center;

        .added-by {
          text-transform: initial;
          line-height: 20px;
          padding-top: 2px;
        }
      }

      > .profile-name {
        margin-left: 16px;
      }
    }
  }

  .extra-brands-count {
    font-size: 10px;
    margin-left: 4px;
  }

  > .name {
    text-transform: capitalize;

    > .profile-image {
      margin-right: 16px;
      display: inline-block;

      > span {
        display: inline-block;
        border-radius: 50%;
        background: dodgerblue;
        color: white;
        width: $profile-image-pill-size;
        height: $profile-image-pill-size;
        line-height: $profile-image-pill-size;
        text-align: center;
        text-transform: capitalize;
        // margin: -10px;
        font-size: 18px;
      }
    }
  }

  .text-black {
    color: var(--fill-locofast-black);
  }

  .edit-icon {
    margin: 0 12px;
  }

  &:hover {
    box-shadow: 0 0 4px rgba(0, 1, 38, 0.25);
  }

  .fa {
    padding: 10px;
    color: var(--fill-grey-dark);
    font-size: 16px;
    font-weight: bold;
  }

  img {
    width: 64px;
  }

  .more-options {
    display: grid;
    grid-template-columns: 1fr 40px 100px;
    align-items: center;

    .grid-3 {
      grid-column-start: 3;
      justify-self: flex-end;
      cursor: pointer;
    }

    .grid-1 {
      justify-self: flex-end;
    }
  }

  .sub_label {
    font-size: 12px;
  }

  .merchants {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 85%;

    .with-hover {
      display: none;
    }
  }

  .production_data {
    > div {
      padding: 4px 0;

      .row-link {
        color: var(--fill-primary-medium);
        cursor: pointer;
        padding: 6px 0;
      }
    }
  }

  .lf-logo {
    background: var(--fill-locofast-black);
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-left: 5px;
    background-image: url("../../../assets/images/ic-logo-short-light.svg");
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
    vertical-align: -3px;
  }
}

.container {
  margin: 16px;
  border: 1px solid var(--fill-grey-light2);
}

.link {
  text-transform: uppercase;
  color: var(--fill-primary-blue);
  font-size: 12px;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.accounts-factories {
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  &.container {
    margin: 8px 0;

    border: 1px solid var(--fill-grey-light3);
    &:first-child {
      margin-top: 0;
    }
  }

  .factory_row {
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &.grid-6 {
      grid-template-columns: 300px repeat(2,1fr) 150px 200px;

    }

    &:hover{
      .with-hover{
        display: block
      }
    }

  }

  .footer-container {
    max-height: 500px;
    overflow: auto;
    border-radius: 0 0 4px 4px;

    .footer-row {
      background: var(--fill-white);
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 12px 20px;
      align-items: center;

      &:first-child {
        border-top: 1px solid var(--fill-grey-light2);
      }
      &:hover {
        background: var(--fill-grey-light5);
      }

      > div {
        flex: 1;
        &:last-child {
          min-width: 200px;
          max-width: 200px;
        }
      }

      .footer-row-image {
        flex: 1;
        display: flex;
        justify-content: center;

        img {
          cursor: pointer;
        }
      }

      .user__profile {
        grid-template-columns: 40px 1fr;
      }
    }
  }
}
