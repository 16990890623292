.move-design-modal {
  min-width: 720px;
  max-width: 720px;
  background: #FCFBFB;

  .collection-card {
    & {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 100px;
      column-gap: 16px;
      box-shadow: 0px 0px 2px rgba(0, 103, 226, 0.12);
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 12px 16px;
      cursor: pointer;
      text-align: left;

      &.card {
        &--selected {
          background: #f2f8ff;
          border: 1px solid #0067e2;
          box-shadow: 0px 0px 2px rgba(0, 103, 226, 0.12);
        }

        &:hover {
          box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.25);
        }

        &:focus {
          outline: none;
          border: 1px solid #0067e2;
        }
      }
    }

    &-container {
      max-height: 400px;
      min-height: 400px;
    }

    &-list {
      display: grid;
      grid-template-rows: 88px;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 8px;
      margin: 4px 24px 4px;
    }

    .card--title {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      flex-direction: column;

      .col-username {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .card--sub-title {
      display: flex;
      margin-top: 12px;

      .line-part {
        font-size: 12px;
        line-height: 16px;

        & + .line-part {
          &::before {
            content: "\2022";
            margin: 0 4px;
            font-size: 10px;
          }
        }
      }
    }

    .card--sub-info {
      display: flex;
      justify-content: flex-end;
    }

    .design-pic {
      max-width: 48px;
      max-height: 48px;
      min-width: 48px;
      min-height: 48px;
      overflow: hidden;
      border-radius: 50%;

      & + .design-pic {
        margin-left: -24px;
        z-index: -1;
      }

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px 24px;

    > .input-grp {
      width: 280px;
    }
  }

  &__count {
    font-weight: 600;
  }
}
