.messaging-design-container {
  display: flex;
  align-items: stretch;
  background: white;
  background-image: url("../../../assets/images/image/payrus.png");

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    order: 1;
  }

  .messaging-header{
    border-bottom: 1px solid var(--fill-grey-light3);
  }

  &__sidebar {
    flex: 0.3;
    background: white;
    height: unset;

    &--align-left {
      order: 0;
      border-right: 1px solid var(--fill-grey-light3);
    }
    &--align-right {
      order: 2;
      border-left: 1px solid var(--fill-grey-light3);
    }
  }

  .messages-list {
    position: relative;
    flex: 1;
    padding: 32px 40px;
  }
}
