.wrapper {
}

.scroll__hack_in_orders {
  max-height: 83vh;
  // background-color: #f5f5f5;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
  background-color: #fff;

  border-bottom: 1px solid var(--fill-grey-light2);
}

.filters__wrapper {
  display: flex;
  align-items: center;
}

// styles for table ====
.table__header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  font-size: 12px;
  color: var(--fill-grey-medium);
  padding: 6px 24px;

  border-bottom: 1px solid var(--fill-grey-light3);

  & > div {
    padding: 10px;
  }
}

// === styles for order item in table ===
.order__wrapper_outer {
  border: solid 1px var(--fill-grey-light3);
  margin: 0px 10px;
  background-color: #fff;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
  }
}
.order {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  color: var(--fill-locofast-black);
  font-size: 14px;

  padding: 6px 14px;

  // & > :not(:last-child) {
  //     border-right: 1px solid #000;
  // }

  background-color: #fff;
  border-radius: 6px;

  & > div {
    padding: 10px;
  }

  // &:not(:last-child) {
  //     margin-bottom: 10px;
  // }
}

.order_item {
}

.order__item_large_font {
  font-size: 14px;
  line-height: 25px;
  color: var(--fill-locofast-black);
}

.order__item_small_font {
  font-size: 12px;
  line-height: 25px;
  color: var(--fill-grey-medium);
}

.order__item_green {
  color: var(--fill-green-dark);
}

.order__item_red {
  color: var(--fill-error);
}

.order__item_orange {
  color: #e6a725;
}

.order__item_large_font_blue {
  color: var(--fill-primary-medium);
  font-size: 12px;
  line-height: 25px;

  cursor: pointer;

  &.designs {
    color: var(--fill-locofast-black);
  }
}

.order__item_city {
  > img {
    vertical-align: -5px;
    margin-right: 5px;
  }
}

// === styels for view dispatch details model ====
.ducumentLink {
  &:visited {
    color: #ff0000;
  }
}
.dispatch {
  min-width: 600px;
  &__wrapper {
    display: grid;
    grid-template-columns: auto 200px;

    // grid-auto-flow: column;
    &:not(:last-child) {
      border-bottom: 1px solid var(--fill-grey-medium);
    }
  }

  &__details_inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    padding: 15px;

    padding-bottom: 15px;
  }

  &__documents {
    padding: 15px;

    background-color: var(--fill-grey-light5);
  }

  &__item {
    line-height: 20px;

    &_label {
      font-size: 14px;
      color: var(--fill-locofast-black);
    }

    &_value {
      font-size: 12px;
      color: var(--fill-grey-medium);
    }
  }
}

.visited_link {
  //    kjdfjfk
}
