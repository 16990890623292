.messaging-factory-item {
  &-list {
    padding: 8px 0;
    background-color: white;
  }

  & {
    display: grid;
    height: 40px;
    padding: 0 16px;
    cursor: pointer;
    grid-template-columns: auto 1fr auto;
    align-items: center;

    &--selected {
      background: var(--fill-selection-light);
      font-weight: 600;
    }

    &--disabled {
      color: var(--fill-grey-dark);
    }

    &:hover{
      background: var(--fill-selection-light);
    }

    .profile-avatar {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;

      > .initials {
        font-size: 14px;
        line-height: 20px;
        font-family: Volkhov;
      }
    }

    &__name {
      font-size: 14px;
      line-height: 20px;
      margin-left: 12px;
      flex:1;
    }

    &__unread-count {
      width: 20px;
      height: 20px;
      border-radius: 14px;
      border: solid 1px #ffffff;
      background-color: #fc3a52;

      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
      margin-left: 12px;
      color: white;
      text-align: center;
    }
  }
}
