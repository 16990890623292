.factory-merchant-form {
  min-width: 500px;
  min-height: 324px;
  max-height: 524px;
  overflow: auto;

  form {
    margin-bottom: 64px;
  }

  .addMerchant {
    color: var(--fill-primary-medium);
    line-height: 1.43;
    font-weight: bold;
    margin: 24px;

    &.btn--disabled {
      background-color: var(--fill-white);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .remove_address {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .row {
    display: flex;
    justify-content: space-around;
    margin: 12px 0;
  }

  .add_btn {
    padding: 15px;
    padding-bottom: 30px;
  }

  .select-container {
    user-select: none;
    position: relative;
    cursor: pointer;
    border: solid 1px var(--fill-grey-light);
    border-radius: 2px;
    padding: 6px 12px;
    height: 42px;
    width: 75%;

    input {
      border: none;
      background-color: transparent;
      max-width: 100%;
      width: 100%;
      font-weight: 600;
      color: var(--fill-locofast-black);
      height: 28px;
    }

    img {
      margin-top: 4px;
    }
  }

  .footer {
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid var(--fill-grey-light);
    width: 100%;
    display: flex;
    border-radius: 0 0 5px 5px;
    justify-content: flex-end;
    padding: 8px 24px;
    position: fixed;
  }
}
