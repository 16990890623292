.input-wrapper {
  display: grid;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  position: relative;
  .label {
    font-size: 0.75rem;
    justify-content: space-between;
    display: flex;
  }
  .error {
    font-size: 0.75rem;
    color: red;
  }
}
.btn {
  border: 1px solid var(--fill-grey-light8);
  border-radius: 8px;
  color: var(--text-light);
  display: block;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  margin-right: 8px;
  padding: 10px 12px;
  white-space: nowrap;
  cursor: pointer;
}
.btnClick {
  background: var(--fill-sky-blue);
  color: var(--fill-primary-blue);
  font-weight: 600;
  padding-left: 28px;
  position: relative;
  border: none;
  border-radius: 8px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  margin-right: 8px;
  padding: 10px 12px;
  white-space: nowrap;
  cursor: pointer;
}
.box {
  display: flex;
  align-items: center;
  gap: 6px;
}
.radioButton,
.checkboxButton {
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }

  input[type="radio"] + span,
  input[type="checkbox"] + span {
    border: 1px solid var(--fill-grey-light8);
    border-radius: 8px;
    color: var(--text-light);
    display: block;
    font-size: 14px;
    font-weight: normal;
    height: auto;
    margin-right: 8px;
    padding: 10px 12px;
    white-space: nowrap;
  }

  input[type="radio"]:checked + span,
  input[type="checkbox"]:checked + span {
    background: var(--fill-sky-blue);
    color: var(--fill-primary-blue);
    font-weight: 600;
    padding-left: 28px;
    position: relative;
    border: none;

    &::before {
      background: url("../../../../assets/images/fabricQualities/Construction.svg");
      content: "";
      height: 12px;
      left: 10px;
      position: absolute;
      top: 14px;
      width: 12px;
    }
  }

  input[type="radio"]:disabled + span,
  input[type="checkbox"]:disabled + span {
    opacity: 0.5;
  }

  &.withoutTick {
    input[type="radio"]:checked + span,
    input[type="checkbox"]:checked + span {
      padding-left: 12px;

      &::before {
        content: "";
        height: 0;
        width: 0;
      }
    }
  }
}
