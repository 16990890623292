.design-container-details {
  height: 100%;
  z-index: 15;

  .design-list__subtitle {
    text-transform: capitalize;
    color: var(--fill-grey-medium);
    font-size: 12px;
    line-height: 16px;
  }

  .design-list__subtitle.active {
    color: var(--fill-primary-blue);
  }
}

.design-container-details__sidebar-item {
  height: 56px;
  padding: 18px 16px;
  cursor: pointer;
  align-items: center;
}

.design-list__item--active {
  background-color: #d4efff;
  // background-color: var(--fill-grey-light3);
}

.design-image-container {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.design-caption {
  margin-left: 12px;
  flex: 1;
  overflow: hidden;
}

// .design-list__title {
//   margin-bottom: 4px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   max-width: 100%;
// }

.design-container-details__main {
  flex-grow: 1;
}

.col-custom-list__item--active {
  // background-color: #d4efff;
  background-color: var(--fill-blue-light);
}
