.addSwatchModal {
  width: 700px;
  margin-top: 24px;
  padding: 0px 24px;
  // max-height: 432px;

  &__scroll {
    max-height: 500px;
  }

  &__input_grp {
    padding: 10px 0px;
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
    align-items: center;

    & > :first-child {
      padding: 11px 0px;
    }
    & > :last-child {
      margin-left: auto;
      width: auto !important;
    }

    &:last-child {
      box-shadow: none !important;
    }
  }

  &__select {
    width: 250px;
    * {
      height: 40px;
    }
  }


  &__adjacent {
    overflow: auto;


    >div {
      margin: 0 12px;
      padding: 0;
    }

    &:after {
      content: '';
      top: 24px;
      bottom: 0;
      width: calc(100% - 48px);
      background: linear-gradient(90deg, transparent 85%, white);
      height: 192px;
      position: absolute;
      pointer-events: none;
      z-index: 2;
    }
  }


  &__add_image {
    display: flex;
    align-items: center;
    position: sticky;
    right: 0;
    z-index: 3
  }

  .column {
    flex-direction: column;
  }



.field_err {
  color: var(--fill-error)
}
}

.loco__radio {

    div:first-child {
      margin-right: 16px;
    }

    label {
      display: flex;
      line-height: 20px;
    }

    span {
      flex-grow: 1;
      margin-bottom: 0;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:checked + span,
    [type="radio"]:not(:checked) + span {
      position: relative;
      // padding-left: 28px;
      cursor: pointer;
      line-height: 19px;
      display: inline-block;
    }

    [type="radio"]:checked + span:before,
    [type="radio"]:not(:checked) + span:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1.5px solid rgba(0, 1, 38, 0.7);
      border-radius: 100%;
      background: #fff;
    }

    [type="radio"]:checked + span:before {
      border: 1px solid var(--fill-secondary-dark) !important;
    }

    [type="radio"]:checked + span:after,
    [type="radio"]:not(:checked) + span:after {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--fill-secondary-dark);
      position: absolute;
      top: 4px;
      right: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + span:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="radio"]:checked + span:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    display: flex;
    justify-content: space-between;
    // flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    padding: 14px 16px;

    span {
      margin: 0;
      line-height: 1.43;
      letter-spacing: 0.2px;
      font-size: 14px;
      font-weight: 600;
    }

    input {
      margin: 0;
    }

    &.loco__radio--left-align {
      &.loco__radio--light {
        [type="radio"]:checked + span:before,
        [type="radio"]:not(:checked) + span:before {
          border-width: 1px;
        }
      }

      [type="radio"]:checked + span,
      [type="radio"]:not(:checked) + span {
        position: relative;
        padding-left: 26px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
      }

      [type="radio"]:checked + span:before,
      [type="radio"]:not(:checked) + span:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1.5px solid rgba(0, 1, 38, 0.7);
        border-radius: 100%;
        background: #fff;
      }

      [type="radio"]:checked + span:after,
      [type="radio"]:not(:checked) + span:after {
        content: "";
        width: 12px;
        height: 12px;
        background: var(--fill-secondary-dark);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }
