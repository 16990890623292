.order-org-row {
  background: white;
  border: none;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  column-gap: 16px;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 18px 15px;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;

  &--hovered,
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.25);
    outline: none;

    .btn.cta--default {
      display: none;
    }

    .btn.cta--on-hover {
      display: block !important;
    }
  }

  &--selected{
    background: #E5F1FF;
    border: 1px solid #82BBFF;
  }

  &__org {
    &-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &-address {
      color: #676773;
      margin-top: 4px;
    }
  }

  .brand-image img{
    max-height: 40px;
    max-width: 40px;
  }

  .btn {
    font-size: 12px;
    padding: 4px 12px;
    height: unset;

    &.cta {
      &--on-hover {
        display: none;
      }
    }
  }

  .brand-image .initials {
    font-size: 20px;
    line-height: 28px;
  }
}
