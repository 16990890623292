.tna-graph {
  &__timeline {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    padding: 0 24px;

    .timeline {
      position: relative;
      min-height: 40px;
      text-align: center;
      padding-bottom: 33px;
      font-style: normal;
      font-weight: normal;
      z-index: 0;
      cursor: pointer;

      &:first-child {
        div {
          white-space: nowrap;
        }
      }

      &__heading {
        font-size: 14px;
        line-height: 20px;
        &:first-letter {
          text-transform: uppercase;
        }
      }

      &__date {
        font-size: 12px;
        line-height: 16px;
        color: var(--fill-grey-dark);
        padding-top: 4px;
      }

      &__pointer {
        display: inline-block;
        visibility: hidden;
        position: absolute;
        transform: translate(-50%);
        bottom: -1px;
        padding-top: 20px;
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border: none;
        background-color: var(--fill-green-2);
        box-shadow: 0 0 0 2px #fff;
        display: block;
        text-align: center;
        margin: 0 auto 16px auto;
        border-radius: 50%;
        box-sizing: border-box;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: var(--fill-success);
        top: 5px;
        left: 50%;
        z-index: -1;
      }

      &:last-child::after {
        content: none;
      }

      &--active {
        .timeline__heading {
          font-weight: bold;
        }
        .timeline__pointer {
          visibility: visible;
        }
      }

      &--progress {
        ~ div {
          color: var(--fill-grey-medium);
          pointer-events: none;
        }
        &::before {
          background-color: var(--fill-green-2);
          border: none;
          box-shadow: 0 0 0 4px rgba(111, 207, 151, 0.25);
        }

        ~ div .timeline__date {
          color: var(--fill-grey-medium);
        }
        ~ ::after,
        &::after {
          background-color: var(--fill-grey-light2);
        }
        ~ ::before {
          border: 2px solid;
          border-color: var(--fill-grey-light2);
          background-color: var(--fill-white);
          box-shadow: none;
        }
      }

      & div {
        white-space: nowrap;
      }
    }
  }
}
