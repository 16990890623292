.courier__details {
  min-width: 400px;
  min-height: 334px;
}

.dispatch-container {
  width: 400px;
  padding: 0 24px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 460px;

  input:disabled {
    color: #bfbfbf;
    background-color: #f5f5f5 !important;
    background-image: none;
    cursor: default;
  }

  .label__class {
    padding: 12px 2px 6px 2px;
  }

  .counter-field {
    > .label__class {
      margin: 8px 0;
      color: var(--fill-grey-dark);
      font-size: 14px;

      &--disabled {
        color: var(--fill-grey-medium);
      }
    }

    > .loco__radio {
      display: inline-block;
      & + .loco__radio {
        margin-left: 20px;
      }
    }
  }

  .field-attachment {
    margin: 12px 0;
    display: flex;
    flex-direction: column;

    .label__class {
      color: var(--fill-grey-dark);
      font-size: 14px;
      margin-bottom: 4px;
    }

    .sample-files {
      justify-content: left;

      &-item {
        &:not(:first-child) {
          margin-left: 4px;
        }
      }
    }
  }

  .w-100 {
    width: 100%;
  }
}

.success-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  img {
    width: 100px;
    height: 100px;
  }

  h4 {
    color: var(--fill-success);
    font-size: 20px;
    margin: 12px;
    font-weight: bold;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    margin: 0;
    max-width: 352px;
    text-align: center;
  }

  a {
    margin-top: 32px;
  }
}
