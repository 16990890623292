@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.swatch-card {
  min-height: 370px;  // chnaged this from 300 to 320 after adding padding ..
  $swatch-card-width: 214px;

  $swatch-card-img-height: 250px;
  transition: 0.2s all;
  cursor: pointer;

  // ======
  padding: 12px 8px;
  // &:hover {
  //   padding: 10px;
  // }

  & > #checkbox {
    opacity: 0;
  }

  // Opacity override on selected card state
  &.swatch-card--selected > #checkbox {
    opacity: 1 !important;
  }

  &:hover > #checkbox {
    opacity: 1;
  }

  & > * {
    width: $swatch-card-width;
    line-height: 1.43;
    // margin-bottom: 32px;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);
    // transform: translateY(-5px);
  }

  .swatch-card__img {
    @include aspect-ratio($swatch-card-width, $swatch-card-img-height);

    // border-radius: 8px;  this is after adding padding 
    max-width: $swatch-card-width;
    max-height: $swatch-card-img-height;

    min-height: $swatch-card-img-height;

    margin-bottom: 16px;
  }

  .swatch-card__more_color {
    color: var(--fill-grey-medium)
  }

  .swatch-card__more-color-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 6px;
    display: inline-block
  }

  .caption {
    font-size: 12px;
    color: var(--fill-grey-medium);
    text-transform: capitalize;
  }
}
