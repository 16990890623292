.move-design-cfm-modal {
  min-width: 720px;
  max-width: 720px;
  background: #fcfbfb;
  height: 544px;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .anim-move {
    margin: 0 45px;
  }
}
