.item {
  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 200px 1fr 200px;
    &__is_brand {
      grid-template-columns: 1.2fr 1fr 1fr 0.8fr;
    }
  }

  &__wrapper {
    font-size: 14px;
  }

  &__no_margin {
    margin: 0;
  }

  &__image {
    margin-right: 10px;
  }

  &__status_state {
    color: #df6100;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__status_normal {
    font-size: 12px;
    text-transform: uppercase;
  }

  &__status_error {
    color: var(--fill-error);
    font-size: 12px;
    position: relative;
    padding-left: 15px;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background: var(--fill-error);
      top: 5px;
      border-radius: 50%;
      left: 0px;
    }
  }

  &__status_ontime {
    color: var(--fill-green-dark);
    font-size: 12px;
    position: relative;
    padding-left: 15px;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background: var(--fill-green-dark);
      top: 5px;
      border-radius: 50%;
      left: 0px;
    }
  }

  &__progress_btn {
    text-transform: uppercase;
    font-size: 12px;

    color: var(--fill-secondary-dark);
  }
}
