.copy-tna-modal {
  height: 496px;
  max-height: 496px;
  width: 968px;
  background-color: #fcfbfb;

  &__grouped-date {
    font-size: 12px;
    line-height: 24px;
    color: var(--fill-light-gray);
  }

  &__grouped-designs {
    margin-top: 8px;
  }

  &__grouped-item + .copy-tna-modal__grouped-item {
    margin-top: 8px;
  }

  &__split-container {
    display: grid;
    grid-template-columns: 512px 1fr;
    height: calc(100% - 72px);
  }

  &__grouped-container {
    padding: 16px 32px;
  }

  &__design-plan-container {
    background-color: white;
    position: relative;
    padding: 24px 32px;

    .empty-list-container {
      background-color: #fff8e2;
      padding: 16px;
      width: auto;
      border-radius: 8px;

      .content {
        margin-top: 12px;
      }

      .description {
        white-space: nowrap;
      }
    }
  }

  .design-card-with-tna {
    padding: 8px 16px;
    display: grid;
    grid-template-columns: auto 128px 1fr;
    column-gap: 8px;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 2px rgba(0, 103, 226, 0.12);
    border-radius: 8px;
    cursor: pointer;

    & + .design-card-with-tna {
      margin-top: 8px;
    }

    &.card {
      &--selected {
        background: #f2f8ff;
        border: 1px solid var(--fill-selection-dark);
        box-shadow: 0px 0px 2px rgba(0, 103, 226, 0.12);
      }

      &:hover {
        box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.25);
      }

      &:focus {
        outline: none;
        border: 1px solid var(--fill-selection-dark);
      }
    }

    &__design-info {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 16px;
      align-items: flex-start;

      .design-name {
        color: var(--fill-grey-dark);
        line-height: 20x;
      }

      .design-id {
        color: var(--fill-light-gray);
        margin-top: 8px;
      }
    }

    &__tna-info {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 24px;

      .tna-step {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &__indicator {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 8px;
          border: 1px solid var(--fill-light-gray);
          border-radius: 50%;
        }

        &__name {
          color: var(--fill-grey-dark);
          font-weight: 600;
        }

        &__date {
          color: var(--fill-light-gray);
          margin-left: 8px;
        }

        &--idle > .tna-step__indicator {
          background-color: var(--fill-sea-buckthorn);
          border-color: transparent;
        }

        &--on-time > .tna-step__indicator {
          background-color: var(--fill-success);
          border-color: transparent;
        }

        &--delayed > .tna-step__indicator {
          background-color: var(--fill-orangered);
          border-color: transparent;
        }

        & + .tna-step {
          margin-top: 8px;
          position: relative;

          &::before {
            content: " ";
            position: absolute;
            display: inline-block;
            width: 2px;
            height: 16px;
            background: var(--fill-grey-light2);
            left: 3px;
            bottom: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .design-tna-plan {
    .design-info {
      display: grid;
      grid-template-columns: 32px 1fr;
      grid-column-gap: 8px;
      align-items: center;
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .tna-row {
      display: grid;
      grid-template-columns: 1fr 0.6fr;
    }

    .tna-category {
      &__row {
        font-weight: 600;
        line-height: 42px;
      }

      &__sub-text {
        color: var(--fill-grey-medium);
      }

      & + .tna-category {
        margin-top: 40px;
      }
    }

    .tna-subcategory {
      &__row {
        color: var(--fill-grey-dark);
        line-height: 40px;
      }
    }
  }

  .inline {
    display: inline;
  }
}
