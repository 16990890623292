.design-details-custom {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .card {
    border: none;
    border-radius: 0;
    background: var(--fill-white);
  }

  .header-tabs {
    &__panel {
      height: unset;
      max-height: calc(100% - 61px);
    }

    &__custom-header {
      min-height: 84px;
    }
  }
}

.design-details__header {
  .btn--blue-inverted {
    font-size: 14px;
  }

  .btn {
    text-transform: unset;
  }
}

.design-details-custom__title {
  line-height: 32px;
  align-items: center;
  font-size: 18px;
}

.design-details-custom__metadata {
  color: #333340;
}

.design-details-custom__center {
  margin-left: auto;
  max-width: 206px;
  max-height: 55px;
  border-radius: 26px;
  background-color: #f1f1f1;
  padding: 4px;
}

.design-details-custom__right {
  display: flex;
  align-items: center;
}

.user-badge {
  user-select: none;
  width: 45px;
  height: 45px;
  border: 2px solid #ffffff;
  border-radius: 22px;
  padding: 10px 7px;
  color: #ffffff;
  align-items: center;
  text-align: center;
}

.user-badge__wrapper {
  :not(:first-child) {
    margin-left: -8px;
    padding-left: 10px;
  }
}

.user-badge__more {
  margin: auto;
  width: 45px;
  height: 45px;
  padding: 8px;
  margin-left: 5px;
}

.with_hover {
  position: relative;

  span {
    opacity: 0;
    position: absolute;
    background: var(--fill-locofast-black);
    color: var(--fill-white);
    top: 44px;
    left: -46px;
    padding: 6px 10px;
    width: 170px;
    z-index: 1;
    text-align: left !important;
    line-height: 16px;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
}

.header-dots {
  margin-left: auto;
  cursor: pointer;
  height: 20px;
}
