.factory-notif-dash__table-header {
  //   min-height: 48px;
  //   height: 48px;
  //   box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
}

.factory-notif-table {
  .react-grid-Container {
    width: calc(100% - 30px) !important;
    // width: 1310px !important;
    margin: 15px;
  }

  .react-grid-Cell:focus {
    outline: none !important;
  }

  .react-grid-HeaderCell {
    border: 1px solid #f2f2f2;
    color: var(--fill-grey-medium);
    display: flex !important;
    align-items: center;
    font-size: 14px;
    background-color: var(--fill-white);
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
  }

  .react-grid-Row--even .react-grid-Cell {
    &:hover {
      background-color: #f5f5f5;
    }
    background-color: #f5f5f5;
  }
  .react-grid-Row--odd .react-grid-Cell {
    &:hover {
      background-color: var(--fill-white);
    }
    background-color: var(--fill-white);
  }
  .react-grid-Row {
    border: 1px solid transparent;
    border-collapse: collapse;
    border-bottom: 1px solid var(--fill-grey-light3);
  }

  .react-grid-Cell {
    font-size: 14px;
    color: var(--fill-locofast-black);
    border: 1px solid transparent;

    border-right: 1px solid var(--fill-grey-light3);
  }

  .react-grid-Row:hover {
    .react-grid-Cell {
      background: #f9f9f9 !important;
    }
  }
  .rdg-selected {
    border: none;
  }
  .react-grid-Row.row-selected {
    .react-grid-Cell {
      border-top: 1px solid #66afe9;
      border-bottom: 1px solid #66afe9;
      box-sizing: border-box;
    }
  }

  .react-grid-Cell:not(.editing) .react-grid-Cell__value {
    white-space: initial;
    border: none;
    overflow: visible !important;
  }

  .notification-dispatch {
    &__container {
      height: inherit;

      .react-grid-HeaderRow {
        width: 1310px !important;
        height: 70px;
      }

      .react-grid-Header {
        height: 70px;
      }

      .react-grid-HeaderCell {
        height: 70px !important;
        white-space: normal;
        width: 156px;
      }

      .react-grid-HeaderCell:nth-child(8) {
        width: 140px !important;
      }

      .widget-HeaderCell__value {
        white-space: normal;
      }

      .react-grid-Viewport {
        top: 70px !important;
      }
    }
  }

  .design {
    &-icon {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      border-radius: 5px;
      position: relative;
      display: inline-block;
      background-color: #efefef;
    }
  }
}

.notif-user-list__user-name {
  font-size: 15px;

  font-weight: 600;

  font-style: normal;

  font-stretch: normal;

  line-height: 1.47;

  letter-spacing: 0.2px;

  color: var(--fill-locofast-black);
}

.notif-user-list__user-email {
  font-size: 14px;

  font-weight: normal;

  font-style: normal;

  font-stretch: normal;

  line-height: normal;

  letter-spacing: normal;

  color: var(--fill-grey-dark);
}

.notifications-container {
  .hide-visibility {
    display: none;
  }
  .flex > div {
    margin-left: 15px;
  }

  .notification-filter{
    justify-content: flex-start;
    padding-left: 1em;
  }
}
