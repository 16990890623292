.inventory_modal {
  height: 556px;
  padding-right: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    font-weight: bold;
  }

  .info-note {
    background: var(--fill-yellow-light);
    padding: 12px 16px;
    width: 100%;
    display: flex;
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light3);
    margin-bottom: 16px;

    > :first-child {
      width: auto;
      margin-right: 8px;
    }
  }

  .scrollbar-container {
    height: auto;
    flex: 1;
  }

  .react-select__indicator-separator {
    width: 0;
  }

  form {
    width: 98%;
  }

  .form__row {
    padding-bottom: 24px;
    > :first-child {
      padding: 8px 0;
    }
  }

  .details__item {
    margin-bottom: 24px;

    .label {
      color: var(--fill-grey-medium);
      line-height: 20px;
      margin-bottom: 4px;
    }
  }

  .padding-0 {
    .form__row {
      padding: 0;
    }
  }

  .schedule-inward-check {
    width: 100%;
    font-size: 14px;
    padding-top: 1px;
  }

  .inward-date {
    width: 100%;

    .custom_datepicker {
      line-height: 16px;

      svg {
        margin: -2px 0;
        height: 20px;
        width: 20px;
      }
    }
  }

  .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .field-inward-date {
    &.grid-2 {
      .schedule-inward-check {
        margin-top: 32px;
      }
    }
  }

  .input-file__meta-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    background: var(--fill-grey-light5);
    border-radius: 4px;
    font-size: 14px;

    .clickable {
      height: 20px;
    }
  }
}
