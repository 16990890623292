$tna-note-border-color: #e5e5e5;

.tna-note {
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  column-gap: 12px;
  padding: 16px;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;

    &__line {
      &:first-child {
        color: #333340;
      }

      &:not(:first-child) {
        margin-top: 4px;
      }

      > span + span::before {
        content: '\00B7';
        margin: 0 4px;
      }
    }
  }

  &-list {
    background-color: #f2f2f2;
    border-radius: 8px;

    &__footer {
      padding: 10px 52px;
      display: flex;
      border-top: 1px solid $tna-note-border-color;

      .btn {
        background-color: transparent;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      img {
        transition: all 200ms;
      }
    }

    &--expanded {
      .tna-note-list__footer {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  & + .tna-note {
    &::before {
      content: " ";
      position: absolute;
      height: 1px;
      left: 52px;
      right: 16px;
      background-color: $tna-note-border-color;
    }
  }
}
