.create-order {
  background-color: white;
  height: 100%;

  .heading {
    border-bottom: none;
  }

  &__progress-steps {
    height: 100%;

    > .progress-steps {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .bar__wrapper {
      padding: 4px 24px;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 20px;
    }

    .bar__content {
      flex: 1;
      overflow: hidden;
    }
  }
}
