.formik-input-logo {
  .formik-field {
    &__input {
      display: grid;
      grid-template-columns: auto 1fr auto;
      column-gap: 20px;
      height: 104px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      padding-right: 12px;

      &__img {
        width: 104px;
        background-color: #f2f8ff;
        height: 106px;
        margin-top: -1px;
        margin-left: -1px;
        border-radius: 4px 0 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .loco-icon {
          height: 40px;
          width: 40px;
        }
      }

      .cell-v2 {
        flex: 1;
      }

      .btn {
        font-weight: bold;
      }
    }
  }
}
