@import "../../../assets/scss/mixins";

.messaging-footer {
  display: flex;
  align-items: center;
  background: white;
  padding: 12px 25px;

  padding: 12px 24px;
  border-top: 1px solid var(--fill-grey-light3);
  background: var(--fill-grey-light5);

  &--blocked {
    color: var(--fill-grey-medium);
    > .loco-icon {
      width: unset;
      margin-right: 16px;
      path {
        fill: var(--fill-grey-medium);
      }
    }
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .textarea {
    width: 100%;
    height: 43px;
    font-size: 14px;
    max-height: 100px;
    margin-right: 16px;
    padding: 10px 16px;
    border-radius: 5px;
    color: var(--fill-locofast-black);
    background-color: white;
    // border: 1px solid var(--fill-grey-light3);
    overflow: auto;
  }

  .attach {
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 12px;
    margin-right: 16px;
    padding: 10px 10px;
    border: 1px dashed var(--fill-grey-light2);
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent;
  }

  .button {
    border: 0px;
    height: 40px;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    max-width: 65px;
    max-height: 100px;
    padding: 12px 16px;
    border-radius: 20px;
    text-transform: uppercase;
    color: var(--fill-grey-medium);
    background-color: var(--fill-grey-light3);
  }
}
