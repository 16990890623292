.brand-dash {
  height: 100%;
  background-color: var(--fill-white);
}

.brand-dash__header {
  height: 8%;
  padding: 8px 10px;
  border-bottom: 1px #000000 solid;
}

.brand-dash__actions {
  margin-left: auto;
}

.brand-dash__main {
  display: flex;
  // 100% of page height - Header height
  height: calc(100% - 56px);
}

.brand-dash__content {
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  // overflow: auto;
  // overflow-x: hidden;

  .reports {
    &__container {
      height: 100%;

      > div:first-child {
        height: 100%;
      }

      .int-sidebar {
        width: 250px;
        min-width: 250px;
        display: block;

        &__list {
          &:first-child {
            margin-top: 8px;
          }

          a {
            font-weight: 600;
          }
        }
      }
    }

    &__content {
      position: relative;
      flex: 1;

      .inline__loader {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);

        .inner-circle {
          left: unset;
        }
      }
    }
  }
  .report-content {
    display: grid;
    grid-template-rows: auto auto 1fr;
    max-height: calc(100vh - 56px);
  }
}
