// DEFAULT COLOR VARIABLES

$ball-active-color: var(--fill-primary-blue);
$ball-inactive-color: #f1f1f1;

$active-color: var(--fill-selection-light2);
$inactive-color: #cccccc;

// DEFAULT SIZING VARIABLES

$switch-size: 34px;
$ball-spacing: 2px;
$stretch-factor: 1.625;

// DEFAULT CLASS VARIABLE

$switch-class: "switch-toggle";

@mixin switch(
  $size: $switch-size,
  $spacing: $ball-spacing,
  $stretch: $stretch-factor,
  $color: $active-color,
  $class: $switch-class
) {
  // SELECTOR VARIABLES

  $self: "." + $class;
  $on: #{$self}--on;
  $off: #{$self}--off;

  // SWITCH VARIABLES

  $active-color: $color;
  $switch-size: $size;
  $ball-spacing: $spacing;
  $stretch-factor: $stretch;
  $ball-size: $switch-size + ($ball-spacing * 3);
  $ball-slide-size: ($switch-size * ($stretch-factor - 1) + $ball-spacing);

  // SWITCH STYLES

  height: $switch-size;
  width: $switch-size * $stretch-factor;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;

  &#{$on},
  &#{$off} {
    &::before,
    &::after {
      content: "";
      left: 0;
      position: absolute !important;
    }

    &::before {
      height: inherit;
      width: inherit;
      border-radius: $switch-size / 2;
      will-change: background;
      transition: background 0.4s 0.3s ease-out;
    }

    &::after {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

      border-style: solid;

      border-width: 0.5px;

      border-image-source: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.12),
        rgba(255, 255, 255, 0.06) 20%,
        rgba(255, 255, 255, 0)
      );

      border-image-slice: 1;

      top: -2px - $ball-spacing;
      height: $ball-size;
      width: $ball-size;
      border-radius: $ball-size / 2;
      will-change: transform;
      transition: transform 0.4s ease-out, background-color 0.5s ease-out;
    }
  }

  &#{$on} {
    &::before {
      background: $active-color !important;
    }
    &::after {
      background-color: $ball-active-color !important;
      transform: translateX($ball-slide-size - 8px);
    }
  }

  &#{$off} {
    &::before {
      background: $inactive-color !important;
    }
    &::after {
      background-color: $ball-inactive-color !important;
      transform: translateX(-$ball-spacing);
    }
  }
}

@function get-switch-class($selector) {
  // First parse the selector using `selector-parse`
  // Extract the first selector in the first list using `nth` twice
  // Extract the first simple selector using `simple-selectors` and `nth`
  // Extract the class name using `str-slice`

  @return str-slice(nth(simple-selectors(nth(nth(selector-parse($selector), 1), 1)), 1), 2);
}

.switch {
  $self: &;
  $toggle: #{$self}-toggle;
  $class: get-switch-class($toggle);

  // default theme
  &#{$self}--default > #{$toggle} {
    // Always pass the $class to the mixin
    @include switch($class: $class);
  }

  // locofast theme
  &#{$self}--locofast > #{$toggle} {
    // Always pass the $class to the mixin
    @include switch($class: $class, $size: 14px, $stretch: 2.5);
  }

  // graphite-small theme
  &#{$self}--graphite-small > #{$toggle} {
    // A smaller switch with a `gray` active color
    // Always pass the $class to the mixin
    @include switch($color: gray, $size: 20px, $class: $class);
  }
}
