.select-pane {
  top: 42px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 1, 38, 0.5);
  background-color: var(--fill-white);
  position: absolute;
  width: 100%;
  z-index: 10000;

  // for left aligned
  transform: translate(-12px, 1px);
}

.select-input-transparent {
  cursor: pointer;
  max-width: 52px;
}

.select-input-transparent:focus {
  outline: none;
  user-select: none !important;
  cursor: pointer !important;
}

.col-collab__dropdown__control {
  max-height: 0.2em;
  height: 0.2em;

  * {
    max-height: 100%;
  }
}

.collection-collab__label {
  justify-content: space-around;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.collection-collab__label_left_aigned {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}

.collab__label_left_aigned_with_no_paddding {
  padding-left: 1em;

  &:hover {
    background-color: var(--fill-grey-light5);
  }
}

.collection-collab__label_with_checkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.in-selection.collection-collab__label {
  cursor: pointer;
}

.in-selection.collection-collab__label:hover {
  background-color: var(--fill-grey-light5);
}

.collection-collab__img {
  background-color: var(--fill-grey-light2);
  //   flex-basis: 40%;

  border-radius: 60px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}

.collection-collab__text {
  flex-basis: 70%;

  &:first-child {
    line-height: 1.43;
    letter-spacing: 0.2px;
    // margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  :nth-child(2) {
    // color: var(--fill-grey-dark);
  }
}

.collection-collab__list {
  // TODO: Enable for scrollable
  // overflow: hidden;
}
