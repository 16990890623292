.file-with-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;


  .input-file__upload_image {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    font-size: 14px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23D4D4D4FF' stroke-width='2' stroke-dasharray='6%2c 5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 4px;

    svg path {
      fill: var(--fill-grey-medium);
    }
  }

  .input-file__meta-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 328px;
    padding: 10px 16px;
    background: var(--fill-grey-light5);
    border-radius: 4px;
    font-size: 14px;

    .clickable {
      height: 20px;
    }
  }
}
