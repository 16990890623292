@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.material-card {
  $material-card-width: 214px;

  $material-card-img-height: 250px;
  transition: 0.2s all;
  cursor: pointer;

  padding: 16px;
  border-radius: 8px;
  height: 358px;
  position: relative;

  & > * {
    width: $material-card-width;
    line-height: 1.43;
  }

  .caption {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .caption,
  .colors-list {
    display: none;
    font-size: 12px;
    font-weight: 600;
    color: var(--fill-grey-medium);
  }

  .caption, .material-name, .colors-list div:first-child{
    margin-bottom: 8px;
    line-height: 16px;
  }

  .content {
    padding-top: 12px;
  }

  .material-name {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 1, 38, 0.4);

    .content {
      transform: translateY(-38px);
      background: var(--fill-white);
      z-index: 99;
    }
    .caption {
      display: -webkit-box;
    }

    .colors-list {
      display: block;
    }
    .material-card__more_color {
      display: none;
    }
  }

  &__img {
    @include aspect-ratio($material-card-width, $material-card-img-height);

    max-width: $material-card-width;
    max-height: $material-card-img-height;

    min-height: $material-card-img-height;
  }

  &__more_color {
    color: var(--fill-grey-medium);
    font-weight: 600;
    font-size: 12px;
  }

  &__more-color-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 12px;
    display: inline-block;
  }

  &__caption {
    font-size: 12px;
    color: var(--fill-grey-medium);
    text-transform: capitalize;
  }

  &__stock_label {
    position: absolute;
    z-index: 9999;
    top: 125px;
    text-align: center;
    background: rgba(0, 1, 38, 0.6);
    left: 76px;
    width: auto;
    color: var(--fill-white);
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid var(--fill-white);
  }
}
