@import "../../assets/scss/_mixins";

.help-popup {
  &--hide {
    display: none;
  }

  &--show {
    display: block;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000126;
    opacity: 0.25;
    z-index: 100;
  }

  &__container {
    position: fixed;
    left: 88px;
    bottom: 16px;
    width: 270px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    z-index: 1000;
  }

  &__header {
    background-color: #b3d1f6;
    padding: 20px 22px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    align-items: center;
  }

  .user-avatar__img {
    position: absolute;
    left: -2.5%;
    top: -2.5%;
    width: 105%;
    height: 105%;
    object-position: center;
    object-fit: fill;
  }

  .help-avatar {
    > button.user-avatar__container {
      position: relative;
      @include fix-size(64px);
    }
  }

  .user-avatar__container {
    @include fix-size(48px);
    border-radius: 50%;
    overflow: hidden;
    background: white;
    border: 2px solid white;
  }

  .user-avatar {
    position: relative;

    &__container {
      position: relative;
    }

    &__notch {
      position: absolute;
      bottom: -20px;
      border: 12px solid transparent;
      border-bottom-color: white;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .user-info {
    &__name {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }
  }

  &__content {
    padding: 20px;

    .greeting {
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
    }

    .para-line {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-top: 8px;

      a {
        color: #0067e2;
      }
    }

    .loco-icon {
      vertical-align: -4px;
    }

    .icon-text {
      margin-left: 8px;
      vertical-align: 0px;
    }
  }
}
