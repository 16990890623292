.tna-graph {
  color: var(--fill-locofast-black);
  border-bottom: 1px solid var(--fill-light-gray);
  background: var(--fill-white);

  &__heading {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    padding: 20px 0;
    margin: 0 24px;
    border-top: 1px solid var(--fill-grey-light3);
  }
}
