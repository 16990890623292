.review-quote-modal {
  background: white;
  max-width: 700px;
  min-width: 700px;
  max-height: 700px;
  min-height: 500px;

  .design-container {
    display: grid;
    grid-template-columns: 96px 1fr 96px;
    padding: 16px 24px;

    .cover-pic img {
      min-height: 96px;
      min-width: 96px;
      max-height: 96px;
      max-width: 96px;
      object-fit: cover;
      border-radius: 4px;
    }

    .design-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .design-info {
      display: flex;
    }

    .design-details {
      margin: 0 16px 0 20px;
    }
  }

  .cta-view-design {
    font-size: 12px;
    line-height: 20px;
    padding: 5px 12px;
  }

  .quotes-container {
    padding: 0 24px 20px;
  }

  .status-quotes {
    margin-top: 20px;
    &__header {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .factory-quote-row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 0.7fr) 0.3fr 80px 110px;
    column-gap: 8px;
    white-space: nowrap;
    margin-top: 8px;
    padding: 16px 20px;
    border: 1px solid var(--fill-grey-light2);
    border-radius: 4px;
    align-items: center;

    .quote-status {
      &__info {
        display: flex;
        align-items: center;
      }

      &__popover {
        overflow: hidden;
        width: 0;
        height: 20px;
        margin-left: 4px;
        transition: all 60ms;
      }
    }

    &--accepted {
      border: 1px solid var(--fill-green-dark);
      background-color: #e8fff5;
      border-radius: 4px;

      .quote-status__info {
        color: var(--fill-green-dark);
      }
    }

    &--rejected {
      .quote-status__info {
        color: var(--fill-orangered);
      }
    }

    &--review-pending {
      .quote-status__info {
        color: goldenrod;
      }
    }

    &--awaited,
    &--requests-withdrawn {
      .quote-status__info {
        color: var(--fill-grey-medium);
      }
    }

    &__last-col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
    }

    .quote-cta-list {
      .btn {
        border-radius: 50%;
        height: 36px;
        width: 36px;
        padding: 0;
        border: none;

        .loco-icon {
          height: 20px;
          width: 20px;
          vertical-align: -4px;
        }

        & + .btn {
          margin-left: 8px;
        }

        &:hover {
          background: #f2f2f2;
        }
      }
    }

    &:hover,
    &--popover {
      .quote-status {
        &__popover {
          width: 20px;
        }
      }
    }
  }

  .quote-price {
    text-align: right;

    .icon-text + .loco-icon {
      vertical-align: -2px;
    }

    &--more {
      > .quote-price__info {
        color: var(--fill-orangered);
      }
    }
    &--less {
      > .quote-price__info {
        color: var(--fill-success);
      }
    }
    &--matched {
      > .quote-price__info {
        color: var(--fill-orange);
      }
    }
  }
}
