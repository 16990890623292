.quote-dropdowns {
  background-color: white;

  & > .card {
    background-color: var(--fill-white);
  }

  .dropdown {
    &--accepted {
      border-top: 1px solid var(--fill-green-dark);
    }

    &--resubmit &--rejected {
      border-top: 1px solid #ffa552;

      .quote-status__info {
        color: var(--fill-orangered);
      }
    }

    &__header {
      align-items: center;
      justify-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: auto minmax(0, 1fr) 0.8fr minmax(100px, 0.8fr) 0.5fr 1.2fr;
      justify-items: left;
      grid-column-gap: 16px;
      transition: all 50ms;

      &--accepted {
        border: 1px solid var(--fill-green-dark);
        background-color: #e8fff5;
        border-radius: 4px;

        &.expanded {
          border-radius: 4px 4px 0 0;
        }

        .quote-status__info {
          color: var(--fill-green-dark);
        }
      }

      &--rejected {
        .quote-status__info {
          color: var(--fill-orangered);
        }
      }

      &--awaited, &--requests-withdrawn {
        .quote-status__info {
          color: var(--fill-grey-medium);
        }
      }

      &__last-col {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      > div {
        width: 100%;
        white-space: nowrap;
      }

      &.expandable {
        cursor: pointer;
      }

      &.can-hover:hover {
        background: var(--fill-grey-light5);
        .quote-cta-list {
          opacity: 1;
        }
      }
    }
  }

  .quote {
    &__type-name {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      text-transform: capitalize;
    }

    &__subtitle {
      margin-top: 0;
    }
  }

  img.arrow {
    transition: all 100ms;
  }

  &__quotes-header {
    margin: 20px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .quote-cta-list {
    display: inline-block;
    opacity: 0;

    .cta-save-quote.btn--disabled {
      color: var(--fill-grey-medium);
      background-color: white;

      svg * {
        stroke: var(--fill-grey-medium);
      }
    }

    > .btn {
      vertical-align: middle;
      max-height: 28px;
      min-height: 28px;
      padding: 3px 8px;

      &:hover {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      }

      & + .btn {
        margin-left: 8px;
      }

      .loco-icon {
        vertical-align: -3px;
        margin-right: 4px;
      }

      .icon-text {
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        min-height: 20px;
      }
    }
  }

  .quote-price {
    &--more {
      > .quote-price__info {
        color: var(--fill-orangered);
      }
    }
    &--less {
      > .quote-price__info {
        color: var(--fill-success);
      }
    }
    &--matched {
      > .quote-price__info {
        color: var(--fill-orange);
      }
    }
  }

  .quote-status {
    display: inline-block;

    &__info {
      display: flex;
      align-items: center;
      position: relative;
    }

    &__popover {
      position: absolute;
      display: none;
      align-items: center;
      right: -32px;
      cursor: pointer;
    }
  }

  &--factory {
    .quote-cta-list {
      opacity: 1;

      > .btn {
        max-height: 32px;
        min-height: 32px;
        padding: 6px 12px;
        font-size: 12px;

        &:hover {
          box-shadow: none;
        }

        & + .btn {
          margin-left: 8px;
        }

        .loco-icon {
          vertical-align: -1px;
          margin-right: 4px;
        }
      }
      .quote-status {
        font-weight: bold;
      }
    }

    .submitted-quote {
      font-weight: 600;
      font-size: 16px;
    }

    .quote-dropdowns__quote-item.dropdown.card {
      border: none;
      background: var(--fill-blue-light2);

      .card__section {
        border: none;
        margin: 20px 24px;
        background: white;
        border-radius: 8px;
      }
    }

    .dropdown__header {
      grid-template-columns: auto 1fr auto;
      border: none;
      border-radius: 0;
      background: white;

      .quote-status {
        text-transform: uppercase;
      }

      &--rejected {
        background: var(--fill-red-light1);
        .quote-status {
          color: var(--fill-orangered);
        }
      }

      &--accepted {
        background-color: #e8fff5;
        .quote-status {
          color: var(--fill-green-1);
        }
      }
    }
  }
}
