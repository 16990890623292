.tna-submit-modal {
  max-width: 340px;

  .alert-icon {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .content {
    margin: 0px auto;
    padding: 0px 20px 20px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      margin: 0;
      padding-bottom: 8px;
      padding-top: 12px;
    }

    p {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--fill-grey-medium);
    }
  }

  .submit-tna-footer {
    border-top: 0;
    padding: 20px;
    display: flex;
    justify-content: flex-end;

    footer {
      button:first-child {
        margin-right: 16px;
      }
    }
  }
}
