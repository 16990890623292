.files-container {
  position: relative;
  padding: 20px 24px;

  .file__icon {
    transform-origin: center;
    transform: rotate(-45deg);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      display: inline-block;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      min-height: 24px;

      & + img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        vertical-align: -3px;
      }
    }

    img {
      width: 28px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;

    .file {
      margin-left: 12px;
      margin-bottom: 12px;
    }
  }

  &__no-files {
    width: 100%;
    text-align: center;
    border: 1px dashed var(--fill-grey-light3);
    padding: 36px;
    border-radius: 4px;
    margin-left: 12px;

    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
  }

  .file {
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 4px rgba(0, 1, 38, 0.25);
    }

    &.is-cover {
      .file__image {
        background-position: top center;
        background-size: cover;
        cursor: pointer;
      }
    }
  }
}
