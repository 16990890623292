.swatch-library-container {
  flex: 1;
  background-color: var(--fill-white);
  height: 100%;

  > .header {
    min-height: 48px;
    max-height: 48px;
    line-height: 32px;
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light2);
    background-color: var(--fill-white);
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    line-height: 1.33;
  }

  > .content {
    height: 100%;
    position: relative;

    > div {
      flex: 1;
    }

    > div:first-child {
      flex: 1 1 20%;
      max-height: 100%;
      max-width: 268px;
    }

    > div:nth-child(2) {
      flex: 2 2 80%;
    }

    .filters {
      padding-left: 5%;
      height: 100%;
      max-height: 100%;
      box-shadow: inset -1px 0 0 0 var(--fill-grey-light3);

      // input:placeholder-shown {
      //   background: var(--fill-grey-light5);
      // }

      input:not([type="number"]) {
        box-shadow: none;
        outline: none;
      }

      input:focus {
        outline: 1px solid var(--fill-grey-light2);
      }
    }

    .flex-wrapper {
      padding: 24px;
      overflow-y: auto;
    }

    .main-container {
      display: flex;
      flex-direction: column;

      > .filters-row {
        padding: 20px 24px;
      }

      > .swatches-grid-container {
        overflow-y: auto;
        > .scrollbar-container {
          padding: 24px;
        }
      }
    }
  }
}

.swatch-pagination {
  border: 0px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  color: var(--fill-primary-medium);
  background-color: var(--fill-white);

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: var(--fill-primary-dark);
  }

  &-container {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
  }

  &--disabled {
    cursor: not-allowed;
    color: var(--fill-grey-light2);

    i {
      cursor: not-allowed;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--fill-grey-light2);
    }
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;

    p {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: var(--fill-locofast-black);
    }
  }

  &-left {
    justify-content: flex-start;
  }

  &-right {
    justify-content: flex-end;

    .swatch-pagination-options {
      padding-left: 20px;
    }
  }
}
