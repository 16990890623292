@import "../../../assets/scss/mixins";

.message-container {
  margin-bottom: 16px;
  display: flex;

  &.right {
    flex-direction: row-reverse;
    .message-content {
      display: inline-block;
      background-color: var(--fill-blue-light);
      border-top-right-radius: 0;
      border: solid 1px rgba(0, 103, 226, 0.3);
    }
    .message-sender {
      margin-left: 12px;
    }
  }
  &.left {
    .message-content {
      background-color: white;
      border-top-left-radius: 0;
    }
    .message-sender {
      margin-right: 12px;
    }
  }

  .message-content {
    padding: 12px 16px;
    border-radius: 8px;
    line-height: 20px;
    border: 1px solid #e5e5e5;
    max-width: 40%;
    width: fit-content;
    flex: 1;
  }
  .created-by {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .message {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--fill-locofast-black);
    margin: 4px 0px 16px;
    font-family: "Nunito Sans", sans-serif;
  }
  .timestamp {
    color: var(--fill-grey-medium);
    font-size: 12px;
    line-height: 16px;
    text-align: right;
  }
  .lf-ref-indicator {
    display: inline-block;
    font-size: 12px;
    background: #8a888391;
    color: white;
    padding: 5px 10px;
    border-radius: 50px;
    margin-top: 0;
  }

  .message-image-list-container {
    width: 100%;
    margin-top: 8px;
  }
  .message-image-container {
    padding: 6px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .message-image {
    display: inline-block;
    background-color: white;
    padding: 10px;
    max-width: 350px;
    max-height: 350px;
    overflow: hidden;
    border-radius: 4px;
  }

  .message-image-thumbnail {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .profile-avatar {
    @include fix-size(32px);

    > .initials {
      line-height: 32px;
      font-size: 16px;
    }
  }
}
