@import "../style.scss";

.sample-version-history {
  min-width: 700px;
  max-width: 700px;

  .sample-version-history__table {
    margin:  0 0 1.5em;
  }
}
