.order-summary {
  .brand-summary {
    &__empty {
      min-width: 350px;
      position: relative;
      flex: 1;
    }

    &__cta-container {
      padding: 12px 24px;

      .btn.cta-continue {
        width: 100%;
      }
    }
  }
}
