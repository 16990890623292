.input-file-vertical {
  border-radius: 2px;
  border: 1px dashed #bfbfbf;
  background-color: var(--fill-white);
  margin-bottom: 1em;
  max-width: 164px;
  min-width: 164px;
  height: 192px;
  display: flex;
  align-items: flex-end;
  background-size: 100% 100%;
  overflow: hidden;
  margin: 0 12px;


  .input-file__file-thumb {
    border-radius: 4px;
    overflow: hidden;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 4px;
    background-color: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
    margin: 12px;

    img {
      margin-left: 10px ;
    }
  }
  
  .input-file__wrapper {
    align-items: flex-start;
    flex-direction: column;
  }
  
  .input-file__wrapper.selected {
    width: 100%;

    .flex-col {
      width: 100%;
    }
  }

  .input-file__progress {
    flex-grow: 1;
    padding: 0px 16px;
    align-self: baseline;
    padding-bottom: 10px;
  }
  
  .input-file__delete {
    margin-left: auto;
    padding-right: 6px;
    cursor: pointer;
    user-select: none;
  }
  
  .input-file__percent {
    // color: var(--fill-green-dark);
    // margin-bottom: 0.7em;
    font-size: 12px;
    color: var(--fill-grey-medium);
  }
  
  .input-file__progress-bar {
  }
  
  .input-file__meta {
    color: var(--fill-grey-medium);
    line-height: 1.43;
    letter-spacing: 0.2px;
    // margin-top: 0.5em;
    user-select: none;
    align-self: flex-start;
  }

  .input-header__text {
    color: var(--fill-grey-dark)
  }
  

  .input-file__meta-selected {
    background-color: rgba($color: #000000, $alpha: 0.7);
    width:100%;
    display: flex;
    color: var(--fill-white);
    padding: 6px;
    align-items: center;
    font-size: 12px;
  }

  .input-file__default-thumb {
    // background-color: #e53935;
    width: 36px;
    height: 44px;
    border-radius: 4px;
    margin: auto;
  }
  
  .input-file__cover-img {
    margin: 1em -8px;
    margin-bottom: -0.5em;
    padding: 1em;
    align-items: center;
    // border-top: 1px solid #bfbfbf;
    color: var(--fill-grey-dark);
    font-size: 11px;
    font-weight: 600;
  }
  

}
