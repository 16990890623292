$col-card-border-color: var(--fill-grey-light3);

.col-card {
  flex-direction: column;
  max-width: 429px;
  min-width: 350px;
  height: 400px;
  background-color: var(--fill-white);
  flex-basis: calc(33.3% - 20px);
  margin-bottom: 20px;

  border: 1px solid $col-card-border-color;
  border-radius: 8px;
  margin-right: 20px;
  overflow: hidden;
}

.col-card__header {
  overflow: hidden;

  line-height: 1.25;
  min-height: 70px;
  background-color: #ffffff;
  padding: 16px;
  border-bottom: 1px solid $col-card-border-color;

  align-items: center;
  // z-index: 10;
  align-items: flex-start;
}

.col-card__header--empty {
  box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
}

.col-card__title {
  font-weight: 600;
}

.col-card__metadata {
}

.col-card__options {
  margin-left: auto;
  font-size: 24px;
  cursor: pointer;
  margin-right: -11px;
}

.col-card__designs {
  flex-grow: 1;
  flex-direction: column;

  .inactive {
    filter: grayscale(1);
  }
}

.col-card__new-design {
  margin: auto;
  text-align: center;

  span {
    line-height: 1.5;
  }

  .btn {
    cursor: pointer;
    background-color: var(--fill-primary-lighter);
    border: 1px var(--fill-primary-medium) dashed;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin: auto;
    margin-bottom: 10px;

    span {
      margin: auto;
      color: var(--fill-primary-medium);
    }
  }
}

.col-card__footer {
  overflow: hidden;

  min-height: 36px;
  padding: 6px 12px;
  background-color: #ffffff;
  align-items: center;
  border-top: 1px solid $col-card-border-color;
}

.col-card__add-design {
  margin-left: auto;
}

.product {
  padding: 16px;
  background-color: #ffffff;
  align-items: center;
  cursor: pointer;
}

.product:hover {
  background-color: var(--fill-grey-light5);
}

.product__status {
  width: 40px;
  margin-right: 12px;

  > img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 20px;
  }

  div {
    content: " ";
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: var(--fill-grey-light3);
  }
}

.product__metadata {
  margin-top: 0.7em;
  text-transform: capitalize;
  color: var(--fill-grey-medium);
}

.product__name {
  line-height: 1.5;
  font-weight: 600;
}

.product__details {
  flex-grow: 1;
  flex-direction: column;
}

.custom-center {
  margin-top: 5px;
}
