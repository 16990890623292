.order-summary {
  .factory-summary {
    &__empty {
      display: flex;
      min-width: 350px;
      position: relative;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    &__cta-container {
      padding: 12px 24px;

      .btn.cta-continue {
        width: 100%;
      }
    }

    &__designs {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-list {
        display: flex;
      }

      &-item {
        min-height: 48px;
        min-width: 48px;
        max-height: 48px;
        max-width: 48px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid white;

        &:not(:first-child) {
          margin-left: -12px;
        }

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &-info {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
      }
    }
  }
}
