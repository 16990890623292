.material-library-container {
  flex: 1;
  background-color: var(--fill-white);
  height: 100%;

  > .header {
    height: 56px;
    line-height: 32px;
    border-bottom: 1px solid var(--fill-grey-light3);
    background-color: var(--fill-white);
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;

    .tabs {
      width: auto;
    }

    .tabs__navbar {
      border-bottom: 0;
      padding: 0;
    }

    .white-arrow {
      path {
        stroke: var(--fill-white);
      }
    }
  }

  > .content {
    height: 100%;
    position: relative;

    > div {
      flex: 1;
    }

    > div:first-child {
      flex: 1 1 20%;
      max-height: 100%;
      max-width: 268px;
    }

    > div:nth-child(2) {
      flex: 2 2 80%;
    }

    .filters {
      padding-left: 5%;
      height: 100%;
      max-height: 100%;
      box-shadow: inset -1px 0 0 0 var(--fill-grey-light3);

      input:not([type="number"]) {
        box-shadow: none;
        outline: none;
      }

      input:focus {
        outline: 1px solid var(--fill-grey-light2);
      }

      .control {
        padding-top: 0;
      }
    }

    .flex-wrapper {
      padding: 24px;
      overflow-y: auto;
    }

    .main-container {
      display: flex;
      flex-direction: column;

      > .filters-row {
        padding: 20px 24px;
      }

      > .library-grid-container {
        overflow-y: auto;
        padding-top: 20px;

        > .scrollbar-container {
          padding: 24px;
        }
      }
    }
  }

  .layout-grid > div {
    margin-bottom: 20px;
  }

  .empty-list-container {
    transform: translate(-40%, -50%);

    .info-btn__details {
      justify-content: center;

      div.flex {
        justify-content: center;
      }

      img {
        width: 96px;
        height: 96px;
      }
    }

    .info-btn__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }

    .info-btn__subtitle {
      font-size: 14px;
      color: var(--fill-locofast-black);
      line-height: 20px;
      max-width: 250px;
    }

    .cta {
      button {
        border: 1px solid var(--fill-grey-light2);
        padding: 8px 16px;
        margin-top: 20px;
      }
    }

    &.no-filters {
      transform: translate(-50%, -50%);
    }
  }
}

.library-pagination {
  border: 0px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  color: var(--fill-primary-medium);
  background-color: var(--fill-white);

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: var(--fill-primary-dark);
  }

  &-container {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
  }

  &--disabled {
    cursor: not-allowed;
    color: var(--fill-grey-light2);

    i {
      cursor: not-allowed;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--fill-grey-light2);
    }
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;

    p {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: var(--fill-locofast-black);
    }
  }

  &-left {
    justify-content: flex-start;
  }

  &-right {
    justify-content: flex-end;

    .library-pagination-options {
      padding-left: 20px;
    }
  }
}
