.sample-files {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-item {
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--fill-white);
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    // &:not(:first-child) {
    //   margin-left: -16px;
    // }

    > img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  .doc-file {
    padding: 10px 11px;
    border: 1px solid var(--fill-grey-light3);
    img {
      object-fit: fill;
      height: 20px;
      border-radius: 0;
    }
  }
}
