.factory-header {
  border-bottom: 1px solid var(--fill-grey-light3);
  padding: 16px 24px;
  background-color: white;

  > .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & + .header-row {
      margin-top: 32px;
    }
  }

  .actions {
    > .action {
      margin-left: 16px;
    }
  }

  .table-headers {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    width: 100%;
    padding: 0 32px 0 20px;

    &.grid-6 {
      grid-template-columns: 300px repeat(2,1fr) 150px 200px;
    }
    > div {
      flex: 1;
      color: var(--fill-grey-medium);
      text-transform: uppercase;
      font-size: 12px;
    }

    & > div:first-child {
      margin-left: -16px;
      margin-right: 16px;
    }

    & > div:last-child {
      flex: 0;
      width: 200px;
      min-width: 200px;
    }
  }

  .select-pane {
    top: 10px;
  }

  .filter-label .dropdown {
    margin-top: 17px;
  }

  .img-arrow-drop-up {
    margin-top: -2px;
  }

}
