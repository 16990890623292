.messaging-container {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;

  &__sidebar {
    width: 25%;
    flex-basis: 25%;
    max-width: 450px;
    min-width: 350px;
    display: flex;
    flex-direction: column;

    .messaging-design-filters {
      border-bottom: 1px solid var(--fill-grey-light3);
      border-right: 1px solid var(--fill-grey-light3);
    }
  }

  &__body {
    flex: 1;
  }

  .messaging-design-container{
    max-height: 100%;
    height: 100%;
  }
}
