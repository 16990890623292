.new-col {
  flex-direction: column;
  width: 330px;
  margin: auto;

  * {
    margin-bottom: 6px;
  }
}

.new-col__label {
  padding: 0 12px;
  color: #333340;
}
