.col-details {
  height: 100%;
  max-height: 100%;

  & > * {
    max-height: 100%;
  }

  // flex-grow: 1;
  width: 100%;
  position: relative;

  .tabs__navbar {
    padding: 8px 24px 8px;
  }
  .tabs__tab {
    padding: 6px 12px;
  }
}

.design,
.col {
  &-details__header {
    padding: 16px 24px;
  }

  &-details__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }
}

.col-details__time {
  padding-left: 12px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 2px;
    background: var(--fill-grey-dark);
    left: 5px;
    top: 8px;
    border-radius: 50%;
  }
}

.col-details__metadata {
  color: var(--fill-grey-dark);
  font-size: 14px;
  line-height: 20px;
}

.col-details__content {
  height: 100%;
}

.col-details__no-designs {
  position: relative;
  height: 100%;
  & > div {
    margin: auto;
  }
}

.col-details__collaborators {
  margin-left: auto;
}
