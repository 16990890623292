.side-drawer-filters {
  font-size: 14px;

  .filter-header {
    padding: 6px 0;
    line-height: 36px;
    font-weight: bold;
    display: flex;

    .btn {
      font-weight: bold;
      margin-left: auto;
      padding: 0;
    }

    .title {
      text-transform: uppercase;
    }
  }

  .filter-item {
    line-height: 16px;
    padding: 6px 0;

    & + .filter-item {
      // margin-top: 12px;
    }
  }

  .filter-items-available {
    color: var(--fill-primary-blue);
    cursor: pointer;

    .img-add {
      height: 20px;
      width: 20px;
      * {
        fill: var(--fill-primary-blue);
      }
    }
    .label {
      vertical-align: 5px;
      margin-left: 10px;
    }
  }

  .filter {
    & + .filter {
      border-top: 1px solid var(--fill-grey-light3);
      margin-top: 8px;
    }
  }

  .side-drawer-filters-footer {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .btn {
      min-height: 40px;
      max-height: 40px;
    }
  }

  .action-apply{
    margin-left: auto;
  }

  .header .close {
    cursor: pointer;
  }
}
