.modal__wrapper--delete-material {
  .modal__header {
    height: 0;
  }
}
.delete-material-modal {
  max-width: 350px;

  .alert-icon {
    padding: 0 20px;
  }

  .content {
    margin: 0px auto;
    padding: 0px 20px 20px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      margin: 0;
      padding-bottom: 8px;
      padding-top: 12px;
    }

    p {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }


  footer {
    border-top: 0;
    padding: 16px;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 16px;
    }

    button {
      font-weight: bold;
    }
  }
}
