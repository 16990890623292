.input-file {
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #bfbfbf;
  background-color: var(--fill-white);
  margin-bottom: 1em;
  max-width: 380px;
  width: 380px;
}

.input-file__file-thumb {
  border-radius: 4px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 4px;
  background-color: var(--fill-grey-light5);
}

.input-file__wrapper {
  align-items: center;
}

.input-file__progress {
  flex-grow: 1;
  padding: 0 16px;
  align-self: baseline;
}

.input-file__delete {
  margin-left: auto;
  padding-right: 6px;
  cursor: pointer;
  user-select: none;
}

.input-file__percent {
  // color: var(--fill-green-dark);
  // margin-bottom: 0.7em;
  font-size: 12px;
  color: var(--fill-grey-medium);
}

.input-file__progress-bar {
}

.input-file__meta {
  color: var(--fill-grey-medium);
  line-height: 1.43;
  letter-spacing: 0.2px;
  // margin-top: 0.5em;
  user-select: none;
  align-self: flex-start;
}

.input-file__default-thumb {
  // background-color: #e53935;
  width: 36px;
  height: 44px;
  border-radius: 4px;
  margin: auto;
}

.input-file__cover-img {
  margin: 1em -8px;
  margin-bottom: -0.5em;
  padding: 1em;
  align-items: center;
  // border-top: 1px solid #bfbfbf;
  color: var(--fill-grey-dark);
  font-size: 11px;
  font-weight: 600;
}

.full_span {
  width: 100%;
  max-width: 100%;
  margin: 0 !important;
}