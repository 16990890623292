.design-summary-details .summary-container {
  margin-right: 16px;

  .tabs-active-content {
    margin-top: 32px;
  }

  .tabs-nav {
    border-bottom: none;
  }

  &__title {
    margin-top: 36px;
    margin-bottom: 32px;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 14px;
    grid-row-gap: 20px;

    &--col-2 {
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }

    &.row-first {
      grid-template-rows: repeat(3, 1fr);
      grid-auto-flow: column;
    }

    .label {
      color: var(--fill-grey-medium);
      padding: 12px 0;
    }

    .value {
      display: flex;
      flex-direction: column;
      padding: 12px 16px;

      &--lines {
        padding: 0 16px;
      }

      &__line-1 {
        padding: 12px 0 4px;
      }

      &__line-2 {
        font-size: 12px;
        line-height: 20px;
        color: var(--fill-grey-medium);
        padding-bottom: 8px;
      }
    }

    .category {
      display: flex;

      p:nth-child(1) {
        flex-grow: 3;
      }

      p:nth-child(2) {
        flex-grow: 2;
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 100px 1fr;
  }

  .input-grp {
    margin-bottom: 0 !important;

    label {
      margin: 0;
    }

    input[type="text"] {
      padding: 11px 16px;
      line-height: 20px;
    }

    &-radio {
      padding: 10px 0;
      > .flex > label {
        flex: 0;
      }
    }

    &__lower:empty {
      display: none;
    }
  }

  &.summary-form {
    .summary-container {
      &__row {
        column-gap: 16px;
      }

    }
  }

  .field-input__annotation {
    font-size: 12px;
    line-height: 20px;
    color: var(--fill-grey-medium);
    margin-left: 12px;
  }

  .with-bottom-margin {
    margin-bottom: 8px;
  }
}
