.prod-container {
  min-width: 500px;

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tr td, th {
      padding: 20px;
    }

    
    tr:first-of-type{
      border-bottom: 1px solid var(--fill-grey-light3);
    }
  }

  .truncate {
    width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}