.hiddenCheckbox {
  > div {
    visibility: hidden;
  }

  > label {
    color: var(--fill-primary-blue);
    letter-spacing: 0.2px;
    line-height: 1.43;
    font-size: 14px;
  }
}

.priceInput {
  width: 40%;
  border-radius: 2px;
  border: 1px solid #bfbfbf !important;
  padding: 0 10px;
}

.flexColumn {
  justify-content: space-between;
  padding-bottom: 12px;
  align-items: center;
}

.filterButtons {
  justify-content: space-between;
  padding-bottom: 8px;

  button {
    text-transform: capitalize;
    font-size: 12px;
    padding: 4px 8px;
    &:disabled{
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.closed {
  display: none;
}
