.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: var(--fill-blue-light2);
  margin: 0 20px 24px;
  padding: 0 16px 20px;
  border-radius: 8px;

  .filters > div {
    margin-right: 16px;
    padding-top: 16px;

    label {
      max-width: 206px;
    }
  }

  &.wrapped {
    align-items: center;

    .filters {
      flex-wrap: wrap;
    }
  }

}

.reports-dropdown {
  &__label {
    color: var(--fill-grey-dark);
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 6px;
    line-height: 16px;
  }

  &.single {
    .filter-scroll {
      padding-top: 10px;
    }
  }

  // Overrided the existing styles
  .dropdown-filter {
    width: 150px;
    padding: 10px 16px;
    background: var(--fill-white);
    border-color: var(--fill-grey-light2);
  }

  .select-input-transparent {
    border: none;
    background-color: transparent;
    font-weight: 600;
    color: var(--fill-locofast-black);
  }

  .select-pane {
    width: 250px;
    height: 300px;
    bottom: 0;
    left: 12px;
    align-items: center;
    position: absolute;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .filter-scroll {
    padding: 18px 8px 0 18px;
    height: 252px;
    overflow: auto;

    &.full-height {
      height: 300px;
    }

    .in-selection {
      padding: 10px 0;
    }
  }

  .input-box {
    justify-content: space-around;

    input,
    div {
      width: 100%;
      max-width: 100%;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .control {
    padding-top: 0;

    label {
      padding-left: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    color: var(--fill-primary-blue);
    padding: 14px 18px;
    font-size: 14px;
    font-weight: normal;
    border-top: 1px solid var(--fill-grey-light3);
    display: grid;
    grid-template-columns: 12px 1fr;
    grid-gap: 14px;
    align-items: center;

    svg path {
      stroke: var(--fill-primary-blue);
    }
  }

  .loco__radio {
    padding: 0;

    & > div {
      height: 20px;
    }
  }
}

.reports-datepicker {
  .DateRangePickerInput {
    padding: 10px 12px 10px 16px;
  }

  .DateInput {
    width: auto;
  }

  .DateInput_input {
    line-height: 20px;
    font-size: 14px;
    color: var(--fill-locofast-black);
    font-weight: normal;
    padding: 0;
    border: none;
    text-align-last: center;
  }

  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 84px 20px 84px 36px;
    justify-content: center;
    place-items: center;
    max-width: 250px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: var(--fill-primary-blue);
    border-color: var(--fill-primary-blue);
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background: var(--fill-primary-lighter);
    border: 1px double var(--fill-grey-light3);
    color: var(--fill-locofast-black);
  }

  .DayPickerKeyboardShortcuts_show::before {
    display: none;
  }

  // .DateRangePickerInput_arrow {
  //   padding-right: 6px;
  // }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: var(--fill-selection-light2);
    border: 1px double var(--fill-selection-light2);
    color: var(--fill-locofast-black);
  }

  .DateRangePickerInput_clearDates {
    padding-right: 0;
    padding-top: 14px;

    &:hover {
      background: none;
    }
  }

  .DateRangePickerInput_calendarIcon {
    height: 20px;
    padding: 0;

    &:focus {
      outline: none;
    }
    svg {
      height: 20px;
    }
  }
}
