.tna-vertical-timeline,
.tna__graph-progress {
  .status-grid {
    font-size: 12px;
    color: var(--fill-locofast-black);
    position: relative;
    margin: 0;

    &::before {
      display: none;
    }

    > .actual-date {
      text-transform: uppercase;
    }

    > .plan-date {
      text-transform: uppercase;
    }

    > .indicator {
      display: inline-block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin-right: 5px;
    }

    &.green {
      > .indicator {
        background-color: var(--fill-success);
      }
      .tna-indicator-tooltip > .title {
        color: var(--fill-success);
      }
    }

    &.red {
      > .indicator {
        background-color: var(--fill-error);
      }
      .tna-indicator-tooltip > .title {
        color: var(--fill-orangered);
      }
    }

    .tna-indicator-tooltip {
      display: none;
      position: absolute;
      background: white;
      border: 1px solid var(--fill-grey-light3);
      box-shadow: 0px 2px 8px rgba(0, 1, 38, 0.1);
      border-radius: 4px;
      padding: 12px;
      z-index: 10;
      min-width: 200px;
      margin-top: 4px;

      > .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      > .due-info {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &:hover {
      .tna-indicator-tooltip {
        display: block;
      }
    }
  }

  &.with_image {
    margin-top: 36px;
    margin-bottom: -20px;
  }
}

.tna-vertical-timeline {
  .status-grid {
    > .plan-date {
      color: var(--fill-grey-medium);
    }
  }
}

.blue-on-hover:hover {
  color: var(--fill-primary-blue);
}

.brand-tna {
  display: flex;
  flex-direction: column;

  .tna-awaited-container {
    position: relative;
    flex: 1;
  }
}
