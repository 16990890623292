.production-table {
  padding: 6px 64px;
  display: grid;
  font-size: 12px;
  color: var(--fill-grey-dark);
  border-bottom: 1px solid var(--fill-grey-light5);
  text-transform: uppercase;
  font-weight: 600;
  background-color: white;
  .table__header_item {
    padding: 12px 0;
    line-height: 16px;
  }
}

.card-custom-product {
  display: grid;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid var(--fill-grey-light5);
  line-height: 20px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0 44px;
  padding: 16px 20px;

  &:hover {
    background-color: var(--fill-grey-light5);
    border-radius: 8px;

    .link-hover {
      color: var(--fill-primary-blue);
    }

    .next-stage {
      button {
        opacity: 1;
        height: 20px;
        white-space: nowrap;
        top: -2px;
      }
    }

    .fill-on-hover {
      background: var(--fill-primary-blue);
      color: var(--fill-white);
      transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 300ms;
    }
  }

  .m-w-200 {
    max-width: 200px;
  }

  .status-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      height: 20px;
      padding: 0 6px;
      font-size: 12px;
      white-space: nowrap;
    }

    .tna-actions button {
      font-weight: bold;
      padding: 6px 12px;
      margin-left: -12px;
      height: 32px;
      text-transform: capitalize;
    }

    .header-tabs__tab-image {
      display: flex;
      align-items: center;
      margin-left: -16px;
      color: var(--fill-locofast-black);
      font-size: 14px;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
    }
  }
}

.card-footer-graph {
  position: relative;
  background: var(--fill-white);
  border-top: none;
  grid-column: 1/7;
}

.status-grid {
  font-size: 14px;
  color: var(--fill-locofast-black);
  position: relative;
  &::before {
    width: 9px;
    height: 9px;
    content: "";
    position: absolute;
    left: -15px;
    top: 5px;
    border-radius: 50%;
  }
}

p.status-grid {
  margin: 0;
}

.status-grid.green {
  &::before {
    background-color: var(--fill-success);
  }
}

.status-grid.red {
  &::before {
    background-color: var(--fill-orangered);
  }
}

.status-grid.grey {
  &::before {
    background-color: var(--fill-grey-medium);
  }
}

.status-grid.orange {
  &::before {
    background-color: var(--fill-orange);
  }
}

.track-btn {
  margin-right: 10px;
}

.link-custom-update {
  &::before {
    content: "\00B7";
    margin: 0 5px;
  }
}

.product-grid-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &.dummy {
    border-radius: 0;
  }
}
.hide_btn {
  display: none;
}

.show_btn {
  display: block;
}

.sample-table {
  grid-template-columns: 1fr 1fr 200px 1fr 200px;
  &.is-brand {
    grid-template-columns: 1.2fr 1fr 1fr 0.8fr;
  }
}

.production__tab_headers {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 0px;
  background-color: white;
}

.tna__graph {
  min-height: 200px;
  background-color: #fff;
  margin-bottom: 36px;
  border-bottom-left-radius: 6px;
  padding-bottom: 64px;
  border-bottom-right-radius: 6px;
  max-width: 1080px;

  &--col-4 {
    padding-left: 192px;

    .tna__graph {
      &-headers {
        :nth-child(4) {
          .tna__graph-heading {
            transform: translate(20px, 0);
          }
        }
      }
    }
  }

  .label__class {
    line-height: 18px;
    margin-top: 4px;
  }
  &-progress {
    align-self: flex-end;
    margin-top: 24px;
    margin-bottom: -12px;
    margin-left: 20px;
  }
  .tna-date {
    font-size: 12px;
    text-transform: uppercase;
  }
  &-heading {
    font-weight: 600;
    height: 40px;
    width: 190px;
    transform: translate(50px, 0);
    text-align: right;
    &--highlight {
      & + div .tna__graph-circle {
        box-shadow: 0 0 0 6px rgba(2, 189, 112, 0.2);
      }
    }
  }

  &-headers {
    display: flex;
    align-items: center;
    padding: 16px 100px 0px 100px;
    width: 1080px;

    :nth-child(1) {
      .tna__graph-heading {
        transform: translate(-40px, 0);
        width: 100px;
        text-align: center;
      }
    }
    :nth-child(4) {
      .tna__graph-heading {
        transform: translate(60px, 0);
      }
    }
    :nth-child(5) {
      .tna__graph-heading {
        transform: translate(20px, 0);
      }
    }
  }
}

.green-circle {
  height: 14px;
  width: 14px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  background-color: var(--fill-green-dark);
  box-shadow: 0 0 0px 2px white;
  z-index: 1;
}

.grey-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--fill-grey-light);
  box-shadow: 0 0 0px 2px white;
  z-index: 1;
}

.cell-v2 > div.next-stage {
  color: var(--fill-locofast-black);
  font-size: 14px;
  position: relative;
  margin-top: 12px;

  &:after {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    top: 5px;
    left: -15px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid var(--fill-grey-medium);
  }

  &:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 16px;
    bottom: 15px;
    left: -11px;
    background-color: var(--fill-grey-medium);
  }

  button {
    opacity: 0;
    padding: 0 6px;
    font-size: 12px;
    text-transform: initial;
    margin-left: 12px;
    position: absolute;

    img {
      width: 8px;
      margin-right: 4px;
    }
  }

  &.no-prev-stage {
    margin-top: 2px;
    &:before {
      height: 0;
    }

    &:after {
      border: none;
    }
  }
}
