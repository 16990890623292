.quote-actions {
  max-width: 600px;

  .card-horizontal {
    display: flex;
    align-items: center;
    border: 1px solid var(--fill-grey-light2);
    margin: 16px 24px 24px;
    border-radius: 8px;
    width: 552px;

    .cover-image {
      width: 180px;
      height: 208px;
      object-fit: none;
      background-color: var(--fill-grey-light5);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    > div {
      padding-left: 14px;

      h4,
      span {
        padding-left: 18px;
      }

      h4 {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 4px;
      }

      span {
        line-height: 24px;
        color: var(--fill-grey-medium);
        font-size: 16px;
      }
    }
  }

  .footer-note {
    background: var(--fill-yellow-light);
    line-height: 20px;
    padding: 12px 24px;
    border: 1px solid var(--fill-grey-light2);
    display: flex;

    svg {
      margin-right: 10px;

      path {
        fill: var(--fill-primary-blue);
      }
    }
  }

  .action-icon {
    position: relative;
    right: 30px;
  }
}

.quote-actions.reject + .modal__footer {
  button:last-child {
    background: var(--fill-orangered);
  }
}

.quote-actions + .modal__footer {
  border-top: 0;
}
