.filter-drawer-button {
  .counter {
    display: inline-block;
    height: 20px;
    width: 20px;
    background: var(--fill-secondary-dark);
    color: white;
    border-radius: 50%;
    margin-right: 4px;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }
}
