.header-tabs {
  height: 100%;
  max-height: 100%;
  // overflow: hidden;
  width: 100%;
}

.header-tabs__header {
  background-color: #ffffff;
  // padding: 24px 32px;
  // min-height: 20%;
}

.header-tabs__navbar {
  margin-top: auto;
  align-items: center;
  border-top: 1px solid var(--fill-grey-light2);

  div {
    user-select: none;
    // cursor: pointer;
  }
}

.header-tabs__tab {
  position: relative;
  flex: 1;
  height: 60px;
  padding: 12px 24px;
  border-bottom: 3px solid var(--fill-grey-light3);
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &::after {
    content: " ";
    background: var(--fill-grey-light3);
    position: absolute;
    bottom: -3px;
    width: 80%;
    height: 3px;
    border-radius: 3px 3px 0px 0px;
    z-index: 5;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &--disabled {
    cursor: not-allowed !important;
    opacity: 0.6;
  }

  &-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-image {
    padding-right: 8px;

    img {
      margin-top: 2px;
    }
    &.active {
      svg rect, svg circle {
        fill: var(--fill-primary-blue);
      }
    }
    &.inactive {
      svg rect, svg circle {
        fill: var(--fill-orange);
      }
    }
    &.completed {
      svg rect, svg circle {
        fill: var(--fill-grey-medium);
      }
    }
  }

  .sub_title {
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    font-weight: normal;
    color: var(--fill-grey-medium);
  }

  &.messages {
    border-bottom: 0;
    border-left: 1px solid var(--fill-grey-light3);
    font-size: 14px;
    line-height: 40px;
    border-bottom: 1px solid var(--fill-grey-light3);
    background-image: url("../../../../assets/images/journey/ic-ghost-message.svg");
    background-position: 24px;
    background-size: 20px;
    background-repeat: no-repeat;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .header-tabs__tab-block {
      flex-direction: row;
      align-items: center;
    }

    &::after {
      height: 0;
    }
  }

  .count {
    background: var(--fill-secondary-dark);
    color: var(--fill-white);
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    left: 18px;
  }
}

.header-tabs__tab--right {
  float: right;
}
.header-tabs__tab--active {
  &::after {
    background: var(--fill-primary-blue);
  }
  span {
    color: var(--fill-locofast-block);
    font-weight: bold;
  }

  &.messages {
    &::after {
      height: 3px;
      bottom: 0;
    }

    background-image: url("../../../../assets/images/journey/ic-blue-message.svg");
  }
}

.header-tabs__panel {
  // padding: 17px 32px;
  // height: 80%;
  // max-height: 80%;
  height: 100%;
  flex-grow: 1;
  max-width: 100%;

  & > * {
    // max-height: 100%;
    flex-grow: 1;
  }
}
