// ==== styles for swatch details model ====
.swatch__details {
  min-width: 75vw;
  padding: 0px 36px 36px 36px;

  &_form {
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    &_upper {
    }
  }
}

.swatch__image_wrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr; //repeat(2, 1fr);

  &_inner {
    display: grid;
    grid-template-columns: 100px auto;
  }
}

.swatch__image_thumnail_wrapper {
  display: flex;
  flex-direction: column;

  &_inner {
    width: 100px;
    height: 120px;

    // background-color: #ddd;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.swatch__thumnail_image {
  width: 100%;
  // height: 140px;
}

.swatch__image_big_boss {
  width: 350px;
  height: 410px;
  // padding: 0px 32px;

  background-color: var(--fill-grey-light5);
  justify-self: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.swatch__image {
  // width: 100%;
  // height: 410px;
  max-width: 350px;
  max-height: 410px;
}

.swatch__name {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--fill-locofast-black);
}

.swatch__description_wrapper {
}

.swatch__description_heading {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--fill-locofast-black);

  margin-top: 10px;
}

.swatch__description {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--fill-locofast-black);
}

.swatch__table_wrapper {
  border: 1px solid #eee;

  margin-top: 10px;
}

.swatch__table_item {
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &_label {
    flex: 1;
    padding: 10px;
    border-right: 1px solid #eee;
    background-color: #f5f5f5;
    font-weight: 600;
  }

  &_value {
    flex: 1;
    padding: 10px;
    color: var(--fill-locofast-black);
  }
}

// === styles for dropdown ===

.dropdown__wrapper_custom {
  border: 1px solid var(--fill-grey-light);
  background-color: var(--fill-grey-light5);

  border-radius: 4px;
  margin-bottom: 15px;
}

// === STYLES FOR DESIGN FILTER ===
.filter__wrapper {
  display: flex;
  align-items: center;

  padding: 5px 10px;
  width: 100%;

  &:hover {
    background-color: var(--fill-grey-light5);
  }
}

.filter__image_wrapper {
  width: 40px;
  height: 40px;
  border-radius: 100px;

  overflow: hidden;
}

.filter__design_image {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.filter__design_name {
  font-size: 12px;
  color: var(--fill-locofast-black);
  padding-left: 15px;
}

// === styles for swatch__details_add_to_design

.swatch__details_add_to_design {
  // max-width: 50vw;
}
