.file {
  width: 164px;
  height: 164px;
  position: relative;
  border-radius: 4px;

  &__headings {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;

    >div:first-child{
      max-width: 120px;
    }

    :nth-child(2) {
      color: var(--fill-grey-dark);
      font-size: 12px;
    }

    p {
      margin: 8px 0;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: 50% 25%;
    background-color: var(--fill-white);
  }

  &__options {
    position: absolute;
    right: 6px;
  }

  .file_title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .file_category {
    font-size: 12px;
    line-height: 16px;
  }
}

.file.empty {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px dashed var(--fill-grey-light4);
}

.file.valid {
  border: 1px solid var(--fill-grey-light4);
}
