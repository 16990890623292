.open-quote {
  margin-right: -24px;

  .table__row__big.single-line {
    position: relative;
  }

  .table__row-cta {
    &-list {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: all 100ms ease-out;
    }

    &-edit {
      padding: 1px 4px;
    }

    &-delete {
      padding: 6px 4px;
      background-color: #eb5757;
      margin-left: 4px;
    }
  }

  &--editable {
    .table__row__big.single-line {
      &:hover {
        .table__row-cta-list {
          opacity: 1;
        }
      }
    }
  }

  .table {
    margin-bottom: 2em;

    & > .table__content {
      .table__row {
        font-size: 13.5px;
      }
    }
  }

  .table--additional-costs {
    border-top: 1px solid var(--fill-grey-light2);
    margin-right: 24px;
  }

  .table__title {
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    background-color: var(--fill-grey-light3);
    border: 1px solid var(--fill-grey-light2);
  }

  .table__header.table__row {
    &.table__row--total-charges {
      margin-bottom: 24px;
    }
  }

  .table__footer,
  .table__header {
    border-bottom: 1px solid var(--fill-grey-light2);

    :first-child {
      flex-grow: 5;
      flex-basis: calc(50%);
      max-width: calc(50% + ((2 * 50%) / 3));
      text-transform: capitalize;
    }
  }

  .table__row__big--additional {
    flex-grow: 5 !important;
    flex-basis: calc(50%) !important;
    max-width: calc(50% + ((2 * 50%) / 3)) !important;
  }

  .table__row {
    border-bottom: 1px solid var(--fill-grey-light2);
    max-height: 3em;
    // align-items: center;

    & > * {
      // font-size: 13.5px;
      flex-grow: 1;
      flex-basis: calc(50% / 3);
      max-width: calc(50% / 3);
      padding: 0.5em 1em;
      text-align: right;
      line-height: 1.43;
      letter-spacing: 0.2px;
    }

    & > :first-child {
      border-left: 1px solid var(--fill-grey-light2);
    }

    & > :last-child {
      border-right: 1px solid var(--fill-grey-light2);
    }

    & > * {
      border-right: 1px solid var(--fill-grey-light2);
    }

    & > .table__row__big {
      flex-basis: 50%;
      max-width: calc(50%);
      text-align: left;
    }

    .table__calculated-data {
      background-color: #f5f5f5;
    }
  }

  img.delete {
  }
}
