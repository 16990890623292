.lf-ui-autosuggest {
  position: relative;

  input.input-search {
    width: 100%;
  }

  .suggestions-container {
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid var(--fill-grey-light3);
    z-index: 9999;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    .item-suggestion {
      cursor: pointer;

      &:hover {
        background: var(--fill-grey-light5);
      }
    }

    > .scrollbar-container {
      max-height: 240px;
    }
  }
}
