.quote_card {
  padding: 15px 16px;
  margin-bottom: 12px;
  flex-direction: row !important;
}

.quote__accepted {
  border: 1px solid var(--fill-green-dark) !important;
  background-color: #e8fff5;
}

.quote__resubmit {
  border: 1px solid #ffa552 !important;
  background-color: #fff0d1;
}

.left {
  div {
    margin-bottom: 4px;
  }
}

.quoteDetails__quoteCard {
  border: none !important;
  border-radius: 0 !important;
  margin-bottom: 0;
  box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
}
