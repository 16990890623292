#search {
  .search-dropdown {
    // display: none;
    max-height: 500px;
    overflow: auto;
  }
}

input {
  outline: none;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: url("../../../assets/images//navigation/ic-search.svg") no-repeat 9px center;
  padding: 9px 10px 9px 32px;
  width: 55px;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="search"]:focus {
  width: 180px;
  background-color: #fff;
  border-color: var(--fill-primary-blue);

  -webkit-box-shadow: 0 0 5px var(--fill-grey-light4);
  -moz-box-shadow: 0 0 5px var(--fill-grey-light4);
  box-shadow: 0 0 5px var(--fill-grey-light4);
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}

#search input[type="search"] {
  width: 15px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
  border: none;
}
#search input[type="search"]:hover {
  background-color: #fff;
}
#search input[type="search"]:focus {
  width: 180px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
  border: 1px solid var(--fill-grey-light2);
}
#search input:-moz-placeholder {
  color: transparent;
}
#search input::-webkit-input-placeholder {
  color: transparent;
}
