.tna-header {
  font-style: normal;
  background: var(--fill-white);
  position: relative;

  &__heading {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 24px;
    padding-bottom: 0;
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
    padding-bottom: 20px;
  }

  &__item {
    text-align: start;
    padding-top: 20px;
    padding-right: 24px;

    &:first-child {
      padding-left: 0;
    }

    &__heading {
      color: #333340;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 4px;
    }

    &__value {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
    }
  }

  &::after{
    content: ' ';
    position: absolute;
    left: 16px;
    right: 16px;
    height: 1px;
    bottom: 0;
    background-color: #f2f4f7;
  }
}
