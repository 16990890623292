.modal__wrapper {
  .tna-graph {
    min-width: 1080px;
    padding-top: 16px;
    &__heading {
      display: none;
    }
  }
  .tna-details {
    min-height: 280px;
  }
}
