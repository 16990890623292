.filter-label {
  position: relative;

  .dropdown {
    position: absolute;
    max-height: 300px;
    z-index: 999;
    background: white;
    color: var(--fill-locofast-black);
    border: 1px solid var(--fill-grey-light3);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    left: 0;
    right: 0;

    .filter-item {
      min-height: 40px;
      display: flex;
      align-items: center;

      label {
        display: flex;
        align-items: center;
        text-transform: none;
        min-height: 40px;
        width: 100%;
      }

      input {
        display: none;
        &:checked + label > .img-check {
          opacity: 1;
        }
      }
    }

    > .scrollbar-container {
      max-height: 300px;
      padding: 8px 0;
    }
  }

  .img-check {
    margin: 0 8px 0 16px;
    opacity: 0;

    * {
      fill: var(--fill-primary-blue);
    }
  }

  .img-arrow-drop-up {
    transform: rotate(180deg);
    transition: all 100ms;
    position: absolute;
    margin-top: -6px;
    margin-left: 2px;

    * {
      fill: var(--fill-grey-medium);
    }
  }

  &.show {
    .img-arrow-drop-up {
      transform: rotate(0deg);
    }
  }
}
